/*eslint-disable */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from 'apollo-react/components/Typography';
import Button from 'apollo-react/components/Button';
import Step from 'apollo-react/components/Step';
import StepLabel from 'apollo-react/components/StepLabel';
import Stepper from 'apollo-react/components/Stepper';
import { makeStyles } from '@mui/styles';
import Grid from 'apollo-react/components/Grid';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  sideBar: {
    padding: '1.5rem'
  },
  publishDetailsContainer: {},
  publishTitle: {
    height: '24px',
    width: '215px',
    color: '#000000',
    fontFamily: 'Proxima Nova',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: 0,
    lineHeight: '24px'
  },
  mapSpecDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem'
  },
  mapSpecDetailsTitle: { color: '#999999', fontSize: '1rem', fontWeight: '400' },
  mapSpecDetailsDescription: { color: '#000000', fontSize: '1rem', fontWeight: '500' },
  mapSpecDetailsWrapper: {
    overflowWrap: 'anywhere',
    whiteSpace: 'normal',
    color: '#000000',
    fontSize: '1rem',
    fontWeight: '500'
  },
  stepper: {
    margin: '1rem 0rem'
  }
});

let steps = [
  'Data Pipeline Settings',
  'Select Sources',
  'Select Trial Version',
  'Validation',
  'Data Export',
  'Review and Publish',
  'Publish Status'
];

export const PublishMappingSpecSidebar = (props) => {
  const classes = useStyles();
  const { activeStep, onStepperClick, onShowMoreInfo } = props;
  const { mappingSpecDetails } = useSelector((state) => state.PublishMappingSpecData);

  const onStepClick = (index) => {
    if (activeStep > index) {
      onStepperClick && onStepperClick(index);
    }
  };

  return (
    <>
      <Box className={classes.sideBar}>
        <Box className={classes.publishDetailsContainer}>
          <Typography data-testid="publish-title" className={classes.publishTitle}>
            Publish Version
          </Typography>
          <Grid item xs={12} className={classes.mapSpecDetails}>
            <Typography className={classes.mapSpecDetailsTitle}>Mapping Spec Version</Typography>
            <Typography className={classes.mapSpecDetailsWrapper}>
              {mappingSpecDetails.mappingSpecVersionName}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.mapSpecDetails}>
            <Typography className={classes.mapSpecDetailsTitle}>Product Mnemonic</Typography>
            <Typography className={classes.mapSpecDetailsDescription}>
              {mappingSpecDetails.productMnemonic}
            </Typography>
          </Grid>
          <Button
            style={{
              padding: '0rem'
            }}
            variant={'text'}
            size={'small'}
            onClick={onShowMoreInfo}
            data-testid="view">
            Show More Info
          </Button>
        </Box>

        <Stepper box activeStep={activeStep} className={classes.stepper}>
          {steps.map((label, index) => (
            <Step
              key={label}
              style={{ padding: '1rem', cursor: activeStep >= index && 'pointer' }}
              onClick={() => onStepClick(index)}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};
