import React, { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';

import Typography from 'apollo-react/components/Typography';
import { makeStyles } from '@mui/styles';
import Grid from 'apollo-react/components/Grid';
import PreviewNonEcrf from './components/PreviewNonEcrf';
import PreviewSDTM from './components/PreviewSDTM';
import useGlobalMessage from 'Utils/useGlobalMessage';
import { MessageConstants } from 'Utils/MessageConstants';

export const useStylesPreviewCreate = makeStyles({
  libraryType: {
    width: '100%',
    marginBottom: '2rem !important',
    boxShadow: '0px 4px 16px rgba(0,0,0,0.04)',
    border: '1px solid #E9E9E9',
    borderRadius: '4px'
  },
  libraryTypeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '48px'
  },
  libraryTypeTitle: {
    fontSize: '14px',
    fontWeight: '500'
  },
  expressionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #F6F7FB',
    padding: '12px 32px',
    '& *': {
      fontSize: '14px !important'
    }
  }
});

const PreviewCreate = () => {
  const dispatch = useDispatch();
  const { getMessage } = useGlobalMessage();
  const {
    workFlowModel,
    nonEcrrfDataProduct = {},
    SDTMDataProduct = {}
  } = useSelector((state) => state.SDHDataProduct) || {};

  const { nonEcrfConfig = '' } = nonEcrrfDataProduct || {};
  const { sdtmConfig = '' } = SDTMDataProduct || {};

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Product Designer',
          path: '/product-designer'
        },
        {
          title: 'Create SDH Data Product',
          path: `/product-designer/${workFlowModel[workFlowModel?.length - 1]?.moduleName}`
        }
      ])
    );
  }, []);

  return (
    <>
      <Grid p={2} style={{ minHeight: '80vh' }}>
        <Typography variant="h3" mb={2}>
          {getMessage(MessageConstants.SDH_PREVIEW_AND_CREATE_TITLE)}
        </Typography>
        <div>
          {nonEcrfConfig && <PreviewNonEcrf />}
          {sdtmConfig && <PreviewSDTM />}
        </div>
      </Grid>
    </>
  );
};

const SDHPreviewCreate = forwardRef(PreviewCreate);
export default SDHPreviewCreate;
