import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Table from 'apollo-react/components/Table';
import Typography from 'apollo-react/components/Typography';
import { DataSetStatus } from 'Pages/Dashboard/Components/Status';
import React, { useState } from 'react';
import Card from 'apollo-react/components/Card';
import Modal from 'apollo-react/components/Modal';
import QueryOutput from 'Pages/DataStandardLibrary/DataStandardLibraryMain/Components/QueryOutput';
import { makeStyles } from '@mui/styles';
import { nonEcrfStyle } from './NonECRSFStyle';
import { Notification } from 'Components/Common/Notification';
import useGlobalMessage from 'Utils/useGlobalMessage';
import { MessageConstants } from 'Utils/MessageConstants';

function ShowQueryOutput({
  queryColumns,
  queryResult,
  queryErrors,
  displayModal,
  handleLeaveModal
}) {
  const useStyles = makeStyles(nonEcrfStyle.modal);
  const classes = useStyles();
  return (
    <Modal
      open={displayModal}
      onClose={handleLeaveModal}
      buttonProps={[{ label: 'Cancel', onClick: handleLeaveModal }]}
      hideHeader
      showCloseIcon
      className={classes.container}>
      <QueryOutput
        queryColumns={queryColumns}
        queryResult={queryResult}
        queryError={queryErrors}
      />
    </Modal>
  );
}

function NonECRFDataListTable({ nonEcrfDatasets, runAllHandler, loading, runAllExecutionWarningMsg }) {
  const { getMessage } = useGlobalMessage();
  const [viewOutputDataSet, setViewOuptutDataSet] = useState({
    queryColumns: '',
    queryResult: '',
    queryErrors: '',
    displayModal: ''
  });

  const ActionViewOutputButtonCell = ({ row }) => {
    if (row?.queryJsonResult) {
      const handleLeaveModal = () => {
        setViewOuptutDataSet((prev) => {
          return {
            ...prev,
            displayModal: false
          };
        });
      };

      return (
        <Button
          variant="secondary"
          onClick={() => {
            setViewOuptutDataSet({
              displayModal: true,
              queryColumns: row?.columns,
              queryResult: JSON.parse(row?.queryJsonResult),
              queryErrors: row?.queryErrors,
              handleLeaveModal
            });
          }}
          size="small">
          View Output
        </Button>
      );
    }
    return <div />;
  };

  const DataSetStatusCell = ({column, row, ...rest}) => {
    const updatedRow = {...row, datasetStatus: row?.isQuerySuccess? 'successful': 'failed'}
    const updatedTableRowCol = {column, row: updatedRow, ...rest}
    if (row?.queryJsonResult || row?.queryErrors) {
      return <div style={{ width: '7rem' }}>{DataSetStatus(updatedTableRowCol)}</div>;
    }
    return <div />;
  };

  const columns = [
    {
      header: 'Data Set Name',
      accessor: 'datasetName'
    },
    {
      header: 'Description',
      accessor: 'description'
    },
    {
      header: 'Status',
      accessor: 'isQuerySuccess',
      width: 200,
      customCell: DataSetStatusCell
    },
    {
      accessor: 'action',
      customCell: ActionViewOutputButtonCell,
      width: 60
    }
  ];

  const CustomButtonHeader = ({ runAllHandler }) => {
    return (
      <div>
        <Button
          size="small"
          variant="primary"
          onClick={runAllHandler}
          disabled={nonEcrfDatasets?.length === 0}>
          Run All
        </Button>
      </div>
    );
  };

  const EmptyTableTypographyStyleTitle1 = {
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '22px',
    color: '#595959'
  };

  const EmptyTableComponent = () => (
    <>
      <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
        {getMessage(MessageConstants.EMPTY_MESSAGE_FOR_NONECRF_DATASET_TABLE)}
      </Typography>
    </>
  );

  const mapRows = () => {
    return nonEcrfDatasets?.length > 0 ? nonEcrfDatasets.map((row) => ({ ...row })) : [];
  };

  return (
    <>
      {runAllExecutionWarningMsg && <Notification variant='warning' message={runAllExecutionWarningMsg}/> }
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Card>
            <Table
              title={getMessage(MessageConstants.NON_ECRF_DATASET_TABLE_TITLE)}
              CustomHeader={CustomButtonHeader}
              headerProps={{ runAllHandler }}
              columns={columns}
              rows={mapRows()}
              emptyProps={{
                text: <EmptyTableComponent />
              }}
              hidePagination
              isLoading={loading}
              defaultRowsPerPage={5}
            />
          </Card>
        </Grid>
      </Grid>
      {viewOutputDataSet?.displayModal && <ShowQueryOutput {...viewOutputDataSet} />}
    </>
  );
}

export default NonECRFDataListTable;
