/* eslint-disable */
import React, { useState } from 'react';
import Card from 'apollo-react/components/Card';
import MenuItem from 'apollo-react/components/MenuItem';
import Grid from 'apollo-react/components/Grid';
import Select from 'apollo-react/components/Select';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import EllipsisVertical from 'apollo-react-icons/EllipsisVertical';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import Tooltip from 'apollo-react/components/Tooltip';
import { useContext } from 'react';
import { MasteringRulesContext } from '../../MasteringRulesProvider';
import CustomeTextField from 'Components/CustomeTextField/CustomeTextField';
import { generateErrorString, getHtmlString } from 'Utils';
import { methodsAndConstantsMapping } from '../../../MethodandConstantMapping';
import Search from 'apollo-react-icons/Search';

const SEARCH_FIELDS = 'Search fields';

const useStyles = makeStyles({
  hint: {
    color: '#0768FD'
  },
  menuPlaceHolder: {
    '&:hover': {
      color: 'blue'
    }
  }
});

const Expression = ({
  updateExpression,
  expression,
  isValid,
  inProgress,
  id,
  index,
  sourceFormItems = {},
  disableDelete,
  methodsConstants,
  defaultForm: sourceForm,
  result: parserResult,
  library,
  formatedFormItemData
}) => {
  const initialCaretPosition = expression ? expression.length : 0;
  const [caretPosition, setCaretPosition] = useState(initialCaretPosition);
  const [searchFieldFormItem, setSearchFieldFormItem] = useState();
  const error = typeof parserResult === 'object' && Object.keys(parserResult).length > 0;
  const { setConfirmAlert, setChangesNotSaved, displaySearchFields } =
    useContext(MasteringRulesContext);

  const selectClasses = useStyles();

  useEffect(() => {
    if (inProgress) {
      setChangesNotSaved(true, id);
    } else {
      setChangesNotSaved(false, id);
    }
  }, [inProgress]);

  const handleSourceFieldSelect = (e) => {
    if (e.target.value && e.target.value !== SEARCH_FIELDS) {
      const source = `[${sourceForm}].[${e.target.value}]`;
      const value =
        expression.substring(0, caretPosition) +
        source +
        expression.substring(caretPosition, expression.length);
      updateExpression('save-exp', { id, expression: value, sourceForm });
      setSearchFieldFormItem(e.target.value);
      setCaretPosition(value.length);
    } else if (e.target.value === SEARCH_FIELDS) {
      displaySearchFields({
        enabled: true,
        onAdd: (formName, itemName) => {
          const exp = `${expression.slice(
            0,
            caretPosition
          )}[${formName}].[${itemName}]${expression.slice(caretPosition)}`;
          setCaretPosition(exp.length);
          updateExpression('save-exp', { id, expression: exp, sourceForm });
          setSearchFieldFormItem(`${formName}/${itemName}`);
          displaySearchFields({ enabled: false });
        },
        onCancel: () => {
          displaySearchFields({ enabled: false });
        }
      });
    }
  };

  const handleMethodSelect = (e) => {
    const method = methodsAndConstantsMapping[e.target.value];
    const exp =
      expression.substring(0, caretPosition) +
      method +
      expression.substring(caretPosition, expression.length);
    updateExpression('save-exp', { id, expression: exp, sourceForm });
    setCaretPosition(exp.length);
  };

  const handleExpressionChange = (value) => {
    updateExpression('save-exp', { id, expression: value, sourceForm });
  };

  const menuItems = [
    {
      text: 'Edit expression',
      onClick: () => {
        updateExpression('edit', { id });
      }
    },
    {
      text: 'Delete expression',
      onClick: () => {
        setConfirmAlert({
          enabled: true,
          type: 'delete',
          onConfirm: () => {
            updateExpression('delete', { id }, true);
            setConfirmAlert({ enabled: false, type: '' });
          },
          onCancel: () => {
            setConfirmAlert({ enabled: false, type: '' });
          }
        });
      },
      disabled: disableDelete
    }
  ];

  const renderSearchFieldMenuItems = () => {
    if (library.startsWith('CDISC ODM'))
      return [
        ...(sourceFormItems[sourceForm] || []).map((item, index) => {
          return (
            <MenuItem value={item} key={`SourceField-${index}`}>
              {item}
            </MenuItem>
          );
        }),
        <MenuItem value={SEARCH_FIELDS} key={`SourceField-Serach-Fields`}>
          <span class={selectClasses?.menuPlaceHolder} style={{ display: 'flex' }}>
            <Search /> Search fields
          </span>
        </MenuItem>
      ];
    else
      return (sourceFormItems[sourceForm] || []).map((item, index) => {
        return (
          <MenuItem value={item} key={`SourceField-${index}`}>
            {item}
          </MenuItem>
        );
      });
  };

  return (
    <Card
      interactive
      style={{
        width: '96%',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        marginBottom: '1rem'
      }}>
      <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <span>Expression {index + 1}</span>
        <Tooltip title="Actions" disableFocusListener>
          <span>
            <IconMenuButton
              id="actions"
              menuItems={menuItems}
              disabled={inProgress}
              size="small"
              data-testid="menu-btn">
              <EllipsisVertical />
            </IconMenuButton>
          </span>
        </Tooltip>
      </Grid>
      {!inProgress && isValid ? (
        <>
          <Grid item md={12}>
            <CustomeTextField
              id="expression-preview"
              name="expression-preview"
              data-testid="expression-preview"
              disabled>
              {getHtmlString(expression, formatedFormItemData, sourceForm)}
            </CustomeTextField>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end'
            }}>
            <Grid xs={3} item style={{ marginRight: '1rem' }}>
              <Select
                label="Source Field"
                onChange={handleSourceFieldSelect}
                placeholder={searchFieldFormItem || 'Select one...'}
                style={{ width: '100%' }}>
                {renderSearchFieldMenuItems()}
              </Select>
            </Grid>
            <Grid xs={3} item style={{ marginRight: '1rem' }}>
              <Select
                onChange={handleMethodSelect}
                classes={selectClasses}
                placeholder="Add Method/Constant"
                style={{ width: '100%' }}>
                {methodsConstants
                  ?.filter((m) => m !== 'QCVSEQ()')
                  .map((item, index) => (
                    <MenuItem value={item} key={`Method-${index}`}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={12}>
              <CustomeTextField
                id="expression"
                onChange={handleExpressionChange}
                onCaretPositionchange={setCaretPosition}
                name="expression"
                data-testid="expression-input"
                helperText={error ? parserResult.message : ''}
                error={error}>
                {error
                  ? generateErrorString(
                      expression,
                      parserResult?.cordinates?.start,
                      parserResult?.cordinates?.end
                    )
                  : getHtmlString(expression, formatedFormItemData, sourceForm)}
              </CustomeTextField>
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  );
};
export default Expression;
