/*eslint-disable*/
import React, { useMemo, useState } from 'react';
import Table from 'apollo-react/components/Table';
import Button from 'apollo-react/components/Button';

import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import { makeStyles } from '@mui/styles';
import {
  compareStrings,
  createStringSearchFilter,
  compareNumbers
} from 'apollo-react/components/Table';
import { DataSetStatus } from 'Pages/Dashboard/Components/Status';
import { compareDates, dateFilter } from 'Utils';
import { TextFieldFilter, IntegerFilter } from 'Pages/Dashboard/Components/Filters';
import { numberSearchFilter } from 'Pages/Dashboard/DataProductSummary/Components/columns';
import { DateFilter } from 'Components/Common/DateFilter';
import { readAsText } from 'Utils';
import { GetRefreshLogDetails } from 'Redux/Service/DataRefreshHistoryService';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import InfoIcon from 'apollo-react-icons/Info';
import Tooltip from 'apollo-react/components/Tooltip';
import VLCDetailsOverlay from './VLCDetails';
import { GetVLCStats } from 'Redux/Service/DashboardService';
import StatusCheck from 'apollo-react-icons/StatusCheck';
import StatusFailure from 'Images/status-failure.svg';
import { green, red } from 'apollo-react/colors';
import styled from 'styled-components';

const useStyles = makeStyles({
  toolbar: {
    height: 'auto'
  }
});

const SuccessIcon = styled(StatusCheck)(() => ({ color: green, height: '13px' }));
const FailedIcon = styled((props) => <img src={StatusFailure} alt="failed" {...props} />)(() => ({
  marginLeft: '8px',
  marginRight: '4px',
  color: red,
  height: '13px'
}));

const numberSearchFilterRules = (accessor) => {
  return function (row, filters) {
    const vlcStatistics = row.vlcStatistics || {};
    const successCount = vlcStatistics[`${accessor}SuccessCount`];
    const failedCount = vlcStatistics[`${accessor}FailedCount`];

    const successFilter = filters[`${accessor}`] ? filters[`${accessor}`].toString() : '';
    const failedFilter = filters[`${accessor}`] ? filters[`${accessor}`].toString() : '';

    const successMatch =
      successCount !== undefined &&
      successCount !== null &&
      successCount.toString().includes(successFilter);
    const failedMatch =
      failedCount !== undefined &&
      failedCount !== null &&
      failedCount.toString().includes(failedFilter);

    return successMatch || failedMatch;
  };
};

const ViewLogButton = ({ row }) => {
  return (
    <div>
      {row?.jobRunID && (
        <Button size="small" data-testid={`${row.jobRunID}-ViewLogButton`} onClick={row.onViewLog}>
          {row.jobRunID}
        </Button>
      )}
    </div>
  );
};

const ViewVLCRulesButton = ({ row, column: { accessor } }) => {
  return (
    <Button
      size="small"
      data-testid={`${accessor}Count`}
      onClick={() => row.handleVLCRulesModal(accessor === 'systemDefinedVLC' ? 0 : 1)}>
      <SuccessIcon />
      {row.vlcStatistics?.[`${accessor}SuccessCount`]}
      <FailedIcon />
      {row.vlcStatistics?.[`${accessor}FailedCount`]}
    </Button>
  );
};

const columns = [
  {
    header: 'Job Run ID',
    accessor: 'jobRunID',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('jobRunID'),
    fixedWidth: false,
    customCell: ViewLogButton
  },
  {
    header: 'Data Set Name',
    accessor: 'datasetName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('datasetName')
  },
  {
    header: 'Data Set Type',
    accessor: 'datasetType',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('datasetType')
  },
  {
    header: 'Total Data Set Records',
    accessor: 'recordsCount',
    filterComponent: IntegerFilter,
    sortFunction: compareNumbers,
    filterFunction: numberSearchFilter('recordsCount')
  },
  {
    header: 'System Defined VLC',
    accessor: 'systemDefinedVLC',
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilterRules('systemDefinedVLC'),
    sortFunction: compareNumbers,
    customCell: ViewVLCRulesButton
  },
  {
    header: 'User Defined VLC',
    accessor: 'userDefinedVLC',
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilterRules('userDefinedVLC'),
    sortFunction: compareNumbers,
    customCell: ViewVLCRulesButton
  },
  {
    header: 'Last Successful Processed Date',
    accessor: 'lastUpdatedDate',
    sortFunction: compareDates,
    filterComponent: DateFilter,
    filterFunction: dateFilter('lastUpdatedDate')
  },
  {
    header: (
      <>
        <span>Source Data Retrieval Date</span>
        <Tooltip
          title="Date on which new or updated data was last found in the linked source and retrieved for processing. Each new run will check the source for new/updated data for all datasets when the run is initiated; if no updates were found, the date remains the last time new/updated data was found during a run"
          placement="bottom">
          <InfoIcon fontSize="extra small" sx={{ marginTop: '-2px' }} />
        </Tooltip>
      </>
    ),
    accessor: 'cacheStartDate',
    sortFunction: compareDates,
    filterComponent: DateFilter,
    filterFunction: dateFilter('cacheStartDate')
  },
  {
    header: 'Status',
    accessor: 'datasetStatus',
    customCell: DataSetStatus,
    sortFunction: compareStrings,
    align: 'center',
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('datasetStatus')
  }
];

const DataSetsTable = (props) => {
  const classes = useStyles();
  const { rowData, setViewLogConfig } = props;
  const dispatch = useDispatch();
  const [displayVLCStatsModal, setVlcStatsModal] = useState(false);
  const [vlcStats, setVlcStats] = useState([]);
  const [dataSetsRowVal, setDataSetsRowVal] = useState({});
  const { protocolDetails } = useSelector((state) => state.DataRefreshHistory);

  const onViewLog = async ({ jobRunID }) => {
    const {
      data: { success, message, viewLogData }
    } = await dispatch(GetRefreshLogDetails({ jobRunID })).then(unwrapResult);

    try {
      const fileData = readAsText(viewLogData);
      if (success) setViewLogConfig({ open: true, ...fileData });
      else dispatch(showBanner({ variant: 'error', message }));
    } catch (error) {
      dispatch(showBanner({ variant: 'error', message: error.message }));
    }
  };

  const handleVLCRulesModal = async (row, tab) => {
    setDataSetsRowVal({
      jobRunID: row?.jobRunID,
      datasetName: row?.datasetName,
      datasetType: row?.datasetType,
      vlcRulesTotalCount: row?.vlcRulesTotalCount,
      recordsCount: row?.recordsCount,
      vlcStatistics: row?.vlcStatistics,
      mappingRuleVersionID: protocolDetails.mappingRuleVersionID,
      tab
    });
    setVlcStatsModal(true);
  };

  const mappedRows = useMemo(
    () =>
      rowData.map((row) => ({
        ...row,
        onViewLog: () => onViewLog(row),
        handleVLCRulesModal: (tab) => handleVLCRulesModal(row, tab)
      })),
    [rowData]
  );
  return (
    <>
      <Table
        title={'Data Sets'}
        classes={classes}
        columns={columns}
        rows={mappedRows}
        rowId="dataSetName"
        initialSortedColumn="lastUpdatedDate"
        initialSortOrder="desc"
        defaultRowsPerPage={10}
        rowsPerPageOptions={[10, 50, 100, 'All']}
        hasScroll
        maxHeight={650}
        tablePaginationProps={{
          labelDisplayedRows: DisplayedRowsLabel,
          truncate: true
        }}
      />
      <VLCDetailsOverlay
        displayVLCStatsModal={displayVLCStatsModal}
        setVlcStatsModal={setVlcStatsModal}
        dataSetsRowVal={dataSetsRowVal}
        vlcStats={vlcStats}
      />
    </>
  );
};

export default DataSetsTable;
