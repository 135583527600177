import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import Typography from 'apollo-react/components/Typography';
import { GetStudyLibrarySourceByDataSetType } from 'Redux/Service/AddStudyLibraryService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  GetNonECRFDatasets,
  GetReservedLibraryInfo,
  RunNonEcrfDatasets
} from 'Redux/Service/DataProductsService';
import {
  resetNonEcrfState,
  setNextEnabled,
  setNonEcrfDataProduct
} from 'Redux/Slice/SDHDataProductSlice';
import { GetViewGlobalLibraries } from 'Redux/Service/GlobalLibraryService';
import {
  ADD_NON_ECRF_DATA_PRODUCT,
  NON_ECRF_LIBRARY_NAME,
  NON_ECRF_PRODUCT_MNEMONIC
} from 'Utils/Constants';
import AddNonECRFForm from './components/AddNonECRFForm';
import NonECRFDataListTable from './components/NonECRFDataListTable';
import { dateFormatByType } from 'Utils';
import NonECRFDetailsStrip from './components/NonECRFDetailsStrip';
import Grid from 'apollo-react/components/Grid';
import TargetDataTable from './components/common/TargetDataTable';
import { TARGET_MODEL_TYPES } from 'Constants/TargetModelTypes';
import useGlobalMessage from 'Utils/useGlobalMessage';
import { MessageConstants } from 'Utils/MessageConstants';

export const nonECRFSchema = yup.object({
  trialName: yup.string().required('Trial Name/Environment Name is required'),
  description: yup.string().max(400, 'Maximum ${max} characters are allowed').optional(),
  targetRowSelected: yup.boolean().required(),
  nonEcrfConfigEnabled: yup.boolean().optional()
});

export const uniqueByEdcTrialName = (array) => {
  const seen = new Set();
  return array.filter((item) => {
    const duplicate = seen.has(item.edcTrialName);
    seen.add(item.edcTrialName);
    return !duplicate;
  });
};

const NonECRFData = () => {
  const { getMessage } = useGlobalMessage();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [edcTrialOptions, setEdcTrialOptions] = useState([]);

  const [nonEcrfTargetLibrary, setNonEcrfTargetLibrary] = useState([]);

  const studyLibraryData = useSelector((state) => state.StudyLibraryData);
  const sdhDataProduct = useSelector((state) => state.SDHDataProduct);

  const {
    workFlowModel = [],
    sdhDataProductConfig = {},
    nonEcrrfDataProduct = {}
  } = sdhDataProduct || {};

  const isNonEcrfWorkFlowRequired = sdhDataProductConfig?.[ADD_NON_ECRF_DATA_PRODUCT];

  const {
    nonEcrfDatasets = [],
    isRunAllExecuted = false,
    description = '',
    selectedTrialName = '',
    nonEcrfConfig = '',
    runAllExecutionWarningMsg = ''
  } = nonEcrrfDataProduct || {};

  const { protocol } = studyLibraryData;
  const formik = useFormik({
    initialValues: {
      trialName: selectedTrialName ? selectedTrialName?.edcTrialName : '',
      description: description ?? '',
      targetRowSelected: true,
      nonEcrfConfigEnabled: nonEcrfConfig ? nonEcrfConfig : isNonEcrfWorkFlowRequired
    },
    validationSchema: nonECRFSchema,
    onSubmit: async () => {}
  });

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Product Designer',
          path: '/product-designer'
        },
        {
          title: 'SDH Data Product',
          path: `/product-designer/${workFlowModel[0]?.moduleName}`
        }
      ])
    );
  }, []);

  const loadTrialOptions = async () => {
    try {
      const libraryType = 'CDISC ODM';
      const studyLibrarySources = await dispatch(
        GetStudyLibrarySourceByDataSetType({
          protocolNumber: protocol?.protocolNumber,
          type: libraryType,
          datasetTypeId: 0
        })
      ).then(unwrapResult);

      const { data } = studyLibrarySources;
      if (data?.success) {
        const edcTrialNames =
          data?.odmMetedataSources?.length > 0 ? uniqueByEdcTrialName(data.odmMetedataSources) : [];
        setEdcTrialOptions(edcTrialNames);
      } else {
        dispatch(showBanner({ variant: 'error', message: studyLibrarySources?.data?.message }));
      }
      setLoading(false);
    } catch (error) {
      dispatch(
        showBanner({
          variant: 'error',
          message: 'Something went wrong in GetStudyLibrarySources Method'
        })
      );
    }
  };

  const loadTargetDataSet = async () => {
    try {
      const targetSelectData = await dispatch(
        GetViewGlobalLibraries({
          IsActive: true,
          exceptRuleset: false,
          GlobalLibType: TARGET_MODEL_TYPES.NON_ECRF
        })
      ).then(unwrapResult);

      const { data } = targetSelectData;
      if (data.success) {
        const { libraries } = data;

        const updatedLibraries =
          libraries?.length > 0
            ? libraries.map((lib) => {
                return {
                  ...lib,
                  createdDate: dateFormatByType(lib.createdDate, 'Table')
                };
              })
            : [];

        setNonEcrfTargetLibrary(updatedLibraries);
        const targetLibrary = libraries?.length > 0 ? libraries[0] : {};
        dispatch(setNonEcrfDataProduct({ targetLibrary }));
      } else {
        dispatch(showBanner({ variant: 'error', message: data.message }));
      }
    } catch (error) {
      dispatch(
        showBanner({ variant: 'error', message: 'Something went wrong in GetLibraries Method' })
      );
    }
  };

  const loadReservedLibraryInfo = async () => {
    try {
      const getReservedLibraryData = await dispatch(GetReservedLibraryInfo()).then(unwrapResult);
      const { data } = getReservedLibraryData;
      if (data?.success) {
        const { productAndLibrary } = data;
        const libraryName = productAndLibrary?.find(
          (item) => item.configName === NON_ECRF_LIBRARY_NAME
        )?.configValue;
        const productMnemonic = productAndLibrary?.find(
          (item) => item.configName === NON_ECRF_PRODUCT_MNEMONIC
        )?.configValue;
        dispatch(setNonEcrfDataProduct({ libraryName, productMnemonic }));
      } else {
        dispatch(showBanner({ variant: 'error', message: data?.message }));
      }
    } catch (error) {
      dispatch(
        showBanner({
          variant: 'error',
          message: 'Something went wrong in GetReservedLibraryInfo Method'
        })
      );
    }
  };

  useEffect(async () => {
    loadTrialOptions();
    loadTargetDataSet();
    loadReservedLibraryInfo();
  }, []);

  useEffect(() => {
    formik.setFieldValue('nonEcrfConfigEnabled', isNonEcrfWorkFlowRequired);
    dispatch(setNextEnabled(!isNonEcrfWorkFlowRequired));
    isNonEcrfWorkFlowRequired &&
      dispatch(setNonEcrfDataProduct({ nonEcrfConfig: isNonEcrfWorkFlowRequired }));
  }, [isNonEcrfWorkFlowRequired]);

  const { protocolNumber } = protocol;
  const { trialName, nonEcrfConfigEnabled } = formik.values;
  useEffect(async () => {
    if (trialName) {
      setLoading(true);
      const { data } = await dispatch(
        GetNonECRFDatasets({
          protocolNumber: protocolNumber,
          trialName: trialName
        })
      ).then(unwrapResult);

      if (data.success) {
        const { nonEcrfDatasets: nonEcrfDatasetsFromAPI } = data;
        if (!isRunAllExecuted) {
          dispatch(setNonEcrfDataProduct({ nonEcrfDatasets: nonEcrfDatasetsFromAPI }));
        }
      }
    }
    setLoading(false);
  }, [trialName]);

  useEffect(() => {
    const isNextEnabled = nonEcrfTargetLibrary?.length > 0 && nonEcrfDatasets?.length > 0;
    
    if (!isRunAllExecuted && nonEcrfDatasets?.length > 0) {
      // dropdown is selected but runAll button not clicked
      dispatch(setNextEnabled(false));
    } else if (isNextEnabled) {
      dispatch(setNextEnabled(true));
    } else if (!nonEcrfConfigEnabled && nonEcrfDatasets?.length === 0) {
      // for Optional
      dispatch(setNextEnabled(true));
    } else {
      dispatch(setNextEnabled(false));
    }
  }, [nonEcrfTargetLibrary, trialName, nonEcrfDatasets, isRunAllExecuted, nonEcrfConfigEnabled]);

  useEffect(() => {
    const allQueriesSuccessful = nonEcrfDatasets.every((dataset) => {
      if ('isQuerySuccess' in dataset) {
        return dataset.isQuerySuccess === true;
      }
      return true;
    });

    if (!allQueriesSuccessful) {
      dispatch(
        setNonEcrfDataProduct({
          runAllExecutionWarningMsg: getMessage(MessageConstants.RUN_ALL_FAILED_QUERY_MESSAGE)
        })
      );
    } else {
      dispatch(setNonEcrfDataProduct({ runAllExecutionWarningMsg: '' }));
    }
  }, [nonEcrfDatasets]);

  const runAllHandler = async () => {
    if (nonEcrfDatasets?.length === 0) return;

    setLoading(true);
    dispatch(setNonEcrfDataProduct({ isRunAllExecuted: false }));

    const nonEcrfDatasetsPayload = nonEcrfDatasets.map((item) => ({
      datasetname: item.datasetName,
      description: item.description,
      queries: item.sqlQuery,
      trialName: trialName
    }));
    try {
      const { data } = await dispatch(
        RunNonEcrfDatasets({
          runNonEcrfDatasets: nonEcrfDatasetsPayload,
          protocolNumber: protocolNumber
        })
      ).then(unwrapResult);
      if (data.success) {
        const { nonecrfDatasets: nonecrfDatasetsApi } = data;

        const nonEcrfDatasetsTransoformed = nonEcrfDatasets.map((item) => {
          const additionalItem = nonecrfDatasetsApi.find(
            (add) => add.datasetName === item.datasetName
          );
          return additionalItem ? { ...item, ...additionalItem } : item;
        });

        dispatch(
          setNonEcrfDataProduct({
            isRunAllExecuted: true,
            nonEcrfDatasets: nonEcrfDatasetsTransoformed
          })
        );
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const configureDPToggleHandler = (e) => {
    formik.setFieldValue('nonEcrfConfigEnabled', e.target.checked);
    dispatch(setNonEcrfDataProduct({ runAllExecutionWarningMsg: '' }));
    if (e.target.checked) {
      dispatch(setNonEcrfDataProduct({ nonEcrfConfig: true }));
    } else {
      dispatch(resetNonEcrfState());
      formik.setFieldValue('trialName', '');
    }
  };

  return (
    <>
      <Grid px={3}>
        <Typography variant="h3">
          {getMessage(MessageConstants.ADD_NON_ECRF_DATA_PRODUCT_TITLE)}
        </Typography>

        <NonECRFDetailsStrip
          nonEcrfConfigEnabled={nonEcrfConfig}
          configureDPToggleHandler={configureDPToggleHandler}
        />

        {nonEcrfConfig && (
          <>
            <AddNonECRFForm formik={formik} edcTrialOptions={edcTrialOptions} />
            <NonECRFDataListTable
              nonEcrfDatasets={nonEcrfDatasets}
              runAllHandler={runAllHandler}
              loading={loading}
              runAllExecutionWarningMsg={runAllExecutionWarningMsg}
            />
            <TargetDataTable
              targetLibraryDataTable={nonEcrfTargetLibrary}
              targetRowSelected={formik.values.targetRowSelected}
            />
          </>
        )}
      </Grid>
    </>
  );
};

const NonECRFDataProducts = forwardRef(NonECRFData);
export default NonECRFDataProducts;
