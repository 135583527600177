import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GetStandardVLCConfig = createAsyncThunk(
  'RuleEditor/GetStandardVLCConfig',
  async (params) => {
    return await axios.get(`${API_URL}/api/RuleEditor/GetStandardVLCConfig`, { params });
  }
);

export const SaveStandardVLCConfig = createAsyncThunk(
  'RuleEditor/SaveStandardVLCConfig',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SaveStandardVLCConfig`, payload);
  }
);
