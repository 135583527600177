import Tooltip from 'apollo-react/components/Tooltip';
import Failure from 'Images/status-failure.svg';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';

const tabLabels = {
  domain: 'Domain Rules',
  additionalDomain: 'Additional Domain Rules',
  columnSettings:'Column Settings',
  vlcRules: 'VLC Rules',
  suppQual: 'Supplemental Qualifiers',
  refData: 'Reference Data',
  mastering: 'Mastering Rules',
  unknown: 'Miscellaneous Errors'
};

export const renderLabel = (label, errorListArray, clonedFlag) => {
  const errorCount = errorListArray.filter((row) => row.errorCode !== undefined).length;
  if (clonedFlag) {
    return (
      <span style={{ display: 'block' }}>
        {
          errorCount > 0 && <Tooltip
            variant="dark"
            title={`${errorCount} ${errorCount > 1 ? 'errors' : 'error'}`}
            placement="bottom"
            style={{ marginRight: 48 }}>
            < StatusExclamation style={{ height: '15px', width: '15px', padding: '2px 2px 0 0', color: 'orange' }}
            />
          </Tooltip>
        }

        <span style={{ marginLeft: '7px' }}>{`${tabLabels[label]} ${`(${errorCount})`}`}</span>
      </span>
    );
  } else if (tabLabels[label] !== undefined && errorListArray.some((row) => row.errorCode !== undefined)) {
    return (
      <span style={{ display: 'block' }}>
        <Tooltip
          variant="dark"
          title={`${errorCount} ${errorCount > 1 ? 'errors' : 'error'}`}
          placement="bottom"
          style={{ marginRight: 48 }}>
          <img
            src={Failure}
            style={{ height: '15px', width: '15px', padding: '2px 2px 0 0', color: 'red' }}
          />
        </Tooltip>
        <span style={{ marginLeft: '7px' }}>{`${tabLabels[label]} ${`(${errorCount})`}`}</span>
      </span>
    );
  } else {
    return tabLabels[label];
  }
};
