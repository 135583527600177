import React, { useEffect, useState } from 'react';
import FilterIcon from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import Table, {
  compareNumbers,
  compareStrings,
  createSelectFilterComponent,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import { IntegerFilter, TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import { Box, MenuItem, Select, Switch, TextField, Tooltip } from 'apollo-react';
import useGlobalKeys from 'Utils/useGlobalKeys';
import useGlobalMessage from 'Utils/useGlobalMessage';
import { KeyConstants } from 'Utils/KeyConstants';
import { useDispatch, useSelector } from 'react-redux';
import { setUnSavedChanges } from 'Redux/Slice/RuleEditorSlice';
import { Info } from 'apollo-react-icons';
import { MessageConstants } from 'Utils/MessageConstants';
import { debounce } from 'Utils';

const INT_FILTER_REGEX = new RegExp('[^\\d]', 'gi');
const NUMERIC_TYPE_FILTER_REGEX = new RegExp('[^n%.]', 'gi');
const DATE_TYPE_FILTER_REGEX = new RegExp('[^dmyhsontapi124.\\s\\:\\-/]', 'gi');

const CustomButtonHeader = ({ toggleFilters, handleSave, unSavedChanges }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Button
        size="small"
        variant="secondary"
        icon={FilterIcon}
        style={{ marginRight: 15 }}
        onClick={toggleFilters}>
        Filter
      </Button>
      <Button
        size="small"
        variant="primary"
        style={{ marginRight: 15 }}
        onClick={handleSave}
        disabled={!unSavedChanges?.title}>
        Save
      </Button>
    </Box>
  );
};

const ToggleFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <Select
      value={filters[accessor] || []}
      size={'small'}
      fullWidth
      multiple
      sx={{
        padding: 0,
        margin: 0,
        '& .MuiInputBase-root': {
          padding: 0,
          margin: 0
        }
      }}
      onChange={(e) =>
        updateFilterValue({
          target: { name: accessor, value: e.target.value }
        })
      }>
      <MenuItem value={true} key={'true'}>
        True
      </MenuItem>
      <MenuItem value={false} key={'false'}>
        False
      </MenuItem>
    </Select>
  );
};

const SelectCell = ({ row, column: { accessor } }) => {
  const { getMessage } = useGlobalMessage();
  const getGlobalKeys = useGlobalKeys();
  return (
    <Select
      value={row[accessor]}
      size={'small'}
      fullWidth
      canDeselect={false}
      onChange={(e) => row?.updateColumnSetting(row.id, accessor, e.target.value)}>
      {getGlobalKeys(KeyConstants.COLUMN_SETTINGS_DATA_TYPES.TOPIC_NAME)?.map((format) => (
        <MenuItem value={format} key={format}>
          {getMessage(KeyConstants.COLUMN_SETTINGS_DATA_TYPES.VALUES[format]?.LABEL)}
        </MenuItem>
      ))}
    </Select>
  );
};

const createSelectDropdownFilter = (accessor, list) => {
  return function (row, filters) {
    if (!filters[accessor].length || filters[accessor].length === list.length) {
      return true;
    } else {
      let rowVal = filters[accessor].includes(row[accessor]);
      if (rowVal) return true;
    }
  };
};
const DATE_TYPE_ALLOWED_CHAR = [
  'Space',
  'a',
  'd',
  'h',
  'i',
  'm',
  'n',
  'o',
  'p',
  's',
  't',
  'y',
  '12',
  '24',
  '.',
  '/',
  '-',
  ':'
];
const NUMERIC_TYPE_ALLOWED_CHAR = ['n', '.', '%'];
const FormatInfoIcon = ({ type }) => {
  if (type === MessageConstants.NUMERIC || type === MessageConstants.DATE) {
    const allowedChar =
      type === MessageConstants.DATE ? DATE_TYPE_ALLOWED_CHAR : NUMERIC_TYPE_ALLOWED_CHAR;
    return (
      <Tooltip
        title={
          <>
            Allowed characters (case insensitive):
            <br />
            {allowedChar.map((char) => (
              <Box
                component="span"
                sx={{
                  background: '#fff3',
                  borderRadius: '4px',
                  marginRight: '4px',
                  px: '4px'
                }}
                key={char}>
                {char}
              </Box>
            ))}
          </>
        }>
        <Info sx={{ height: '1rem' }} />
      </Tooltip>
    );
  } else {
    return <></>;
  }
};

const FormatCell = ({ row, column: { accessor } }) => {
  return (
    <TextField
      size="small"
      fullWidth
      disabled={![MessageConstants.NUMERIC, MessageConstants.DATE].includes(row.dataType)}
      value={row[accessor]}
      onChange={(e) =>
        row?.updateColumnSetting(
          row.id,
          accessor,
          row.dataType === MessageConstants.NUMERIC
            ? e.target.value.replace(NUMERIC_TYPE_FILTER_REGEX, '')
            : e.target.value.replace(DATE_TYPE_FILTER_REGEX, '')
        )
      }
      icon={<FormatInfoIcon type={row.dataType} />}
    />
  );
};

const IntegerCell = ({ row, column: { accessor } }) => {
  return (
    <TextField
      size="small"
      fullWidth
      value={row[accessor]}
      onChange={(e) => {
        row?.updateColumnSetting(row.id, accessor, e.target.value.replace(INT_FILTER_REGEX, ''));
      }}
    />
  );
};

const ToggleCell = ({ row, column: { accessor } }) => {
  return (
    <Switch
      checked={row[accessor]}
      size="small"
      onChange={() => {
        row?.updateColumnSetting(row.id, accessor, !row[accessor]);
      }}
    />
  );
};

const DATA_TYPE_FILTERS = [
  MessageConstants.ALPHANUMERIC,
  MessageConstants.NUMERIC,
  MessageConstants.DATE
];

const columns = [
  {
    header: 'Domain Variable',
    accessor: 'datasetColumn',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('datasetColumn'),
    filterComponent: TextFieldFilter,
    fixedWidth: false
  },
  {
    header: 'Primary Key',
    accessor: 'isPrimaryKey',
    sortFunction: compareNumbers,
    filterFunction: createSelectDropdownFilter('isPrimaryKey', [true, false]),
    filterComponent: ToggleFilter,
    customCell: ToggleCell,
    fixedWidth: false
  },
  {
    header: 'Required',
    accessor: 'isRequiredField',
    sortFunction: compareNumbers,
    filterFunction: createSelectDropdownFilter('isRequiredField', [true, false]),
    filterComponent: ToggleFilter,
    customCell: ToggleCell,
    fixedWidth: false
  },
  {
    header: 'Data Type',
    accessor: 'dataType',
    sortFunction: compareStrings,
    filterFunction: createSelectDropdownFilter('dataType', DATA_TYPE_FILTERS),
    filterComponent: createSelectFilterComponent(DATA_TYPE_FILTERS, {
      size: 'small',
      multiple: true
    }),
    SelectFilter: ToggleFilter,
    customCell: SelectCell,
    fixedWidth: false
  },
  {
    header: 'Format',
    accessor: 'format',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('format'),
    filterComponent: TextFieldFilter,
    customCell: FormatCell,
    fixedWidth: false
  },
  {
    header: 'Min Length',
    accessor: 'minLength',
    sortFunction: compareNumbers,
    filterFunction: numberSearchFilter('minLength'),
    filterComponent: IntegerFilter,
    customCell: IntegerCell,
    fixedWidth: false
  },
  {
    header: 'Max Length',
    accessor: 'maxLength',
    sortFunction: compareNumbers,
    filterFunction: numberSearchFilter('maxLength'),
    filterComponent: IntegerFilter,
    customCell: IntegerCell,
    fixedWidth: false
  }
];

const ColumnSettingsTable = ({
  selectedDomainColumnSettings,
  selectedDomain,
  isLoading,
  handleSaveDomainColumnSettings
}) => {
  const [columnSettings, setColumnSettongs] = useState([]);
  const dispatch = useDispatch();
  const {
    CONTINUE_WITHOUT_SAVING,
    COLUMN_SETTINGS_UNSAVED_CHANGES_WARNING_MESSAGE,
    KEEP_EDITING,
    VARIABLES
  } = useGlobalMessage();
  const { unSavedChanges } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const updateColumnSetting = (id, key, value) => {
    setColumnSettongs((_columnSettings) => {
      const modifiedColumnSettings = [..._columnSettings];
      modifiedColumnSettings.forEach((columnSetting) => {
        if (columnSetting.id === id) {
          if (key === 'dataType' && value !== columnSetting[key]) {
            columnSetting.format = '';
          }
          columnSetting[key] = value;
        }
      });
      return modifiedColumnSettings;
    });
  };

  useEffect(() => {
    setColumnSettongs(
      selectedDomainColumnSettings.map((colSetting) => ({
        ...colSetting,
        updateColumnSetting,
        key: colSetting.id
      }))
    );
  }, [selectedDomainColumnSettings]);

  const checkUnsavedChanges = () => {
    if (selectedDomainColumnSettings?.length !== columnSettings?.length) {
      return;
    }
    const hasChanges = columnSettings.some((colSetting, index) => {
      const originalSetting = selectedDomainColumnSettings[index];
      return (
        colSetting.datasetColumn === originalSetting.datasetColumn &&
        (colSetting.dataType !== originalSetting.dataType ||
          colSetting.format !== originalSetting.format ||
          (colSetting.minLength || '') != (originalSetting.minLength || '') ||
          (colSetting.maxLength || '') != (originalSetting.maxLength || '') ||
          colSetting.isPrimaryKey != originalSetting.isPrimaryKey ||
          colSetting.isRequiredField != originalSetting.isRequiredField)
      );
    });
    let _unsavedChanges = {};
    if (hasChanges) {
      _unsavedChanges = {
        title: CONTINUE_WITHOUT_SAVING,
        subtitle: COLUMN_SETTINGS_UNSAVED_CHANGES_WARNING_MESSAGE,
        primaryLabel: CONTINUE_WITHOUT_SAVING,
        secondaryLabel: KEEP_EDITING
      };
    }

    dispatch(setUnSavedChanges(_unsavedChanges));
  };

  useEffect(debounce(checkUnsavedChanges, 400), [columnSettings, selectedDomainColumnSettings]);

  const handleSave = () => {
    handleSaveDomainColumnSettings(selectedDomain, columnSettings);
  };

  return (
    <Box
      sx={{
        // Fix for filters miss align when using isSticky, need to review again up on apollo-react upgrade
        '& thead>tr>th': {
          top: '0 !important'
        }
      }}>
      <Table
        title={selectedDomain}
        subtitle={`${columnSettings.length} ${VARIABLES}`}
        columns={columns}
        rows={columnSettings}
        rowsPerPageOptions={[10, 20, 50, 100, 'All']}
        hasScroll
        isSticky
        stickyHeaderTop={0}
        maxHeight={750}
        tablePaginationProps={{
          truncate: true
        }}
        CustomHeader={CustomButtonHeader}
        headerProps={{
          handleSave,
          unSavedChanges
        }}
        defaultRowsPerPage={10}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default ColumnSettingsTable;
