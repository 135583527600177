import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Box from 'apollo-react/components/Box';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Modal from 'apollo-react/components/Modal';
import Step from 'apollo-react/components/Step';
import StepLabel from 'apollo-react/components/StepLabel';
import Stepper from 'apollo-react/components/Stepper';
import Typography from 'apollo-react/components/Typography';
import SourceData from 'Pages/ProductDesigner/Components/Preview/Components/SourceData';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DomainData from 'Pages/ProductDesigner/Components/Preview/Components/DomainData';
import { setselectedDomainSourceData } from 'Redux/Slice/DomainPeviewSlice';
import { useDispatch } from 'react-redux';
import { PREVIEW_TYPES } from 'Pages/ProductDesigner/Components/Preview/Components/PreviewTypes';

const PreviewHeader = ({ mappingRuleVersionID, domainCode }) => {
  const [showLeavePageModal, setShowLeavePageModal] = useState(false);
  const navigate = useNavigate();

  const handleReturnButton = () => {
    setShowLeavePageModal(!showLeavePageModal);
  };

  const handleClose = () => {
    setShowLeavePageModal(!showLeavePageModal);
  };
  return (
    <Grid container spacing={2} style={{ marginBottom: '2rem' }}>
      <Modal
        open={showLeavePageModal}
        variant="warning"
        onClose={() => handleClose()}
        title="Leave Preview?"
        message="Are you sure you want to leave this page?"
        buttonProps={[
          { label: 'Cancel', onClick: () => handleClose() },
          {
            label: 'Ok',
            onClick: () => {
              navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/column-settings`, {
                state: {
                  domainCode
                }
              });
            }
          }
        ]}
        id="warning"
      />
      <Grid item xs={12} style={{ display: 'flex' }}>
        <Button
          variant="secondary"
          size="small"
          icon={ChevronLeft}
          style={{ marginRight: 10, color: '#0768FD', marginBottom: '10px' }}
          onClick={handleReturnButton}>
          Return to Column Settings
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="title1" gutterBottom>
          Column Settings
        </Typography>
        <Typography variant="title2" gutterBottom>
          {domainCode}
        </Typography>
      </Grid>
    </Grid>
  );
};

const steps = ['Source Data', 'Preview Data'];

const ColumnSettingsPreview = () => {
  const [activeStep, setActiveStep] = useState();
  const { id: mappingRuleVersionID } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const onStepClick = (index) => {
    if (activeStep > index) {
      setActiveStep(index);
    }
  };

  const handleNext = () => {
    if (activeStep === 0 || activeStep < 2) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  useEffect(() => {
    dispatch(setselectedDomainSourceData({}));
    setActiveStep(0);
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection={'column'}
      style={{ padding: '0rem 2rem', width: '100%', boxSizing: 'border-box' }}>
      <PreviewHeader
        mappingRuleVersionID={mappingRuleVersionID}
        domainCode={location.state?.domainCode || ''}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          style={{ background: 'none', marginBottom: '2rem', width: '70%' }}>
          {steps.map((label, i) => (
            <Step key={label} style={{ cursor: 'pointer' }} onClick={() => onStepClick(i)}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      {activeStep === 0 && (
        <SourceData
          handleNext={handleNext}
          handleBack={handleBack}
          type={PREVIEW_TYPES.VLCRULES}
          mdsDomainActive={true}
          domainCode={location.state?.domainCode}
          prevRequired={false}
        />
      )}
      {activeStep === 1 && (
        <DomainData
          mdsDomainActive={true}
          type={PREVIEW_TYPES.VLCRULES}
          domainCode={location.state?.domainCode}
          isColumnSettings={true}
        />
      )}
    </Box>
  );
};

export default ColumnSettingsPreview;
