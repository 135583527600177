export const IS_SUPPQUAL = { Y: 'Y', N: 'N' };
export const DATASET_NAME_MT = 'MEDDRA_THESAURUS';
export const DATASET_NAME_DT = 'DRUG_THESAURUS';
export const NON_ECRF_LIBRARY_NAME = "non_ecrf_library_name";
export const NON_ECRF_PRODUCT_MNEMONIC = 'non_ecrf_product_mnemonic';
export const ADD_NON_ECRF_DATA_PRODUCT = 'add-non-ecrf-data-product';
export const QCTMS_DATA_PRODUCT = 'qctms-data-product';
export const SDTM_DATA_PRODUCT = 'sdtm-data-product';
export const SDTM_PRODUCT_MNEMONIC = 'sdtm_product_mnemonic';
export const SDTM_GLOBAL_LIBRARY_NAME = 'sdtm_global_library_name';
export const YES = 'Yes';
export const NO = 'No';