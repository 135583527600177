import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;

export const GetAssignedStudyLibraries = createAsyncThunk(
  '/dataProduct/GetAssignedStudyLibraries',
  async () => {
    return await axios.get(
      `${API_URL}/api/DataProduct/GetAssignedStudyLibraries?userId=${Cookies.get('user.id')}`
    );
  }
);
export const SavePinnedStudies = createAsyncThunk(
  '/dataProduct/SavePinnedStudies',
  async (payload) => {
    return await axios.post(`${API_URL}/api/DataProduct/SavePinnedStudies`, payload);
  }
);

export const GetNonECRFDatasets = createAsyncThunk(
  'DataProduct/GetNonECRFDatasets',
  async (params) => {
    return await axios.get(`${API_URL}/api/DataProduct/GetNonECRFDatasets`, {
      params
    });
  }
);

export const RunNonEcrfDatasets = createAsyncThunk(
  'DataProduct/RunNonEcrfDatasets',
  async (payload) => {
    return await axios.post(`${API_URL}/api/DataProduct/RunNonEcrfDatasets`, payload);
  }
);

export const GetReservedLibraryInfo = createAsyncThunk(
  'DataProduct/GetReservedLibraryInfo',
  async () => {
    return await axios.get(`${API_URL}/api/DataProduct/GetReservedLibraryInfo`);
  }
);

export const SaveNonEcrfDataproduct = createAsyncThunk(
  'DataProduct/SaveNonEcrfDataproduct',
  async (payload) => {
    return await axios.post(`${API_URL}/api/DataProduct/SaveNonEcrfDataproduct`, payload);
  }
);

export const CreateSDTMDataProduct = createAsyncThunk(
  'DataProduct/CreateSDTMDataProduct',
  async (payload) => {
    return await axios.post(`${API_URL}/api/DataProduct/CreateSDTMDataProduct`, payload);
  }
);
