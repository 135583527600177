/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Typography from 'apollo-react/components/Typography';
import { neutral1 } from 'apollo-react/colors';
import { jsonToExcel, convertExcelToJson, uuidv4, getHeaders } from 'Utils';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import Variable from './Variable';

import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import Button from 'apollo-react/components/Button';
import PlusIcon from 'apollo-react-icons/Plus';
import Upload from 'apollo-react-icons/Upload';
import { Download } from 'apollo-react-icons';

import { GetRuleExpressionMethodsAndConstants } from 'Redux/Service/MasteringRuleService';
import { GetRuleExpressionTemplates } from 'Redux/Service/DomainRuleService';
import {
  getMethodsList,
  getRuleList
} from 'Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/Utils/DomainUtils';
import { useDispatch } from 'react-redux';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { methodsAndConstantsMapping } from 'Pages/ProductDesigner/Components/RuleEditor/MethodandConstantMapping';
import RulesetSelector from './RulesetSelector';
import FolderOpen from 'apollo-react-icons/FolderOpen';
import Modal from 'apollo-react/components/Modal';
import StatusExclamationIcon from 'apollo-react-icons/StatusExclamation';
import { checkColumns, checkFileData } from 'Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/Utils/FileValidationUtils';
import _ from 'lodash';

const useStyles = makeStyles({
  container: {
    fontFamily: 'Proxima Nova',
    boxShadow: '0px 1px 3px #0002'
  },
  floatingContainer: {
    position: 'sticky',
    top: 0,
    backgroundColor: neutral1,
    zIndex: 2,
    marginTop: '1rem'
  },
  header: {
    padding: '1rem',
    backgroundColor: 'white',
    borderRadius: '4px 4px 0 0'
  },
  headerTitle: {
    fontWeight: '600'
  },
  headerSubTitle: {
    fontSize: '14px',
    color: '#444'
  },
  select: {
    '& div[class*="makeStyles-hint-"] ': {
      color: '#0768fd',
      fontWeight: '500'
    },
    '& div [class*="MuiSelect-icon"]': {
      color: '#0768fd',
      fontWeight: '500'
    },
    margin: '8px 5px 0px 5px'
  },
  columnsHeader: {
    '&>div': {
      padding: '1rem',
      color: '#444'
    }
  },
  previewHeader: {
    boxShadow: '0px -1px 3px #8881'
  },
  modal: {
    maxWidth: 450
  }
});

const RulesetColumns = [
  {
    VariableName: '',
    Label: '',
    Expression: ''
  }
];

const columns = ['VariableName', 'Label', 'Expression'];

const allowedTypes = ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'csv'];

const RuleSetEditor = (props) => {
  const {
    uploadLibraryRuleSet,
    ruleSetValidationResult,
    preview,
    setFileUploadInfoRuleSet,
    setUploadLibraryRuleSet,
    stickyTop,
    isStudyLibrary,
    previewTitle,
    showOnlyActive
  } = props;

  const [rowData, setRowData] = useState(uploadLibraryRuleSet?.length ? uploadLibraryRuleSet : []);
  const [methodsList, setMethodsList] = useState([]);
  const [expressionList, setExpressionList] = useState([]);
  const [caretPosition, setCaretPosition] = useState({
    position: 0,
    id: '',
    rulesetIndex: -1,
    inputIndex: -1
  });
  const [displayImportRuleset, setDisplayImportRuleset] = useState(false);
  const [selectedGlobalRulesetLibrary, setSelectedGlobalRulesetLibrary] = useState([]);
  const [showConfirmDailog, setConfirmDailog] = useState(false);
  const [uploadRulesetModal, setUploadRulestModal] = useState(false);
  const rulesetVariableLength = rowData?.length > 1 ? 'Variables' : 'Variable';
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileInput = React.useRef(null);

  const getMethodsAndConstants = async () => {
    const methodsAndConstantsResponse = await dispatch(GetRuleExpressionMethodsAndConstants()).then(
      unwrapResult
    );
    const method = getMethodsList(methodsAndConstantsResponse);
    method === false
      ? dispatch(
          showBanner({ variant: 'error', message: methodsAndConstantsResponse?.data?.message })
        )
      : setMethodsList(method.methods);
  };

  const getRuleExpressionTemplates = async () => {
    const expressionTemplatesResponse = await dispatch(GetRuleExpressionTemplates(true)).then(
      unwrapResult
    );
    const ruleExp = getRuleList(expressionTemplatesResponse);
    ruleExp === false
      ? dispatch(
          showBanner({ variant: 'error', message: expressionTemplatesResponse?.data?.message })
        )
      : setExpressionList(ruleExp);
  };

  useEffect(() => {
    getMethodsAndConstants();
    getRuleExpressionTemplates();
  }, []);

  const toggleEditMode = (editMode, variableRuleSetID) => {
    setRowData((_rowData) =>
      _rowData.map((_row) => {
        if (_row.variableRuleSetID === variableRuleSetID) {
          return { ..._row, editMode };
        } else return _row;
      })
    );
  };

  const onDelete = (variableRuleSetID) => {
    const filteredData = rowData.filter((item) => item.variableRuleSetID !== variableRuleSetID);
    console.log(filteredData, 'deleteeee');
    setRowData(filteredData);
  };

  const handleToggleRow = (isExpanded, variableRuleSetID) => {
    setRowData((_rowData) =>
      _rowData.map((_row) => {
        if (_row.variableRuleSetID === variableRuleSetID) {
          return { ..._row, expanded: isExpanded };
        } else return _row;
      })
    );
  };

  const handleEditRow = (value, key, variableRuleSetID) => {
    setRowData((_rowData) => {
      let modifiedRowsData = [..._rowData];
      modifiedRowsData = modifiedRowsData.map((_row) => {
        if ((_row.id || _row.variableRuleSetID) === variableRuleSetID) {
          if (JSON.stringify(_row[key]) !== JSON.stringify(value)) {
            return { ..._row, [key]: value };
          }
        }
        return _row;
      });
      return modifiedRowsData;
    });
  };

  const handleAddVariable = () => {
    setRowData((_rowData) => {
      const variableRuleSetID = uuidv4();
      const newRow = {
        variableRuleSetID,
        key: variableRuleSetID,
        variableName: '',
        label: '',
        expressions: [{ id: uuidv4(), expression: '' }],
        status: true,
        toggleEditMode,
        onDelete,
        handleToggleRow,
        editRow: handleEditRow,
        editMode: true,
        expanded: true,
        isValid: false
      };
      return [newRow, ..._rowData];
    });
  };

  useEffect(() => {
    if (!rowData?.length) handleAddVariable();
    if (!preview) {
      setUploadLibraryRuleSet(rowData);
    }
  }, [rowData]);

  const handleDownloadTemplate = () => {
    jsonToExcel(RulesetColumns, 'RuleSet.xlsx');
  };
  const allowedExtensions = ['xlsx', 'xls'];

  const fileValidation = (fileData, headers) => {
    const isFileValid = checkFileData(fileData);
    const isColumnsValid = checkColumns(headers, columns);
    let error = '';
    if (!isFileValid && !isColumnsValid) {
      error = 'File is empty and columns are mismatched';
    } else if (isFileValid && !isColumnsValid) {
      error = 'columns are mismatched';
    } else if (!isFileValid && isColumnsValid) {
      error = 'File is empty';
    } else {
      error = '';
    }
    return error;
  };

  const handleUploadRuleset = async (e) => {
    const selectedFiles = e.target.files;
    const size = selectedFiles[0].size;

    if (size < 10 * 1024 * 1024) {
      const file = selectedFiles[0].name.split('.');
      const extension = file[file.length - 1];
      if (allowedTypes.includes(extension)) {
        const fileJSON = await convertExcelToJson(selectedFiles[0]);
        const headers = await getHeaders(selectedFiles[0]);
        const isError = fileValidation(fileJSON, headers);
        if (!isError) {
          let newFileArr = [];
          fileJSON.map((obj) => {
            const newObj = { ...RulesetColumns[0], ...obj };
            var lowerObj = _.transform(newObj, function (result, val, key) {
              result[key.charAt(0).toLowerCase() + key.slice(1)] = val;
            });
            lowerObj.expressions = lowerObj.expression?.trim()
              ? lowerObj.expression
                  .split('\n')
                  .filter((expr) => expr?.trim())
                  .map((expr) => ({ id: uuidv4(), expression: expr }))
              : [{ id: uuidv4(), expression: '' }];
            lowerObj.variableRuleSetID = uuidv4();
            lowerObj.status = true;
            lowerObj.editRow = handleEditRow;
            newFileArr.push(lowerObj);
          });
          setFileUploadInfoRuleSet(selectedFiles);
          setRowData(newFileArr);
        } else {
          dispatch(
            showBanner({
              variant: 'error',
              message:
                'File not uploaded: Column headers in your file contains errors; double-check and try again.'
            })
          );
        }
      } else {
        dispatch(
          showBanner({
            variant: 'error',
            message:
              'File not uploaded: The file you are trying to upload is not a valid format, only .xlsx, .xlsm, .xlsb, .xltx, .xltm, .csv  files are permitted.'
          })
        );
      }
    } else {
      dispatch(
        showBanner({
          variant: 'error',
          message: 'File not uploaded: The file exceeds the maximum size of 10 Mb.'
        })
      );
    }
    e.target.value = null;
  };

  const handleSelect = (e, type) => {
    const { position, id, rulesetIndex, inputIndex } = caretPosition;
    if (!id || rulesetIndex === -1 || inputIndex === -1) return;
    let lastSelectedValue = rowData[rulesetIndex]?.expressions[inputIndex]?.expression;
    if (lastSelectedValue === undefined) return;
      
    lastSelectedValue = lastSelectedValue.split(''); // split the string

    if (type === 'EXPRESSION_CONSTANT') {
      const value = e.target.value.split('');
      lastSelectedValue.splice(position, 0, ...value); // append the method or constant value in between
    } else if (type === 'METHOD_CONSTANT') {
      const value = methodsAndConstantsMapping[e.target.value];
      lastSelectedValue.splice(position, 0, ...value); // append the source field value in between
    } else {
      return;
    }

    lastSelectedValue = lastSelectedValue.join(''); // join back to string
    setCaretPosition((prev) => ({ ...prev, position: lastSelectedValue.length }));
    
    const updatedExpressions = [...rowData[rulesetIndex].expressions];
    updatedExpressions[inputIndex] = {
      ...updatedExpressions[inputIndex],
      expression: lastSelectedValue
    };
    handleEditRow(updatedExpressions, 'expressions', id);
  };

  const handleApplyRuleset = (rulesetVariables) => {
    setSelectedGlobalRulesetLibrary(rulesetVariables);
    setDisplayImportRuleset(false);

    if (
      rowData.every(
        (row) =>
          row?.variableName === '' &&
          row?.label === '' &&
          row?.expressions?.every((e) => e.expression.trim() === '')
      )
    ) {
      setRowData(rulesetVariables);
      setSelectedGlobalRulesetLibrary([]);
    } else {
      setConfirmDailog(true);
    }
  };

  const confirmModalButtonProps = [
    {
      label: 'Cancel',
      onClick: () => {
        setSelectedGlobalRulesetLibrary([]);
        setConfirmDailog(false);
      }
    },
    {
      label: 'Confirm',
      onClick: () => {
        setRowData(selectedGlobalRulesetLibrary);
        setSelectedGlobalRulesetLibrary([]);
        setConfirmDailog(false);
      }
    }
  ];

  const uploadRulesetButtonProps = [
    {
      label: 'Cancel',
      onClick: () => {
        setUploadRulestModal(false);
      }
    },
    {
      label: 'Upload ruleset',
      onClick: () => {
        fileInput.current.click();
        setUploadRulestModal(false);
      }
    }
  ];

  const uploadRulesetModalTitle = () => {
    if (!rowData.every((el) => el.label === '' && el.variableName === '' && el?.expressions?.every((e) => e.expression.trim() === ''))) {
      return 'Upload and replace ruleset?';
    } else {
      return 'Upload ruleset?';
    }
  };

  const handleCaretPositionChange = (pos, id, rulesetIndex, inputIndex) => {
    setCaretPosition((prev) => ({
      ...prev,
      position: pos,
      id,
      rulesetIndex,
      inputIndex
    }));
  };

  const handleMoveVariableRow = (moveFrom, moveTo) => {
    setRowData((_rowData) => {
      const sortedRowData = [..._rowData];
      let [variableRowToMove] = sortedRowData.splice(moveFrom, 1);
      sortedRowData.splice(moveTo, 0, variableRowToMove);
      return sortedRowData;
    });
  };

  return (
    <>
      <>
        <Box className={classes.container}>
          <Box
            draggable={false}
            display={'flex'}
            flexDirection={'column'}
            className={classes.floatingContainer}
            style={{ top: stickyTop, marginTop: preview && '0px' }}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              className={[classes.header, preview && classes.previewHeader]}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                width={'100%'}
                alignItems={'center'}>
                <Box>
                  <Typography className={classes.headerTitle}>
                    {preview ? previewTitle : 'Ruleset Variables'}
                  </Typography>
                  <Typography className={classes.headerSubTitle}>
                    {preview ? 'Ruleset' : `${rowData?.length} ${rulesetVariableLength}`}
                  </Typography>
                </Box>
                {!preview && (
                  <Box
                    sx={{
                      '& >button': {
                        marginLeft: '0.5rem'
                      }
                    }}>
                    {isStudyLibrary ? (
                      <Button
                        size={'small'}
                        icon={<FolderOpen />}
                        onClick={() => setDisplayImportRuleset(true)}>
                        Import Ruleset
                      </Button>
                    ) : (
                      <></>
                    )}
                    <Button
                      size={'small'}
                      icon={<Upload />}
                      onClick={() => setUploadRulestModal(true)}>
                      Upload Ruleset
                    </Button>
                    {uploadRulesetModal ? (
                      <Modal
                        open={uploadLibraryRuleSet}
                        variant={'error'}
                        title={
                          <Grid container>
                            <Grid xs={1}>
                              <StatusExclamationIcon />
                            </Grid>
                            <Grid xs={10}>{uploadRulesetModalTitle()}</Grid>
                          </Grid>
                        }
                        data-testid="upload-ruleset-modal"
                        className={classes.modal}
                        buttonProps={uploadRulesetButtonProps}
                        onClose={() => {
                          setUploadRulestModal(false);
                        }}>
                        {
                          'All the expression in the ruleset will be replaced with the imported ones and cannot be restored.'
                        }
                      </Modal>
                    ) : (
                      <></>
                    )}
                    <input
                      onChange={handleUploadRuleset}
                      ref={fileInput}
                      type="file"
                      style={{ display: 'none' }}
                      data-testid="file-input"
                    />
                    <Button size={'small'} icon={<Download />} onClick={handleDownloadTemplate}>
                      Download Template
                    </Button>
                  </Box>
                )}
              </Box>
              {!preview && (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  <Box>
                    <Button
                      size={'small'}
                      icon={<PlusIcon />}
                      variant={'secondary'}
                      onClick={handleAddVariable}>
                      Add Variable
                    </Button>
                  </Box>
                  <Box display={'flex'} flexDirection={'row'}>
                    <Select
                      data-testid='add-method'
                      className={classes.select}
                      onChange={(e) => handleSelect(e, 'METHOD_CONSTANT')}
                      placeholder={'Add Method'}
                      style={{ marginRight: '10px' }}
                      size={'small'}>
                      {methodsList.map((m) => (
                        <MenuItem value={m} key={m}>
                          {m}
                        </MenuItem>
                      ))}
                    </Select>
                    <Select
                      data-testid='add-expression'
                      className={classes.select}
                      onChange={(e) => handleSelect(e, 'EXPRESSION_CONSTANT')}
                      placeholder={'Add Expression Template'}
                      size={'small'}>
                      {expressionList
                        .filter((item) => item.templateExpressionType.includes('D'))
                        .map((item, index) => (
                          <MenuItem value={item.templateExpression} key={`Method-${index}`}>
                            {item.templateExpression}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                </Box>
              )}
            </Box>

            <Box width={'100%'} display={'flex'}>
              <Grid container className={classes.columnsHeader}>
                <Grid xs={12} style={{ fontSize: '16px', marginLeft: '1.5rem' }}>
                  Variable Details
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box width={'100%'} display={'flex'}>
            <Grid container>
              {rowData
                ?.filter((row) => (showOnlyActive ? row.status : true))
                .map((row, index) => (
                  <Variable
                    {...row}
                    index={index}
                    rowCount={rowData?.length}
                    editRow={handleEditRow}
                    onDelete={onDelete}
                    ruleSetValidationResult={ruleSetValidationResult}
                    handleCaretPositionChange={handleCaretPositionChange}
                    preview={preview}
                    rowData={rowData}
                    key={row.variableRuleSetID}
                    onMoveVariableRow={handleMoveVariableRow}
                  />
                ))}
            </Grid>
          </Box>
        </Box>
      </>
      {displayImportRuleset ? (
        <RulesetSelector
          key={'ImportRuleset'}
          open={displayImportRuleset}
          setOpen={setDisplayImportRuleset}
          onApplyRuleset={handleApplyRuleset}
          modalTitle={'Import Ruleset'}
          modalSubtitle={'Select a previously created ruleset to import into your current work.'}
        />
      ) : (
        <></>
      )}
      {showConfirmDailog ? (
        <Modal
          open={showConfirmDailog}
          variant={'warning'}
          title={'Replace ruleset!'}
          data-testid="confirm-modal"
          buttonProps={confirmModalButtonProps}
          onClose={() => {
            setSelectedGlobalRulesetLibrary([]);
            setConfirmDailog(false);
          }}>
          {
            'All the expression in the ruleset will be replaced with the imported ones and cannot be restored.'
          }
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default RuleSetEditor;
