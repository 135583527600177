import { dateFormatByType, jsonToExcelPublishDownload } from 'Utils';
import { getTimePointsSheet } from '../RuleEditor/SelectDataSource/Utils/CtpSvtUtils';
import { MessageConstants } from 'Utils/MessageConstants';
import { TARGET_MODEL_TYPES, isRulesetType } from 'Constants/TargetModelTypes';
import { NO, YES } from 'Utils/Constants';

export const getDomainLists = (domainLists) => {
  if (domainLists && Object.keys(domainLists).length > 0) {
    let obj = {};
    const domainKeys = Object.keys(domainLists);
    domainKeys.forEach((key) => {
      let arr = [];
      domainLists[key].forEach((item) => {
        const {
          filterName,
          defaultFormMnemonic,
          filterCondition,
          filterConditionOperator,
          // protocolID,
          variableName,
          expression,
          isMapRowActive
        } = item;
        let row = {};
        let index = -1;
        if (isMapRowActive) {
          const element = arr.find((el, i) => {
            index = i;
            return el['FILTER NAME'] === filterName;
          });
          if (element) {
            if (Object.keys(element).includes(variableName)) {
              row = {
                ...element,
                [variableName]: `${element[variableName]}\n${expression}`
              };
            } else {
              row = { ...element, [variableName]: expression };
            }
            arr[index] = row;
          } else {
            row = {
              'FILTER NAME': filterName,
              'DEFAULT FORM/DATA FILE MNEMONIC': defaultFormMnemonic,
              'FILTER CONDITION': filterCondition,
              'FILTER CONDTION OPERATOR': filterConditionOperator
              // PROTOCOLID: protocolID,
            };
            row['Q_ROW_CONDITION_OP'] = filterConditionOperator;
            row['Q_ROW_CONDITION'] = filterCondition;
            row[variableName] = expression;
            delete row['FILTER CONDITION'];
            delete row['FILTER CONDTION OPERATOR'];
            arr.push(row);
          }
        }
      });
      obj[key] = arr;
    });
    return obj;
  } else {
    return {};
  }
};

export const getMDSDomainLists = (domainLists, targetDataModelType) => {
  if (domainLists && Object.keys(domainLists).length > 0) {
    const mdsDomainRules = [];

    Object.keys(domainLists).forEach((key) => {
      domainLists[key].forEach((item) => {
        const { variableName, expression, isMapRowActive, lineage } = item;
        if (isMapRowActive) {
          const rule = {
            Dataset: key,
            VariableName: variableName,
            Expression: expression
          };
          if (targetDataModelType === TARGET_MODEL_TYPES.RULESET) {
            rule.Lineage = lineage;
          }
          mdsDomainRules.push(rule);
        }
      });
    });
    return mdsDomainRules;
  } else {
    return [];
  }
};

export const getMasteringSettings = (masteringSettings, getDisplayName) => {
  let arr = [];
  let index = -1;
  masteringSettings.forEach((setting) => {
    const { variableName, expression, sourceID, defaultForm } = setting;
    let row = {};
    const element = arr.find((el, i) => {
      index = i;
      return el['DEFAULT FORM'] === defaultForm;
    });

    if (element) {
      if (Object.keys(element).includes(variableName)) {
        row = {
          ...element,
          [variableName]: `${element[variableName]}\n${expression}`
        };
      } else {
        row = {
          ...element,
          [variableName]: expression
        };
      }
      arr[index] = row;
    } else {
      row = {
        SOURCE: getDisplayName(sourceID),
        'DEFAULT FORM': defaultForm,
        [variableName]: expression
      };
      arr.push(row);
    }
  });
  return arr;
};

export const getVisitMasteringFilters = (visitMasteringFilterExps, getDisplayName) => {
  if (visitMasteringFilterExps && visitMasteringFilterExps.length > 0) {
    let arr = [];
    visitMasteringFilterExps.forEach((item) => {
      const {
        filterName,
        defaultFormMnemonic,
        variableName,
        expression,
        isMapRowActive,
        filterCondition,
        filterConditionOperator,
        sourceID
      } = item;
      let row = {};
      let index = -1;
      if (isMapRowActive) {
        const element = arr.find((el, i) => {
          index = i;
          return el['FILTER NAME'] === filterName;
        });
        if (element) {
          if (Object.keys(element).includes(variableName)) {
            row = {
              ...element,
              [variableName]: `${element[variableName]}\n${expression}`
            };
          } else {
            row = { ...element, [variableName]: expression };
          }
          arr[index] = row;
        } else {
          row = {
            'FILTER NAME': filterName,
            'DEFAULT FORM/DATA FILE MNEMONIC': defaultFormMnemonic,
            Q_ROW_CONDITION_OP: filterConditionOperator,
            Q_ROW_CONDITION: filterCondition,
            SOURCE: getDisplayName(sourceID)
          };
          row[variableName] = expression;
          arr.push(row);
        }
      }
    });
    return arr;
  } else {
    return [];
  }
};

export const downloadFile = (data, params, name, userDetails) => {
  const {
    dataSources,
    timePoints,
    trialVisits,
    ctpTimePoints,
    suppQuals,
    masteringRulesGeneralSetting,
    masteringRulesSubjectSetting,
    masteringRulesVisitSetting,
    domainLists,
    trialArms,
    sourceEvents,
    ruleCodeLists,
    trialElements,
    trialinclusionExclusions,
    unMappedOdmItems,
    unMappedSourceEvents,
    postSQL: additionalDomain,
    customSQLData,
    derivedVisitSettings,
    sourceItems,
    variables,
    rulesets,
    datasets,
    rulesetSourceItems,
    odmForms,
    odmItems,
    vlcRulesData,
    meddraThesaurus,
    drugThesaurus,
    vlcColumnSettings
  } = data;

  const {
    protocolName,
    projectCode,
    sponsor,
    mappingSpecVersionName,
    productMnemonic,
    targetDataModelName,
    targetDataModelVersion,
    targetDataModelType,
    getDisplayName,
    getMessage
  } = params;

  const NO_DATA = [undefined, null, ''];
  const visitName = derivedVisitSettings?.derivedVisitSettingName;
  const prefix = derivedVisitSettings?.prefixOrSuffix;
  const prefix_text = derivedVisitSettings?.text;
  const NonRulesetType = !isRulesetType(targetDataModelType);

  const DERIVED_VISIT_NAME = !NO_DATA.includes(visitName) ? visitName : 'N/A';
  const DERIVED_VISIT_PREFIX_SUFFIX = !NO_DATA.includes(prefix) ? prefix : 'N/A';
  const DERIVED_VISIT_TEXT = !NO_DATA.includes(prefix_text) ? prefix_text : 'N/A';

  const title = [
    { title: 'Data Alignment Specification' },
    { title: 'for' },
    { title: `Protocol Number: ${protocolName}` },
    { title: `Project Code: ${projectCode}` },
    { title: `Sponsor: ${sponsor}` },
    { title: `Mapping Specification Version: ${mappingSpecVersionName}` },
    { title: `Product Mnemonic: ${productMnemonic}` }
  ];

  const nonRulesetData = [
    { title: `Target Data Model: ${targetDataModelName}` },
    { title: `Target Data Model Version: ${targetDataModelVersion}` },
    {
      title: `Events Not Mapped Count: ${
        unMappedSourceEvents?.length ? unMappedSourceEvents?.length : 0
      }`
    },
    {
      title: `Items Not Mapped Count: ${unMappedOdmItems?.length ? unMappedOdmItems?.length : 0}`
    },
    { title: `Derived Visit Name_Condition: ${DERIVED_VISIT_NAME}` },
    { title: `Derived Visit Name _Prefix/Suffix: ${DERIVED_VISIT_PREFIX_SUFFIX}` },
    { title: `Derived Visit Name _Text: ${DERIVED_VISIT_TEXT}` },
    {
      title: `Created By: ${userDetails.createdBy}`
    },
    { title: `Created Date: ${userDetails.createdDate}` },
    {
      title: `${
        masteringRulesGeneralSetting !== null && masteringRulesGeneralSetting['SETTING NAME']
      }: ${masteringRulesGeneralSetting !== null && masteringRulesGeneralSetting['VALUE NAME']}`
    }
  ];

  let newTitle = NonRulesetType
    ? title.concat(nonRulesetData)
    : title.concat(nonRulesetData.slice(7, -1));

  const subjectSettings =
    NonRulesetType && getMasteringSettings(masteringRulesSubjectSetting, getDisplayName);
  const visitSettings =
    NonRulesetType && getVisitMasteringFilters(masteringRulesVisitSetting, getDisplayName);
  const domainList = NonRulesetType
    ? getDomainLists(domainLists)
    : getMDSDomainLists(domainLists, targetDataModelType);
  let domains = [];
  if (NonRulesetType && domainList && Object.keys(domainList).length > 0) {
    domains = Object.keys(domainList);
  }
  const sourceData = dataSources.map((item) => ({
    ...item,
    dataSetType: item.dataSetType || 'N/A',
    libraryType: getDisplayName(item.libraryType),
    dataSourceName: getDisplayName(item.dataSourceName),
    createdDate: dateFormatByType(item.createdDate, 'Table')
  }));

  const dataSets = datasets?.map((item) => ({
    ...item,
    dataSetType: item.dataSetType || 'N/A',
    rulesetName: item.rulesetName !== '' ? item.rulesetName : 'N/A'
  }));

  const ruleSets = rulesets?.map((item) => ({
    ...item,
    fieldExpression: item.fieldExpression.join('\n')
  }));

  const itemsNotMapped = unMappedOdmItems?.length
    ? unMappedOdmItems.map((item) => ({
        ...item,
        createdDate: dateFormatByType(item.createdDate, 'Table')
      }))
    : [];

  const eventsNotMapped = unMappedSourceEvents?.length
    ? unMappedSourceEvents.map((item) => ({
        ...item,
        createdDate: dateFormatByType(item.createdDate, 'Table')
      }))
    : [];

  const customSQLQueryData = customSQLData?.length
    ? customSQLData?.map((item) => {
        return {
          'Protocol Name': item.protocolName,
          'Study ID': item.studyID,
          'Study Library ID': item.studyLibraryID,
          'Study Library Version': item.studyLibraryVersion,
          'Data Source': item.dataSource,
          'Dataset Name': item.datasetName,
          Names: item.names,
          'Data Type': item.dataType,
          'User ID': item.userID,
          'Created Date': item.createdDate
        };
      })
    : [];

  const postSQLQueryData = additionalDomain?.length
    ? additionalDomain?.map((item) => {
        return {
          'Query Name': item.queryName,
          'Domain Name': item.domainName,
          Query: item.query,
          Description: item.comments
        };
      })
    : [];

  const vlcRules = vlcRulesData?.length
    ? vlcRulesData?.map((item) => {
        return {
          Active: item.isActive,
          'VLC Rule Name': item.ruleName,
          Dataset: item.dataset,
          'Columns to Raise Issue Against': item.columns,
          Expression: item.expression,
          'VLC Action Condition': item.vlcActionCondition,
          'VLC Action Message': item.vlcActionMessage,
          Description: item.description
        };
      })
    : [];

  const vlcColumnSettingsData = vlcColumnSettings?.length
    ? vlcColumnSettings?.map((item) => {
        return {
          'Dataset Name': item.datasetName,
          'Variable Name': item.datasetColumn,
          'Primary Key': item.isPrimaryKey ? YES : NO,
          Required: item.isRequiredField ? YES : NO,
          'Data Type': getMessage(item.dataType),
          Format: item.format,
          'Min Length': item.minLength,
          'Max Length': item.maxLength
        };
      })
    : [];

  const modifiedSourceEvents = NonRulesetType ? getTimePointsSheet(sourceEvents) : [];

  const timePointsData =
    targetDataModelType !== TARGET_MODEL_TYPES.IQVIA_CTP ? timePoints : ctpTimePoints;

  const newJsonResponse = [
    newTitle,
    sourceData,
    sourceItems,
    odmForms,
    odmItems,
    variables,
    modifiedSourceEvents,
    timePointsData,
    trialArms?.length && trialArms,
    ruleCodeLists?.length && ruleCodeLists,
    trialElements?.length && trialElements,
    trialinclusionExclusions?.length && trialinclusionExclusions,
    suppQuals?.length > 0 && suppQuals,
    subjectSettings,
    visitSettings,
    itemsNotMapped,
    eventsNotMapped,
    postSQLQueryData
  ];

  const newJsonResponseRuleset = [
    newTitle,
    dataSets,
    ruleSets,
    sourceData,
    rulesetSourceItems,
    ruleCodeLists?.length && ruleCodeLists,
    domainList,
    postSQLQueryData,
    vlcRules,
    vlcColumnSettingsData
  ];

  const TIME_POINTS =
    targetDataModelType !== TARGET_MODEL_TYPES.IQVIA_CTP ? 'Time Points' : 'CTP SVT';

  const sheetNames = [
    'Title',
    'Data Sources',
    'Source Items',
    'ODM Forms',
    'ODM Items',
    'Variables',
    'Source Events',
    TIME_POINTS,
    'trial Arms',
    'Code List',
    'trial Elements',
    'trial InclusionExclusions',
    'Supplemental Qualifiers',
    'Mastering Rules_Subject Setting',
    'Mastering Rules_Visit Setting',
    'Items Not Mapped',
    'Events Not Mapped',
    'Additional Domain Rules'
  ];
  if (NonRulesetType) {
    if (targetDataModelType !== TARGET_MODEL_TYPES.IQVIA_CTP) {
      newJsonResponse.splice(5, 0, trialVisits);
      sheetNames.splice(5, 0, 'Trial Visits');
    }
    if (odmForms.length === 0 && odmItems.length === 0) {
      newJsonResponse.splice(3, 2);
      sheetNames.splice(3, 2);
    } else if (odmForms.length === 0) {
      newJsonResponse.splice(3, 1);
      sheetNames.splice(3, 1);
    } else if (odmItems.length === 0) {
      newJsonResponse.splice(4, 1);
      sheetNames.splice(4, 1);
    }
  } else if (!NonRulesetType) {
    sheetNames.splice(1, 0, 'Datasets');
    sheetNames.splice(2, 0, 'Rulesets');
    sheetNames.splice(5);
    sheetNames.push('Code List');
  }

  if (NonRulesetType) {
    if (domains.length > 0) {
      domains.forEach((domain) => {
        sheetNames.push(domain);
        newJsonResponse.push(domainList[domain]);
      });
    }
  } else {
    sheetNames.push('Domain Rules');
    sheetNames.push('Additional Domain Rules');
    sheetNames.push('VLC Rules');
    sheetNames.push('Column Settings');
  }

  if (customSQLQueryData?.length) {
    newJsonResponse.push(customSQLQueryData);
    newJsonResponseRuleset.push(customSQLQueryData);
    sheetNames.push('Custom SQL');
  }

  if (meddraThesaurus?.length) {
    newJsonResponse.push(meddraThesaurus);
    sheetNames.push(getMessage(MessageConstants.MEDDRA_THESAURUS));
  }

  if (drugThesaurus?.length) {
    newJsonResponse.push(drugThesaurus);
    sheetNames.push(getMessage(MessageConstants.DRUG_THESAURUS));
  }

  if (targetDataModelType === TARGET_MODEL_TYPES.NON_ECRF) {
    // Remove DataSets for NON_ECRF data product
    sheetNames.splice(1, 1);
    newJsonResponseRuleset.splice(1, 1);
    // Remove Column Settings for NON_ECRF data product
    sheetNames.splice(8, 1);
    newJsonResponseRuleset.splice(8, 1);
  }

  jsonToExcelPublishDownload(
    NonRulesetType ? newJsonResponse : newJsonResponseRuleset,
    `${name}.xlsx`,
    sheetNames
  );
};

export default {
  getDomainLists,
  getMasteringSettings
};
