import React from 'react';
import Typography from 'apollo-react/components/Typography';
import Table, { compareNumbers, createStringSearchFilter } from 'apollo-react/components/Table';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import { compareStrings } from './utilites';
import { IntegerFilter, TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import { numberSearchFilter } from 'Pages/Dashboard/DataProductSummary/Components/columns';

const EmptyTableTypographyStyleTitle = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959'
};

const EmptyTableComponent = () => {
  return (
    <Typography variant="title2" style={EmptyTableTypographyStyleTitle}>
      No Data found
    </Typography>
  );
};

const columns = [
  {
    header: 'Domain Name',
    accessor: 'datasetName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('datasetName')
  },
  {
    header: 'Variable Name',
    accessor: 'datasetColumn',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('datasetColumn')
  },
  {
    header: 'Primary Key',
    accessor: 'isPrimaryKey',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('isPrimaryKey')
  },
  {
    header: 'Required',
    accessor: 'isRequiredField',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('isRequiredField')
  },
  {
    header: 'Data Type',
    accessor: 'dataType',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('dataType')
  },
  {
    header: 'Format',
    accessor: 'format',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('format'),
    filterComponent: TextFieldFilter,
    fixedWidth: false
  },
  {
    header: 'Min Length',
    accessor: 'minLength',
    sortFunction: compareNumbers,
    filterFunction: numberSearchFilter('minLength'),
    filterComponent: IntegerFilter,
    fixedWidth: false
  },
  {
    header: 'Max Length',
    accessor: 'maxLength',
    sortFunction: compareNumbers,
    filterFunction: numberSearchFilter('maxLength'),
    filterComponent: IntegerFilter,
    fixedWidth: false
  }
];

const ColumnSettingsTab = (props) => {
  const { columnSettingsData } = props;
  return (
    <Table
      title={'Mapping Spec'}
      subtitle={'Column Settings'}
      columns={columns}
      rows={columnSettingsData}
      rowsPerPageOptions={[10, 50, 100, 'All']}
      hasScroll
      maxHeight={650}
      rowProps={{ hover: false }}
      initialSortOrder="asc"
      tablePaginationProps={{
        labelDisplayedRows: DisplayedRowsLabel,
        truncate: true
      }}
      emptyProps={{
        text: <EmptyTableComponent />
      }}
    />
  );
};

export default ColumnSettingsTab;
