import React, { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import Typography from 'apollo-react/components/Typography';
import {
  SDTM_DATA_PRODUCT,
  SDTM_GLOBAL_LIBRARY_NAME,
  SDTM_PRODUCT_MNEMONIC
} from 'Utils/Constants';
import Grid from 'apollo-react/components/Grid';
import Box from 'apollo-react/components/Box';
import TextField from 'apollo-react/components/TextField';
import DetailsStrip from './components/common/DetailsStrip';
import TargetDataTable from './components/common/TargetDataTable';
import {
  resetSDTMState,
  setNextEnabled,
  setSDTMDataProduct
} from 'Redux/Slice/SDHDataProductSlice';
import { GetViewGlobalLibraries } from 'Redux/Service/GlobalLibraryService';
import { unwrapResult } from '@reduxjs/toolkit';
import { dateFormatByType } from 'Utils';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { GetReservedLibraryInfo } from 'Redux/Service/DataProductsService';
import useGlobalMessage from 'Utils/useGlobalMessage';
import { MessageConstants } from 'Utils/MessageConstants';

const SDTM = () => {
  const { getMessage } = useGlobalMessage();
  const sdhDataProduct = useSelector((state) => state.SDHDataProduct);
  const {
    workFlowModel = [],
    sdhDataProductConfig = {},
    SDTMDataProduct = {},
    nonEcrrfDataProduct = {}
  } = sdhDataProduct || {};
  const {
    sdtmConfig = false,
    targetList = [],
    description = '',
    libraryName = '',
    productMnemonic = ''
  } = SDTMDataProduct || {};
  const { nonEcrfConfig = false } = nonEcrrfDataProduct || {};
  const isSDTMWorkFlowRequired = sdhDataProductConfig?.[SDTM_DATA_PRODUCT];

  const dispatch = useDispatch();

  const loadReservedLibraryInfo = async () => {
    try {
      const getReservedLibraryData = await dispatch(GetReservedLibraryInfo()).then(unwrapResult);
      const { data } = getReservedLibraryData;
      if (data?.success) {
        const { productAndLibrary } = data;

        const libraryName = productAndLibrary?.find(
          (item) => item.configName === SDTM_GLOBAL_LIBRARY_NAME
        )?.configValue;
        const productMnemonic = productAndLibrary?.find(
          (item) => item.configName === SDTM_PRODUCT_MNEMONIC
        )?.configValue;
        dispatch(setSDTMDataProduct({ libraryName, productMnemonic }));
      } else {
        dispatch(showBanner({ variant: 'error', message: data?.message }));
      }
    } catch (error) {
      dispatch(
        showBanner({
          variant: 'error',
          message: 'Something went wrong in GetReservedLibraryInfo Method'
        })
      );
    }
  };

  useEffect(() => {
    loadReservedLibraryInfo();
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Product Designer',
          path: '/product-designer'
        },
        {
          title: 'SDTM Data Product',
          path: `/product-designer/${workFlowModel[1]?.moduleName}`
        }
      ])
    );
  }, []);

  const loadTargetDataSet = async () => {
    try {
      const targetSelectData = await dispatch(
        GetViewGlobalLibraries({ IsActive: true, exceptRuleset: false, GlobalLibType: 'RDS SDTM' })
      ).then(unwrapResult);

      const { data } = targetSelectData;
      if (data.success) {
        const { libraries } = data;

        const updatedLibraries =
          libraries?.length > 0
            ? libraries.map((lib) => {
                return {
                  ...lib,
                  createdDate: dateFormatByType(lib.createdDate, 'Table')
                };
              })
            : [];

        dispatch(setSDTMDataProduct({ targetList: updatedLibraries }));
      } else {
        dispatch(showBanner({ variant: 'error', message: data.message }));
      }
    } catch (error) {
      dispatch(
        showBanner({ variant: 'error', message: 'Something went wrong in GetLibraries Method' })
      );
    }
  };

  useEffect(async () => {
    if (isSDTMWorkFlowRequired) {
      loadTargetDataSet();
      dispatch(setSDTMDataProduct({ sdtmConfig: true }));
    }

    if (
      (sdtmConfig || nonEcrfConfig) &&
      libraryName &&
      productMnemonic &&
      (targetList?.length === 0 || targetList?.length > 0)
    ) {
      dispatch(setNextEnabled(true));
    } else {
      dispatch(setNextEnabled(false));
    }
  }, [isSDTMWorkFlowRequired, sdtmConfig, nonEcrfConfig, libraryName, productMnemonic, targetList]);

  const configureDPToggleHandler = (e) => {
    const isConfigEnabled = e.target.checked;
    if (isConfigEnabled) {
      loadTargetDataSet();
      dispatch(setSDTMDataProduct({ sdtmConfig: isConfigEnabled, description: description }));
    } else {
      dispatch(resetSDTMState());
    }
  };

  return (
    <>
      <Grid px={3}>
        <Typography variant="h3">{getMessage(MessageConstants.ADD_SDTM_DATA_PRODUCT)}</Typography>
        <DetailsStrip
          dpConfig={sdtmConfig}
          configureDPToggleHandler={configureDPToggleHandler}
          dataProductName={SDTM_DATA_PRODUCT}
        />
        {sdtmConfig && (
          <>
            <Box mt={2} mb={2}>
              <Grid item xs={5}>
                <TextField
                  id="description"
                  label="Description (Optional)"
                  name="description"
                  multiline
                  placeholder="Some Description"
                  fullWidth
                  value={description}
                  onChange={(e) => dispatch(setSDTMDataProduct({ description: e.target.value }))}
                />
              </Grid>
            </Box>
            <TargetDataTable targetLibraryDataTable={targetList} targetRowSelected={true} />
          </>
        )}
      </Grid>
    </>
  );
};

const SDTMDataProducts = forwardRef(SDTM);
export default SDTMDataProducts;
