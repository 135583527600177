/* eslint-disable */
import { makeStyles } from '@mui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import Download from 'apollo-react-icons/Download';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import Loader from 'Components/Loader/Loader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  GetValidateMappingSpec,
  GetValidateMappingSpecForClone
} from 'Redux/Service/CreateMappingSpecService';
// import { closeLoader, showLoader } from 'Redux/Slice/LoaderSlice';
import { dateFormatByType, jsonToExcelPublishDownload } from 'Utils';
import DomainRules from './DomainRules';
import EventsNotMapped from './EventsNotMapped';
import ItemsNotMapped from './ItemsNotMapped';
import MasteringRules from './MasteringRules';
import Miscellaneous from './Miscellaneous';
import ReferenceData from './ReferenceData';
import PostSQL from './PostSQL';
import SupplQual from './SupplQual';
import { renderLabel } from '../../../../../Publish/Components/TabLabelUtils/tabLabels';
import Cookies from 'js-cookie';
import { getValidateTabs, getValidateTabsClonePayload } from './ValidateTabsPayload';
import { getRowDataForRuleEditor } from 'Redux/Slice/DataProductLibrarySlice';
import useDisplayName from 'Utils/useDisplayName';
import VLCRulesTab from './VLCRulesTab';
import { isRulesetType, TARGET_MODEL_TYPES } from 'Constants/TargetModelTypes';
import ColumnSettingsTab from './ColumnSettingsTab';
import useGlobalMessage from 'Utils/useGlobalMessage';
import { NO, YES } from 'Utils/Constants';

const useStyles = makeStyles({
  tabComponent: {
    '& td': {
      whiteSpace: 'pre-line'
    }
  }
});

const TabComponent = (props) => {
  const [domainErrorList, setDomainErrorList] = useState([]);
  const [postSQLData, setPostSQLData] = useState([]);
  const [columnSettingsRowData, setColumnSettingsRowData] = useState([]);
  const [vlcRowData, setVlcRowData] = useState([]);
  const [suppQualErrorList, setSuppQualErrorList] = useState([]);
  const [referenceErrorList, setReferenceErrorList] = useState([]);
  const [unKnownErrorList, setUnKnownErrorList] = useState([]);
  const [masteringErrorList, setMasteringErrorList] = useState([]);
  const [itemsNotMapped, setItemsNotMapped] = useState([]);
  const [eventsNotMapped, setEventsNotMapped] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const { id: mappingRuleVersionID } = useParams();
  // const { mappingRuleVersionID } = useSelector((state) => state.RuleEditorData);
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const classes = useStyles();
  const navigate = useNavigate();
  const getDisplayName = useDisplayName();
  const { getMessage } = useGlobalMessage();

  const {
    productNnemonic,
    targetDataModelName,
    targetDataModelVersion,
    protocolName,
    targetDataModelType
  } = useSelector((state) => {
    return state.DataProductStudyLibrary.rowDataForRuleEditor;
  });

  const { sponsor, projectCode } = useSelector((state) => {
    return state.StudyLibraryData.protocol;
  });
  const userLoginID = Cookies.get('user.id');
  const location = useLocation();
  let path = location.pathname;
  const ITEMS_NOT_MAPPED = 'Items Not Mapped';
  const EVENTS_NOT_MAPPED = 'Events Not Mapped';

  useEffect(() => {
    if (props.cloned) {
      dispatch(getRowDataForRuleEditor(rowDataForRuleEditor.targetDataModelType === ''));
    }
  }, [props.cloned]);

  const title = [
    { title: 'Data Alignment Specification' },
    { title: 'for' },
    { title: `Protocol Number: ${protocolName}` },
    { title: `Project Code: ${projectCode}` },
    { title: `Sponsor: ${sponsor}` },
    { title: `Product Mnemonic: ${productNnemonic}` },
    { title: `Target Data Model: ${targetDataModelName}` },
    { title: `Target Data Model Version: ${targetDataModelVersion}` },
    { title: `Events Not Mapped Count: ${eventsNotMapped?.length}` }
  ];
  isRulesetType(targetDataModelType) && title.splice(-3);
  // const { loading } = useSelector((state) => state.LoaderData);
  const [errorMsg, setErrorMsg] = useState(false);
  const dispatch = useDispatch();

  const handleChangeTab = (event, value) => {
    setValue(value);
  };

  const refObj = {
    QTP: 'TP',
    QTV: 'TV',
    QTE: 'TE',
    QTI: 'TI',
    QTA: 'TA',
    CODELIST: 'CODELIST'
  };

  const downloadValidationData = () => {
    const mdsDomainData = domainErrorList?.map((item) => {
      const mdsDataObj = {
        'Domain Name': item.domainName,
        'Variable Name': item.variableName,
        'Is Error': item.isErrorText,
        'Error Description': item.message
      };

      if (targetDataModelType == TARGET_MODEL_TYPES.RULESET) {
        mdsDataObj['Ruleset Name'] = item.rulesetName;
        mdsDataObj['Ruleset Version'] = item.rulesetVersion;
        mdsDataObj['Category'] = item.category;
      }
      return mdsDataObj;
    });
    const domainData = domainErrorList?.map((item) => {
      return {
        'Domain Name': item.domainName,
        'Variable Name': item.variableName,
        'Filter Name': item.rowName,
        'Is Error': item.isErrorText,
        'Error Description': item.message
      };
    });
    const postSQLQueryData = postSQLData?.map((item) => {
      return {
        'Query Name': item.queryName,
        'Domain Name': item.domainCode
      };
    });
    const vlcRulesData = vlcRowData?.map((item) => {
      return { 'Rule Name': item.ruleName, Dataset: item.dataset };
    });
    const columnSettingsData = columnSettingsRowData?.map((item) => {
      return {
        'Domain Name': item.datasetName,
        'Variable Name': item.datasetColumn,
        'Primary Key': item.isPrimaryKey,
        Required: item.isRequiredField,
        'Data Type': item.dataType,
        Format: item.format,
        'Min Length': item.minLength,
        'Max Length': item.maxLength
      };
    });
    const suppQualData = suppQualErrorList?.map((item) => {
      return {
        'Domain Name': item.domainName,
        'Variable Name': item.variableName,
        'Is Error': item.isErrorText,
        'Error Description': item.message
      };
    });
    const masteringData = masteringErrorList?.map((item) => {
      return {
        Domain: item.domainName,
        Variable: item.variableName,
        'Row Name': item.rowName,
        'Is Error': item.isErrorText,
        'Error Description': item.message
      };
    });
    const referanceData = referenceErrorList?.map((item) => {
      return {
        'Reference Trial Name': item.domainName,
        'Is Error': item.isErrorText,
        'Error Description': item.message
      };
    });
    const unKnownData = unKnownErrorList?.map((item) => {
      return {
        'Is Error': item.isErrorText,
        'Error Description': item.message
      };
    });
    const result = [
      title,
      domainData,
      postSQLQueryData,
      dataSources,
      suppQualData,
      referanceData,
      masteringData,
      unKnownData,
      itemsNotMapped,
      eventsNotMapped
    ];
    const sheetName = [
      'Title',
      'Domain',
      'Additional Domain Rules',
      'Data Sources',
      'SupplementalQualifiers',
      'ReferenceData',
      'MasteringRules',
      'MiscellaneousErrors',
      ITEMS_NOT_MAPPED,
      EVENTS_NOT_MAPPED
    ];

    if (targetDataModelType === TARGET_MODEL_TYPES.RULESET) {
      jsonToExcelPublishDownload(
        [title, referanceData, mdsDomainData, postSQLQueryData, columnSettingsData, vlcRulesData],
        `${protocolName}-${productNnemonic}-Validation Rules.xlsx`,
        [
          'Title',
          'ReferenceData',
          'Domain',
          'Additional Domain Rules',
          'Column Settings',
          'VLC Rules'
        ]
      );
    } else if (targetDataModelType === TARGET_MODEL_TYPES.NON_ECRF) {
      jsonToExcelPublishDownload(
        [title, referanceData, mdsDomainData, postSQLQueryData, vlcRulesData],
        `${protocolName}-${productNnemonic}-Validation Rules.xlsx`,
        ['Title', 'ReferenceData', 'Domain', 'Additional Domain Rules', 'VLC Rules']
      );
    } else {
      jsonToExcelPublishDownload(
        result,
        `${protocolName}-${productNnemonic}-Validation Rules.xlsx`,
        sheetName
      );
    }
  };

  const getAllTabsData = async () => {
    setErrorMsg(false);
    // dispatch(showLoader());
    let payload = props.cloned
      ? getValidateTabsClonePayload(location.state, protocol, userLoginID)
      : getValidateTabs(mappingRuleVersionID, protocol, rowDataForRuleEditor, userLoginID);
    let validateData = props.cloned
      ? await dispatch(GetValidateMappingSpecForClone(payload)).then(unwrapResult)
      : await dispatch(GetValidateMappingSpec(payload)).then(unwrapResult);
    // dispatch(showLoader());
    if (validateData.data.success) {
      let data = validateData.data;
      // data !== undefined ? dispatch(closeLoader()) : dispatch(showLoader());
      let domainErrorList = [];
      let suppQualErrorList = [];
      let referenceErrorList = [];
      let unKnownErrorList = [];
      let masteringList = [];
      let domainProcessList = [];
      let suppQualProcessList = [];
      let refDataProcessList = [];
      let masteringProcessList = [];
      let unknownProcessList = [];

      if (data && data.dataProduct && Object.keys(data.dataProduct)) {
        const { mappingRuleVersion, protocolNumber } = data.dataProduct;
        const newData = {
          protocolNumber: protocolNumber,
          mappingRuleVersionId: mappingRuleVersion
        };
        navigate('.', {
          state: { ...location.state, ...newData }
        });
      }

      if (data.unMappedOdmItems?.length) {
        const formatedData = data.unMappedOdmItems.map((item) => {
          return {
            ...item,
            usedInFilterCondition: item.usedInFilterCondition ? 'True' : 'False',
            createdDate: dateFormatByType(item.createdDate, 'Table')
          };
        });
        setItemsNotMapped(formatedData);
      }

      if (data.unMappedSourceEvents?.length) {
        setEventsNotMapped(
          data.unMappedSourceEvents.map((item) => ({
            ...item,
            createdDate: dateFormatByType(item.createdDate, 'Table')
          }))
        );
      }

      if (data.postSqlQueryData?.length) {
        setPostSQLData(data.postSqlQueryData.sort((a, b) => a.executionOrder - b.executionOrder));
      }

      if (data.vlcRuleData?.length) {
        setVlcRowData(data.vlcRuleData);
      }

      if (data.vlcColumnSettings?.length) {
        setColumnSettingsRowData(
          data.vlcColumnSettings?.map((colSetting) => ({
            ...colSetting,
            dataType: getMessage(colSetting?.dataType),
            isPrimaryKey: colSetting?.isPrimaryKey ? YES : NO,
            isRequiredField: colSetting?.isRequiredField ? YES : NO
          }))
        );
      }

      if (data.dataSources?.length) {
        setDataSources(
          data.dataSources.map((item) => ({
            ...item,
            dataSourceName: getDisplayName(item.dataSourceName),
            libraryType: getDisplayName(item.libraryType),
            createdDate: dateFormatByType(item.createdDate, 'Table')
          }))
        );
      }

      if (data.errorListsDict.DomainRulesErrorList.length) {
        data.errorListsDict.DomainRulesErrorList.forEach((domainData, index) => {
          if (domainData.logType !== 'Warning') {
            domainErrorList.push({
              domainName: domainData.domainName !== undefined ? domainData.domainName : '',
              variableName: domainData.variableName,
              message: domainData.message,
              errorCode: domainData.errorCode,
              rowName: domainData.rowName,
              uniqueKey: Date.now() + index,
              logType: props.cloned ? 'Warning' : domainData.logType,
              isError: props.cloned ? undefined : !!domainData.errorCode,
              isErrorText: props.cloned ? 'No' : 'Yes',
              isSuppqual: domainData.isSuppqual,
              rulesetName:
                domainData.rulesetName !== undefined &&
                domainData.rulesetName !== null &&
                domainData.rulesetName !== ''
                  ? domainData.rulesetName
                  : 'N/A',
              rulesetVersion:
                domainData.version !== undefined &&
                domainData.version !== null &&
                domainData.version !== ''
                  ? domainData.version
                  : 'N/A',
              category:
                domainData.category !== undefined &&
                domainData.category !== null &&
                domainData.category !== ''
                  ? domainData.category
                  : 'N/A'
            });
          } else {
            domainProcessList.push({
              domainName: domainData.domainName !== undefined ? domainData.domainName : '',
              variableName: domainData.variableName,
              message: domainData.message,
              errorCode: domainData.errorCode,
              rowName: domainData.rowName,
              uniqueKey: domainData.variableRuleId,
              logType: domainData.logType,
              isErrorText: 'No',
              isSuppqual: domainData.isSuppqual,
              rulesetName:
                domainData.rulesetName !== undefined &&
                domainData.rulesetName !== null &&
                domainData.rulesetName !== ''
                  ? domainData.rulesetName
                  : 'N/A',
              rulesetVersion:
                domainData.version !== undefined &&
                domainData.version !== null &&
                domainData.version !== ''
                  ? domainData.version
                  : 'N/A',
              category:
                domainData.category !== undefined &&
                domainData.category !== null &&
                domainData.category !== ''
                  ? domainData.category
                  : 'N/A'
            });
          }
        });
      }

      if (data.errorListsDict.SuppQualsErrorList.length) {
        data.errorListsDict.SuppQualsErrorList.forEach((suppData) => {
          if (suppData.logType !== 'Warning') {
            suppQualErrorList.push({
              domainName: suppData.domainName !== undefined ? suppData.domainName : '',
              variableName: suppData.variableName,
              message: suppData.message,
              errorCode: suppData.errorCode,
              rowName: suppData.rowName,
              uniqueKey: suppData.variableRuleId,
              logType: props.cloned ? 'Warning' : suppData.logType,
              isError: props.cloned ? undefined : !!suppData.errorCode,
              isErrorText: props.cloned ? 'No' : 'Yes'
            });
          } else {
            suppQualProcessList.push({
              domainName: suppData.domainName !== undefined ? suppData.domainName : '',
              variableName: suppData.variableName,
              message: suppData.message,
              errorCode: suppData.errorCode,
              rowName: suppData.rowName,
              uniqueKey: suppData.variableRuleId,
              logType: suppData.logType,
              isErrorText: 'No'
            });
          }
        });
      }

      if (data.errorListsDict.ReferenceDataErrorList.length) {
        data.errorListsDict.ReferenceDataErrorList.forEach((refData) => {
          if (refData.logType !== 'Warning') {
            referenceErrorList.push({
              domainName: refData.domainName !== undefined ? refData.domainName : '',
              variableName: refData.variableName,
              message: refData.message,
              errorCode: refData.errorCode,
              rowName: refData.rowName,
              uniqueKey: refData.variableRuleId,
              logType: props.cloned ? 'Warning' : refData.logType,
              isError: props.cloned ? undefined : !!refData.errorCode,
              isErrorText: props.cloned ? 'No' : 'Yes'
            });
          } else {
            refDataProcessList.push({
              domainName: refData.domainName !== undefined ? refData.domainName : '',
              variableName: refData.variableName,
              message: refData.message,
              errorCode: refData.errorCode,
              rowName: refData.rowName,
              uniqueKey: refData.variableRuleId,
              logType: refData.logType,
              isErrorText: 'No'
            });
          }
        });
      }

      if (data.errorListsDict.UnKnownErrorList.length) {
        data.errorListsDict.UnKnownErrorList.forEach((unknownData) => {
          if (unknownData.logType !== 'Warning') {
            unKnownErrorList.push({
              domainName: unknownData.domainName !== undefined ? unknownData.domainName : '',
              variableName: unknownData.variableName,
              message: unknownData.message,
              errorCode: unknownData.errorCode,
              rowName: unknownData.rowName,
              uniqueKey: unknownData.variableRuleId,
              logType: props.cloned ? 'Warning' : unknownData.logType,
              isError: props.cloned ? undefined : !!unknownData.errorCode,
              isErrorText: props.cloned ? 'No' : 'Yes'
            });
          } else {
            unknownProcessList.push({
              domainName: unknownData.domainName !== undefined ? unknownData.domainName : '',
              variableName: unknownData.variableName,
              message: unknownData.message,
              errorCode: unknownData.errorCode,
              rowName: unknownData.rowName,
              uniqueKey: unknownData.variableRuleId,
              logType: unknownData.logType,
              isErrorText: 'No'
            });
          }
        });
      }

      if (data.errorListsDict.MasteringRulesErrorList.length) {
        data.errorListsDict.MasteringRulesErrorList.forEach((mastData) => {
          let _rowName = mastData.rowName.split('_');
          _rowName[0] =
            mastData.isCustomSQLLibrary === true
              ? getDisplayName(mastData.displayName)
              : getDisplayName(_rowName[0]);
          if (mastData.logType !== 'Warning') {
            masteringList.push({
              domainName: mastData.domainName !== undefined ? mastData.domainName : '',
              variableName: mastData.variableName,
              message: mastData.message,
              errorCode: mastData.errorCode,
              rowName: _rowName.join('_'),
              uniqueKey: mastData.variableRuleId,
              logType: props.cloned ? 'Warning' : mastData.logType,
              isError: props.cloned ? undefined : !!mastData.errorCode,
              isErrorText: props.cloned ? 'No' : 'Yes'
            });
          } else {
            masteringProcessList.push({
              domainName: mastData.domainName !== undefined ? mastData.domainName : '',
              variableName: mastData.variableName,
              message: mastData.message,
              errorCode: mastData.errorCode,
              rowName: _rowName.join('_'),
              uniqueKey: mastData.variableRuleId,
              logType: mastData.logType,
              isErrorText: 'No'
            });
          }
        });
      }

      if (
        (domainErrorList.length ||
          suppQualErrorList.length ||
          referenceErrorList.length ||
          unKnownErrorList.length ||
          masteringList.length) === 0 ||
        props.cloned
      ) {
        props.disableMappingButton(false);
      } else {
        props.disableMappingButton(true);
      }

      data.processedVariables.DomainRulesProcessedList &&
        data.processedVariables.DomainRulesProcessedList.forEach((domainData) => {
          domainProcessList.push({
            domainName: domainData.domainCode,
            errorCode: undefined,
            variableName: domainData.variableName,
            rowName: domainData.rowName,
            uniqueKey: domainData.variableRuleId,
            isErrorText: 'No',
            isSuppqual: domainData.isSuppqual,
            rulesetName:
              domainData.rulesetName !== undefined &&
              domainData.rulesetName !== null &&
              domainData.rulesetName !== ''
                ? domainData.rulesetName
                : 'N/A',
            rulesetVersion:
              domainData.version !== undefined &&
              domainData.version !== null &&
              domainData.version !== ''
                ? domainData.version
                : 'N/A',
            category:
              domainData.category !== undefined &&
              domainData.category !== null &&
              domainData.category !== ''
                ? domainData.category
                : 'N/A'
          });
        });

      data.processedVariables.SuppQualsProcessedList &&
        data.processedVariables.SuppQualsProcessedList.forEach((suppData) => {
          suppQualProcessList.push({
            domainName: suppData.domainCode,
            errorCode: undefined,
            variableName: suppData.variableName,
            uniqueKey: suppData.variableRuleId,
            isErrorText: 'No'
          });
        });

      data.processedVariables.ReferenceDataProcessedList &&
        data.processedVariables.ReferenceDataProcessedList.forEach((refData) => {
          refDataProcessList.push({
            domainName: refData.domainCode !== undefined ? refObj[refData.domainCode] : '',
            errorCode: undefined,
            variableName: refData.variableName ? refData.variableName : '',
            uniqueKey: refData.variableRuleId,
            isErrorText: 'No'
          });
        });

      data.processedVariables.MasteringRulesProcessedList &&
        data.processedVariables.MasteringRulesProcessedList.forEach((mastData) => {
          let _rowName = mastData.rowName.split('_');
          _rowName[0] =
            mastData.isCustomSQLLibrary === true
              ? getDisplayName(mastData.displayName)
              : getDisplayName(_rowName[0]);
          masteringProcessList.push({
            domainName: mastData.domainCode,
            errorCode: undefined,
            variableName: mastData.variableName,
            rowName: _rowName.join('_'),
            uniqueKey: mastData.variableRuleId,
            isErrorText: 'No'
          });
        });

      setDomainErrorList([...domainErrorList, ...domainProcessList]);
      setSuppQualErrorList([...suppQualErrorList, ...suppQualProcessList]);
      setReferenceErrorList([...referenceErrorList, ...refDataProcessList]);
      setUnKnownErrorList([...unKnownErrorList, ...unknownProcessList]);
      setMasteringErrorList([...masteringList, ...masteringProcessList]);
      // dispatch(closeLoader());
      setLoading(false);
    } else {
      // dispatch(closeLoader());
      setErrorMsg(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTabsData();
  }, []);

  const getNonRulesetTabs = (errorCount, cloneFlag) => {
    const cloneStyle = { display: cloneFlag ? 'none' : '' };
    let dynamicTabs;
    if (errorCount === 0) {
      dynamicTabs = (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label={renderLabel('domain', domainErrorList, props.cloned)} />
          <Tab label={renderLabel('additionalDomain', postSQLData, props.cloned)} />
          <Tab label={renderLabel('suppQual', suppQualErrorList, props.cloned)} />
          <Tab label={renderLabel('refData', referenceErrorList, props.cloned)} />
          <Tab label={renderLabel('mastering', masteringErrorList, props.cloned)} />
          <Tab label={ITEMS_NOT_MAPPED} style={cloneStyle} />
          <Tab label={EVENTS_NOT_MAPPED} style={cloneStyle} />
        </Tabs>
      );
    } else {
      dynamicTabs = (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label={renderLabel('domain', domainErrorList, props.cloned)} />
          <Tab label={renderLabel('additionalDomain', postSQLData, props.cloned)} />
          <Tab label={renderLabel('suppQual', suppQualErrorList, props.cloned)} />
          <Tab label={renderLabel('refData', referenceErrorList, props.cloned)} />
          <Tab label={renderLabel('mastering', masteringErrorList, props.cloned)} />
          <Tab label={renderLabel('unknown', unKnownErrorList, props.cloned)} />
          <Tab label={ITEMS_NOT_MAPPED} style={cloneStyle} />
          <Tab label={EVENTS_NOT_MAPPED} style={cloneStyle} />
        </Tabs>
      );
    }
    return dynamicTabs;
  };

  const getRulesetTabs = () => {
    if (unKnownErrorList?.length)
      return (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label={renderLabel('domain', domainErrorList)} />
          <Tab label={renderLabel('additionalDomain', postSQLData)} />
          <Tab label={renderLabel('columnSettings', columnSettingsRowData)} />
          <Tab label={renderLabel('vlcRules', vlcRowData)} />
          <Tab label={renderLabel('refData', referenceErrorList)} />
          <Tab label={renderLabel('unknown', unKnownErrorList)} />
        </Tabs>
      );
    else
      return (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label={renderLabel('domain', domainErrorList)} />
          <Tab label={renderLabel('additionalDomain', postSQLData)} />
          <Tab label={renderLabel('columnSettings', columnSettingsRowData)} />
          <Tab label={renderLabel('vlcRules', vlcRowData)} />
          <Tab label={renderLabel('refData', referenceErrorList)} />
        </Tabs>
      );
  };

  const getNonEcrfTabs = () => {
    if (unKnownErrorList?.length)
      return (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label={renderLabel('domain', domainErrorList)} />
          <Tab label={renderLabel('additionalDomain', postSQLData)} />
          <Tab label={renderLabel('vlcRules', vlcRowData)} />
          <Tab label={renderLabel('refData', referenceErrorList)} />
          <Tab label={renderLabel('unknown', unKnownErrorList)} />
        </Tabs>
      );
    else
      return (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label={renderLabel('domain', domainErrorList)} />
          <Tab label={renderLabel('additionalDomain', postSQLData)} />
          <Tab label={renderLabel('vlcRules', vlcRowData)} />
          <Tab label={renderLabel('refData', referenceErrorList)} />
        </Tabs>
      );
  };

  const getRulesetTabComponent = () => {
    if (value === 0)
      return (
        <DomainRules
          targetDataModelType={targetDataModelType}
          errorMsg={errorMsg}
          domainData={domainErrorList}
          triggerGetApi={getAllTabsData}
          title={title}
          path={path}
          cloneFlag={props.cloned}
        />
      );
    else if (value === 1) return <PostSQL postSQLRowData={postSQLData} cloneFlag={props.cloned} />;
    else if (value === 2) return <ColumnSettingsTab columnSettingsData={columnSettingsRowData} />;
    else if (value === 3) return <VLCRulesTab vlcRowData={vlcRowData} />;
    else if (value === 4)
      return (
        <ReferenceData
          errorMsg={errorMsg}
          refData={referenceErrorList}
          triggerGetApi={getAllTabsData}
          title={title}
          path={path}
          cloneFlag={props.cloned}
        />
      );
    else if (value === 5 && unKnownErrorList.length > 0)
      return (
        <Miscellaneous
          errorMsg={errorMsg}
          unknownData={unKnownErrorList}
          triggerGetApi={getAllTabsData}
          title={title}
          cloneFlag={props.cloned}
        />
      );
  };

  const getNonEcrfTabComponent = () => {
    if (value === 0)
      return (
        <DomainRules
          targetDataModelType={targetDataModelType}
          errorMsg={errorMsg}
          domainData={domainErrorList}
          triggerGetApi={getAllTabsData}
          title={title}
          path={path}
          cloneFlag={props.cloned}
        />
      );
    else if (value === 1) return <PostSQL postSQLRowData={postSQLData} cloneFlag={props.cloned} />;
    else if (value === 2) return <VLCRulesTab vlcRowData={vlcRowData} cloneFlag={props.cloned} />;
    else if (value === 3)
      return (
        <ReferenceData
          errorMsg={errorMsg}
          refData={referenceErrorList}
          triggerGetApi={getAllTabsData}
          title={title}
          path={path}
          cloneFlag={props.cloned}
        />
      );
    else if (value === 4 && unKnownErrorList.length > 0)
      return (
        <Miscellaneous
          errorMsg={errorMsg}
          unknownData={unKnownErrorList}
          triggerGetApi={getAllTabsData}
          title={title}
          cloneFlag={props.cloned}
        />
      );
  };

  const getNonRulesetTabComponent = () => {
    if (value === 0)
      return (
        <DomainRules
          targetDataModelType={targetDataModelType}
          errorMsg={errorMsg}
          domainData={domainErrorList}
          triggerGetApi={getAllTabsData}
          title={title}
          path={path}
          cloneFlag={props.cloned}
        />
      );
    else if (value === 1) return <PostSQL postSQLRowData={postSQLData} cloneFlag={props.cloned} />;
    else if (value === 2)
      return (
        <SupplQual
          errorMsg={errorMsg}
          suppData={suppQualErrorList}
          triggerGetApi={getAllTabsData}
          title={title}
          path={path}
          cloneFlag={props.cloned}
        />
      );
    else if (value === 3)
      return (
        <ReferenceData
          errorMsg={errorMsg}
          refData={referenceErrorList}
          triggerGetApi={getAllTabsData}
          title={title}
          path={path}
          cloneFlag={props.cloned}
        />
      );
    else if (value === 4)
      return (
        <MasteringRules
          errorMsg={errorMsg}
          masteringData={masteringErrorList}
          triggerGetApi={getAllTabsData}
          title={title}
          path={path}
          cloneFlag={props.cloned}
        />
      );
    else if (value === 5 && unKnownErrorList.length > 0)
      return (
        <Miscellaneous
          errorMsg={errorMsg}
          unknownData={unKnownErrorList}
          triggerGetApi={getAllTabsData}
          title={title}
          cloneFlag={props.cloned}
        />
      );
    else if (unKnownErrorList.length ? value === 6 : value === 5)
      return <ItemsNotMapped itemsNotMappedData={itemsNotMapped} triggerGetApi={getAllTabsData} />;
    else if (unKnownErrorList.length ? value === 7 : value === 6)
      return (
        <EventsNotMapped eventsNotMappedData={eventsNotMapped} triggerGetApi={getAllTabsData} />
      );
  };

  const renderTabs = (errorCount, cloneFlag) => {
    if (targetDataModelType === TARGET_MODEL_TYPES.RULESET) {
      return getRulesetTabs();
    } else if (targetDataModelType === TARGET_MODEL_TYPES.NON_ECRF) {
      return getNonEcrfTabs();
    } else {
      return getNonRulesetTabs(errorCount, cloneFlag);
    }
  };

  const renderTabComponent = () => {
    if (targetDataModelType === TARGET_MODEL_TYPES.RULESET) {
      return getRulesetTabComponent();
    } else if (targetDataModelType === TARGET_MODEL_TYPES.NON_ECRF) {
      return getNonEcrfTabComponent();
    } else {
      return getNonRulesetTabComponent();
    }
  };

  return (
    <>
      {loading ? (
        <DataVizCard style={{ marginTop: props.cloned ? '16px' : 0, width: '100%' }}>
          <Loader />
        </DataVizCard>
      ) : (
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: props.cloned ? '16px' : 0
            }}>
            {!props.cloned && (
              <Button
                size="small"
                data-testid="download-btn"
                onClick={downloadValidationData}
                icon={<Download />}
                style={{ color: '#0768fd' }}>
                Download
              </Button>
            )}
          </div>
          {renderTabs(unKnownErrorList.length, props.cloned)}
          <Card
            interactive
            style={{
              marginTop: '10px',
              position: 'relative'
            }}
            className={classes.tabComponent}>
            {renderTabComponent()}
          </Card>
        </div>
      )}
    </>
  );
};
export default TabComponent;
