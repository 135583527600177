import Accordion from 'apollo-react/components/Accordion';
import AccordionDetails from 'apollo-react/components/AccordionDetails';
import AccordionSummary from 'apollo-react/components/AccordionSummary';
import Box from 'apollo-react/components/Box';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import useDisplayName from 'Utils/useDisplayName';
import { useStylesPreviewCreate } from '../SDHPreviewCreate';
import { LIBRARY_TYPES } from 'Constants/LibraryTypes';

function PreviewNonEcrf() {
  const classes = useStylesPreviewCreate();
  const sdhDataProduct = useSelector((state) => state.SDHDataProduct);
  const { nonEcrrfDataProduct = {} } = sdhDataProduct || {};
  const {
    selectedTrialName = '',
    productMnemonic = '',
    libraryName = '',
    targetLibrary = {},
    nonEcrfDatasets = [],
  } = nonEcrrfDataProduct;

  const getDisplayName = useDisplayName();

  const datasetNames =
    nonEcrfDatasets?.length > 0 ? nonEcrfDatasets?.map((item) => item.datasetName).join(', ') : '';

  return (
    <Accordion defaultExpanded className={classes.libraryType}>
      <AccordionSummary>
        <Box className={classes.libraryTypeHeader}>
          <Typography className={`${classes.libraryTypeTitle}`}>Non-eCRF Data Product</Typography>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>{}</Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.expressionContainer}>
        <Grid container spacing={0} mt={1} gap={2}>
          <Grid item xs={2}>
            <Typography variant="body2" className={classes.label}>
              Product Mnemonic:
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {productMnemonic}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" className={classes.label}>
              Library Name:
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {libraryName}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="body2" className={classes.label}>
              Data Source:
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {getDisplayName(LIBRARY_TYPES.CDISC_ODM)}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="body2" className={classes.label}>
              Trial Name/Environment Name:
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {selectedTrialName?.edcTrialName}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="body2" className={classes.label}>
              Datasets:
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {datasetNames}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              Target Library:
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {targetLibrary.libraryName}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default PreviewNonEcrf;
