export const MessageConstants = {
  // DataExport - Domain & Columns
  EXPORT_OVERWRITE_DATA_FILENAME_LABEL: 'EXPORT_OVERWRITE_DATA_FILENAME_LABEL',
  EXPORT_OVERWRITE_DATA_FILENAME_TOOLTIP: 'EXPORT_OVERWRITE_DATA_FILENAME_TOOLTIP',
  EXPORT_OVERWRITE_DATA_FILENAME_WARNING_TITLE: 'EXPORT_OVERWRITE_DATA_FILENAME_WARNING_TITLE',
  EXPORT_OVERWRITE_DATA_FILENAME_WARNING_DESC: 'EXPORT_OVERWRITE_DATA_FILENAME_WARNING_DESC',
  EXPORT_OVERWRITE_DATA_FILENAME_WARNING_OK: 'EXPORT_OVERWRITE_DATA_FILENAME_WARNING_OK',
  EXPORT_OVERWRITE_DATA_FILENAME_WARNING_CANCEL: 'EXPORT_OVERWRITE_DATA_FILENAME_WARNING_CANCEL',
  EXPORT_DOMAIN_DATA_FILENAME_COLUMN_LABEL: 'EXPORT_DOMAIN_DATA_FILENAME_COLUMN_LABEL',

  // DataExport - SuppQual Dropdown values
  EXPORT_SEPERATE_TABLE_TITLE: 'EXPORT_SEPERATE_TABLE_TITLE',
  EXPORT_SEPERATE_TABLE_DESC: 'EXPORT_SEPERATE_TABLE_DESC',
  EXPORT_PRIMARY_TABLE_TITLE: 'EXPORT_PRIMARY_TABLE_TITLE',
  EXPORT_PRIMARY_TABLE_DESC: 'EXPORT_PRIMARY_TABLE_DESC',
  EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_TITLE: 'EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_TITLE',
  EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_DESC: 'EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_DESC',
  DO_NOT_EXPORT_TITLE: 'DO_NOT_EXPORT_TITLE',
  DO_NOT_EXPORT_DESC: 'DO_NOT_EXPORT_DESC',

  // DataExport - Export Type
  EXPORT_TYPE_CUMULATIVE_TITLE: 'EXPORT_TYPE_CUMULATIVE_TITLE',
  EXPORT_TYPE_CUMULATIVE_DESC: 'EXPORT_TYPE_CUMULATIVE_DESC',
  EXPORT_TYPE_INCREMENTAL_TITLE: 'EXPORT_TYPE_INCREMENTAL_TITLE',
  EXPORT_TYPE_INCREMENTAL_DESC: 'EXPORT_TYPE_INCREMENTAL_DESC',

  // DataExport - Errors
  EXPORT_ERROR_DATA_FILENAME_EXIST: 'EXPORT_ERROR_DATA_FILENAME_EXIST',
  EXPORT_ERROR_COLUMN_ERROR_EXIST: 'EXPORT_ERROR_COLUMN_ERROR_EXIST',

  //ReferenceData - generic messages
  REFERENCE_DATA_STUDY_LIBRARY_IMPORT_LABEL: 'REFERENCE_DATA_STUDY_LIBRARY_IMPORT_LABEL',
  REFERENCE_DATA_PREVIEW_RETURN_BUTTON: 'REFERENCE_DATA_PREVIEW_RETURN_BUTTON',
  REFERENCE_DATA_PREVIEW_RETURN_WARNING_TITLE: 'REFERENCE_DATA_PREVIEW_RETURN_WARNING_TITLE',
  REFERENCE_DATA_PREVIEW_RETURN_IMPORT_WARNING_DESC:
    'REFERENCE_DATA_PREVIEW_RETURN_IMPORT_WARNING_DESC',
  REFERENCE_DATA_PREVIEW_RETURN_UPLOAD_WARNING_DESC:
    'REFERENCE_DATA_PREVIEW_RETURN_UPLOAD_WARNING_DESC',
  REFERENCE_DATA_PREVIEW_RETURN_EDIT_WARNING_DESC:
    'REFERENCE_DATA_PREVIEW_RETURN_EDIT_WARNING_DESC',
  REFERENCE_DATA_DELETE_CARD_WARNING_TITLE: 'REFERENCE_DATA_DELETE_CARD_WARNING_TITLE',
  REFERENCE_DATA_DELETE_CARD_WARNING_DESC: 'REFERENCE_DATA_DELETE_CARD_WARNING_DESC',
  REFERENCE_DATA_VIEW_DATA_TABLE_LABEL: 'REFERENCE_DATA_VIEW_DATA_TABLE_LABEL',
  REFERENCE_DATA_FILE_UPLOAD_LABEL: 'REFERENCE_DATA_FILE_UPLOAD_LABEL',

  //ReferenceData - TimePoints
  REFERENCE_DATA_TIMEPOINTS_IMPORT_LABEL: 'REFERENCE_DATA_TIMEPOINTS_IMPORT_LABEL',
  REFERENCE_DATA_TIMEPOINTS_IMPORT_SYNC_TOOLTIP: 'REFERENCE_DATA_TIMEPOINTS_IMPORT_SYNC_TOOLTIP',
  REFERENCE_DATA_TIMEPOINTS_IMPORT_WARNING_TITLE: 'REFERENCE_DATA_TIMEPOINTS_IMPORT_WARNING_TITLE',
  REFERENCE_DATA_TIMEPOINTS_IMPORT_WARNING_DESC: 'REFERENCE_DATA_TIMEPOINTS_IMPORT_WARNING_DESC',
  REFERENCE_DATA_TIMEPOINTS_IMPORT_ERROR_NO_DATA: 'REFERENCE_DATA_TIMEPOINTS_IMPORT_ERROR_NO_DATA',

  //ReferenceData - TrialVisits
  REFERENCE_DATA_TRIALVISITS_IMPORT_LABEL: 'REFERENCE_DATA_TRIALVISITS_IMPORT_LABEL',
  REFERENCE_DATA_TRIALVISITS_IMPORT_SYNC_TOOLTIP: 'REFERENCE_DATA_TRIALVISITS_IMPORT_SYNC_TOOLTIP',
  REFERENCE_DATA_TRIALVISITS_IMPORT_WARNING_TITLE:
    'REFERENCE_DATA_TRIALVISITS_IMPORT_WARNING_TITLE',

  //ReferenceData - MedDRA
  REFERENCE_DATA_MEDDRA_IMPORT_LABEL: 'REFERENCE_DATA_MEDDRA_IMPORT_LABEL',
  REFERENCE_DATA_MEDDRA_IMPORT_SYNC_TOOLTIP: 'REFERENCE_DATA_MEDDRA_IMPORT_SYNC_TOOLTIP',
  REFERENCE_DATA_MEDDRA_IMPORT_WARNING_TITLE: 'REFERENCE_DATA_MEDDRA_IMPORT_WARNING_TITLE',
  REFERENCE_DATA_MEDDRA_IMPORT_WARNING_DESC: 'REFERENCE_DATA_MEDDRA_IMPORT_WARNING_DESC',

  //ReferenceData - Drug Thesaurus
  REFERENCE_DATA_DT_IMPORT_LABEL: 'REFERENCE_DATA_DT_IMPORT_LABEL',
  REFERENCE_DATA_DT_IMPORT_SYNC_TOOLTIP: 'REFERENCE_DATA_DT_IMPORT_SYNC_TOOLTIP',
  REFERENCE_DATA_DT_IMPORT_WARNING_TITLE: 'REFERENCE_DATA_DT_IMPORT_WARNING_TITLE',
  REFERENCE_DATA_DT_IMPORT_WARNING_DESC: 'REFERENCE_DATA_DT_IMPORT_WARNING_DESC',

  //Generic messages
  WARNING_LABEL_YES_PROCEED: 'WARNING_LABEL_YES_PROCEED',
  WARNING_LABLE_CANCEL: 'WARNING_LABLE_CANCEL',
  WARNING_LABEL_OK: 'WARNING_LABEL_OK',

  // Data Export Package Format
  FOLDER_PATH: 'FOLDER_PATH',
  OTHER_SETTINGS: 'OTHER_SETTINGS',
  UNCOMPRESSED: 'UNCOMPRESSED',
  COMPRESSED: 'COMPRESSED',
  CSV: 'CSV',
  NONE: 'NONE',
  PROTOCOL_MNEMONIC_PRODUCT_MNEMONIC: 'PROTOCOL_MNEMONIC_PRODUCT_MNEMONIC',
  AUTO_GENERATED_BATCH_ID: 'AUTO_GENERATED_BATCH_ID',
  EXPORT_DATE_AND_TIME: 'EXPORT_DATE_AND_TIME',
  EXPORT_PACKAGE_FORMAT: 'EXPORT_PACKAGE_FORMAT',
  EXPORT_FILE_FORMAT: 'EXPORT_FILE_FORMAT',
  FOLDER_NAME: 'FOLDER_NAME',
  SUB_FOLDER: 'SUB_FOLDER',
  CREATE_RECORD_COUNT_FILE: 'CREATE_RECORD_COUNT_FILE',
  AUTOMATED_FOLDER_SETUP: 'AUTOMATED_FOLDER_SETUP',

  //Mapping Spec Details
  VIEW_ALL_LABEL: 'VIEW_ALL_LABEL',
  SHOW_LATEST_CREATED_LABEL: 'SHOW_LATEST_CREATED_LABEL',

  // QCODE DOMAINS
  SQL_TEMPLATE: 'SQL Template',
  DRUG_THESAURUS: 'DRUG_THESAURUS',
  MEDDRA_THESAURUS: 'MEDDRA_THESAURUS',

  // COLUMN_SETTINGS_DATA_TYPES
  ALPHANUMERIC: 'ALPHANUMERIC',
  NUMERIC: 'NUMERIC',
  DATE: 'DATE',

  // Column Settings Messages
  VARIABLES: 'VARIABLES',
  CONTINUE_WITHOUT_SAVING: 'CONTINUE_WITHOUT_SAVING',
  COLUMN_SETTINGS_UNSAVED_CHANGES_WARNING_MESSAGE:
    'COLUMN_SETTINGS_UNSAVED_CHANGES_WARNING_MESSAGE',
  KEEP_EDITING: 'KEEP_EDITING',
  COLUMN_SETTINGS_PRE_VALIDATION_MESSAGE: 'COLUMN_SETTINGS_PRE_VALIDATION_MESSAGE',
  COLUMN_SETTINGS_NOTE_MESSAGE: 'COLUMN_SETTINGS_NOTE_MESSAGE',

  // SDH Data Products
  ADD_NON_ECRF_DATA_PRODUCT_TITLE: "ADD_NON_ECRF_DATA_PRODUCT_TITLE",
  RUN_ALL_FAILED_QUERY_MESSAGE: 'RUN_ALL_FAILED_QUERY_MESSAGE',
  ADD_SDTM_DATA_PRODUCT: "ADD_SDTM_DATA_PRODUCT",
  NON_ECRF_DATASET_TABLE_TITLE: 'NON_ECRF_DATASET_TABLE_TITLE',
  EMPTY_MESSAGE_FOR_NONECRF_DATASET_TABLE: 'EMPTY_MESSAGE_FOR_NONECRF_DATASET_TABLE',
  NON_ECRF_TARGET_TABLE_TITLE: 'NON_ECRF_TARGET_TABLE_TITLE',
  EMPTY_MESSAGE_FOR_NONECRF_TARGET_TABLE: 'EMPTY_MESSAGE_FOR_NONECRF_TARGET_TABLE',
  SDH_PREVIEW_AND_CREATE_TITLE: "SDH_PREVIEW_AND_CREATE_TITLE",
};
