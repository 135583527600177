/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { Box, Table, Typography } from 'apollo-react';
import Modal from 'apollo-react/components/Modal';
import Footer from 'Components/Footer';
import { dateFormatByType } from 'Utils';
import { VLCErrorDetails } from './VLCRules.errors';
import { columns } from './VLCRules.columns';
import { CustomButtonHeader, EmptyTableComp } from './VLCRules.tableComps';
import { VLCRuleEditor } from './VLCRules.editor';
import { DeleteVLCRules, GetVLCRules, ToggleVLCRules } from 'Redux/Service/VLCRulesService';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getDomainAndVariables } from 'Redux/Service/PostSQLEditorService';
import { validateVLCActionMessage, validateVLCRule } from './VLCRules.validator';
import { setNextEnabled } from 'Redux/Slice/RuleEditorSlice';

export const VLCRules = () => {
  const [vlcRules, setVLCRules] = useState([]);
  const [modalConfig, setModalConfig] = useState({});
  const [displayVlcRuleEditor, setDisplayVlcRuleEditor] = useState(false);
  const [existingRuleName, setExistingRuleName] = useState([]);
  const [editVLCRule, setEditVLCRule] = useState({});
  const { id: mappingRuleVersionID } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [domainAndVariablesData, setDomainAndvariablesData] = useState([]);
  const [dataSetName, setDataSetName] = useState([]);
  const [vlcRulesWithErrors, setVLCRulesWithErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getDomainAndVariablesData = async () => {
    setLoading(true);
    const response = await dispatch(getDomainAndVariables(mappingRuleVersionID)).then(unwrapResult);
    if (response && response.data && response.data.success) {
      const { domainAndVariables } = response.data;
      if (domainAndVariables?.length) {
        setDataSetName(
          domainAndVariables
            .filter((domain) => domain.domainType !== 'No Mapping')
            ?.map((data) => data.domainCode)
        );
        setDomainAndvariablesData(domainAndVariables);
      }
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data.message }));
    }
    setLoading(false);
  };

  const fetchVLCRules = async () => {
    setLoading(true);
    const response = await dispatch(GetVLCRules(mappingRuleVersionID)).then(unwrapResult);
    if (response?.data.success) {
      const { vlcRules } = response?.data;
      setVLCRules(
        vlcRules.map((_row, index) => ({
          ..._row,
          iqAuditDate: dateFormatByType(_row.iqAuditDate, 'Table')
        }))
      );
      setExistingRuleName(vlcRules?.map((name) => name.vlcRuleName));
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data.message }));
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(setNextEnabled(false));
    (async () => {
      await getDomainAndVariablesData();
      await fetchVLCRules();
    })();
  }, []);

  const handleDelete = async (vlcRuleID) => {
    const deleteResponse = await dispatch(DeleteVLCRules(vlcRuleID)).then(unwrapResult);
    if (deleteResponse?.data?.success) {
      setModalConfig({});
      await fetchVLCRules();
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data.message }));
    }
  };

  const handleToggle = async (isActive, vlcRuleID) => {
    const payload = {
      vlcRuleID: vlcRuleID,
      isActive: isActive
    };
    const response = await dispatch(ToggleVLCRules(payload)).then(unwrapResult);
    if (response?.data?.success) {
      setModalConfig({});
      await fetchVLCRules();
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data.message }));
    }
    setModalConfig({});
  };

  const onDelete = (vlcRuleName, vlcRuleID) => {
    setModalConfig({
      open: true,
      variant: 'error',
      title: 'Delete VLC Rule',
      body: 'Are you sure you want to Delete this VLC rule?\n All the Issues of deleted VLC rule would be auto closed in DRM when the Mapping Spec is published.',
      buttonProps: [
        {
          label: 'Cancel',
          onClick: () => {
            setModalConfig({});
          }
        },
        {
          label: 'Delete',
          onClick: () => {
            handleDelete(vlcRuleID);
          }
        }
      ],
      vlcRuleName: vlcRuleName
    });
  };

  const onActiveToggle = (isActive, vlcRuleName, vlcRuleID) => {
    if (!isActive) {
      setModalConfig({
        open: true,
        variant: 'warning',
        title: 'Deactivate VLC Rule',
        body: 'Are you sure you want to Deactivate this VLC rule?\nAll the Issues of deactivated VLC rule would be auto closed in DRM when the mapping spec is published.',
        buttonProps: [
          {
            label: 'Cancel',
            onClick: () => {
              setModalConfig({});
            }
          },
          {
            label: 'Deactivate',
            onClick: () => handleToggle(isActive, vlcRuleID)
          }
        ],
        vlcRuleName: vlcRuleName,
        onClose: () => {
          setModalConfig({});
        }
      });
    } else {
      handleToggle(isActive, vlcRuleID);
    }
  };

  const onEdit = (row) => {
    setDisplayVlcRuleEditor(true);
    setEditVLCRule(row);
  };

  const validateRules = () => {
    const domainAndVariablesMapping = {};
    domainAndVariablesData.forEach((domain) => {
      if (domain.domainType !== 'No Mapping') {
        domainAndVariablesMapping[domain.domainCode] = [
          domain.reservedVariables,
          domain.variableName
        ]
          .flat()
          .filter(Boolean);
      }
    });

    const _vlcRulesWithErrors = {};
    vlcRules.forEach((rule) => {
      let expressionError, messageError;
      try {
        validateVLCRule(rule.expression, domainAndVariablesMapping[rule.dataSet]);
      } catch (e) {
        expressionError = e;
      }
      try {
        validateVLCActionMessage(rule.vlcActionMessage, domainAndVariablesMapping[rule.dataSet]);
      } catch (e) {
        messageError = e;
      }
      if (expressionError || messageError) {
        _vlcRulesWithErrors[rule.vlcRuleID] = {
          expressionError,
          messageError
        };
      }
    });
    isLoading || dispatch(setNextEnabled(!Object.keys(_vlcRulesWithErrors)?.length));
    setVLCRulesWithErrors(_vlcRulesWithErrors);
  };

  useEffect(() => {
    validateRules();
  }, [vlcRules, domainAndVariablesData, isLoading]);

  const handleAddVLCRule = () => {
    setDisplayVlcRuleEditor(true);
    setEditVLCRule({});
  };

  const handlePreview = () => {
    navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/vlc-rules-preview`);
  };

  const errorMappedVLCRules = useMemo(
    () =>
      vlcRules.map((rule) => ({
        ...rule,
        hasError: vlcRulesWithErrors[rule.vlcRuleID],
        onDelete,
        onEdit,
        onActiveToggle
      })),
    [vlcRulesWithErrors]
  );

  return (
    <>
      <Box pt={0} pl={3} pr={3}>
        <Typography variant="h3">VLC Rules (Optional)</Typography>
        <Typography variant="title">
          Edit checks - to specify the criteria for any dataset with an output of True or False and
          defining the conditions for the same
        </Typography>
        <VLCErrorDetails
          rulesWithError={vlcRules
            .filter((vlcRule) => vlcRulesWithErrors[vlcRule.vlcRuleID])
            .map((vlcRule) => vlcRule.vlcRuleName)}
        />
        <Box mt={2}>
          <Table
            title={vlcRules?.length > 0 ? `List of Rules (${vlcRules?.length})` : `VLC Rules`}
            subtitle={vlcRules?.length === 0 && '0 Rules'}
            columns={columns}
            rows={errorMappedVLCRules}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 'All']}
            hasScroll
            maxHeight={450}
            tablePaginationProps={{
              truncate: true
            }}
            isLoading={isLoading}
            headerProps={{ handleAddVLCRule, vlcRules, handlePreview }}
            CustomHeader={CustomButtonHeader}
            defaultRowsPerPage={5}
            initialSortedColumn="iqAuditDate"
            initialSortOrder="desc"
            emptyProps={{
              content: <EmptyTableComp handleAddVLCRule={handleAddVLCRule} />
            }}
          />
        </Box>
      </Box>
      <Footer width={'100%'} />
      {modalConfig.open && (
        <Modal
          open={modalConfig.open}
          variant={modalConfig.variant}
          title={modalConfig.title}
          data-testid="confirmation-modal"
          buttonProps={modalConfig.buttonProps}
          onClose={modalConfig.onClose}>
          <Box sx={{ whiteSpace: 'pre-line', width: 'auto' }}>
            {modalConfig.body}
            <Box sx={{ mt: '3rem' }}>Rule Name:</Box>
            <Box sx={{ fontWeight: '700' }}>{modalConfig.vlcRuleName}</Box>
          </Box>
        </Modal>
      )}
      <VLCRuleEditor
        vlcRules={vlcRules}
        displayVlcRuleEditor={displayVlcRuleEditor}
        setDisplayVlcRuleEditor={setDisplayVlcRuleEditor}
        existingRuleName={existingRuleName}
        editVLCRule={editVLCRule}
        setEditVLCRule={setEditVLCRule}
        fetchVLCRules={fetchVLCRules}
        domainAndVariablesData={domainAndVariablesData}
        dataSetName={dataSetName}
      />
    </>
  );
};
