/*eslint-disable */
import React, { useState, useContext, useMemo, useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from 'apollo-react/components/Button';
import Typography from 'apollo-react/components/Typography';
import Grid from 'apollo-react/components/Grid';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import Footer from 'Components/Footer';
import { unwrapResult } from '@reduxjs/toolkit';
import Tooltip from 'apollo-react/components/Tooltip';
import {
  GetMasteringSettings,
  GetRuleMetadataFormItems,
  GetVariableMappingRules,
  GetRuleExpressionMethodsAndConstants,
  SaveVariableMappingRules
} from 'Redux/Service/MasteringRuleService';

import { MasteringRulesContext } from './MasteringRulesProvider';
import {
  MiddlePanel,
  GeneralSettings,
  DefaultFormSettings
} from './Components/MasteringRuleComponents';
import { getSourceName, SOURCE_NAME_MAPPING } from 'Utils';
import CustomModal from 'Components/Modal';
import SubjectLibrary from './Components/MasteringRules/SubjectLibrary';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useRef } from 'react';
import { DeleteVariableRule, GetRuleExpressionTemplates } from 'Redux/Service/DomainRuleService';
import { GetProductDataSourcesByMappingRuleVersionID } from 'Redux/Service/RuleEditorService';
import _ from 'lodash';
import { setCreateMappingObj, setWarningModalConfig } from 'Redux/Slice/RuleEditorSlice';
import SearchFields from './Components/DomainRules/SearchFields';
import DerivedVisitNameConfig from './Components/MasteringRuleComponents/DerivedVisitNameConfig';
import { GetDerivedVisitNameData } from 'Redux/Service/MasteringRuleService';
import Loader from 'apollo-react/components/Loader';
import Cookies from 'js-cookie';
import { GetProductCTPData } from 'Redux/Service/ReferenceDataCardsService';
import { referenceDataService } from 'Services/ReferenceData.Service';
import VisitFormFilters from './Components/MasteringRuleComponents/VisitFormFilters';
import VisitLibrary from './Components/MasteringRules/VisitLibrary';
import { Q2LAB, QECG } from 'Pages/DataStandardLibrary/DataStandardLibraryMain/constants';
import { ChevronLeft } from 'apollo-react-icons';
import { getRuleList } from './Utils/DomainUtils';
import { TARGET_MODEL_TYPES } from 'Constants/TargetModelTypes';

export const SUBJECT_SETTING = 'Subject Setting';
export const VISIT_SETTING = 'Visit Setting';

export const MASTERING_TYPE_UNMAPPING = {
  SUBJMAST: SUBJECT_SETTING,
  Visit_Mastering: VISIT_SETTING
};

export const MASTERING_TYPE_MAPPING = {
  'Subject Setting': 'SUBJMAST',
  'Visit Setting': 'Visit_Mastering'
};

const BORDER_BOX = 'border-box';
const PROXIMA_NOVA_FONT = 'Proxima Nova';
const GENERAL_SETTING = 'General Setting';
const CDR_TABULAR = 'CDR Tabular';
const CDISC_ODM = 'CDISC ODM';
const CANCEL_PAGE = 'cancel-page';
const useStyles = makeStyles({
  header: {
    display: 'flex',
    width: '100%',
    boxSizing: BORDER_BOX,
    flexDirection: 'column',
    padding: '0px 22px',
    fontFamily: PROXIMA_NOVA_FONT
  },
  body: {
    boxSizing: BORDER_BOX,
    display: 'flex',
    padding: '22px',
    width: '100%',
    fontFamily: PROXIMA_NOVA_FONT
  },
  footer: {
    boxSizing: BORDER_BOX,
    padding: '1rem 1rem 0 1rem',
    fontFamily: PROXIMA_NOVA_FONT
  },
  loader: {
    '&>div': {
      marginTop: 'calc(50vh - 113px)'
    }
  }
});

export const MasteringRules = (props) => {
  const classes = useStyles();
  const { handleStepperValidate } = props;

  const location = useLocation();
  const { createMappingObj = {} } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let treeArr = [];
  const contextData = useContext(MasteringRulesContext);
  const {
    setTreeView,
    treeView,
    selectNodeText,
    parentNodeName,
    setParentNodeName,
    formMetaData,
    setFormMetaData,
    masteringType,
    confirmAlert,
    setConfirmAlert,
    expressions,
    setExpressionsForNode,
    setMethodsAndConstants,
    methodsConstants,
    setExpressions,
    setSelectedNodeText,
    setExpanded,
    setSelected,
    mappingError,
    setMappingError,
    codeListDataMapping,
    edcDefaultForms,
    setEDCDefaultForms,
    cdrDefaultForms,
    setCDRDefaultForms,
    searchFieldsEnabled,
    visitMasteringFilters,
    setVisitMasteringFilters,
    setExpressionTemplates
  } = contextData;

  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const [productDataSources, setDataSources] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { id: mappingRuleVersionID } = useParams();

  const userId = Cookies.get('user.id');
  const [data, setData] = useState([]);
  const [masteringData, setMasteringData] = useState({});
  const [isMandatory, setIsMandatory] = useState(true);
  const [previewDisabled, setPreviewDisabled] = useState(false);
  const [derivedVisitSetting, setDerivedVisitSetting] = useState({});
  const [derivedVisits, setDerivedVisits] = useState([]);
  const [ctpSvtDefaultForms, setCtpSvtDefaultForms] = useState({});
  const [refDataService, setRefDataService] = useState({});
  const [selectedVisitLibrary, setSelectedVisitLibrary] = useState({});
  const prevNodeCombination = useRef();

  const { workFlowModel } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const [libraryRowSequence, setLibraryRowSeq] = useState({});
  const setExpressionsCallback = useRef();

  const getSourceData = async () => {
    setLoading(true);
    const selectedDataSources = await dispatch(
      GetProductDataSourcesByMappingRuleVersionID(mappingRuleVersionID)
    ).then(unwrapResult);
    if (selectedDataSources.data.success) {
      setDataSources(selectedDataSources.data.ruleStudyLibrary);
      let result = await referenceDataService(selectedDataSources.data.ruleStudyLibrary);
      setRefDataService(result);
    } else {
      dispatch(showBanner({ variant: 'error', message: selectedDataSources.data.message }));
    }
    setLoading(false);
  };

  const getMasteringSettings = async () => {
    setLoading(true);
    const isFullMastering = masteringType.toLowerCase() !== 'full' ? false : true;
    let masteringSettingsResp = await dispatch(GetMasteringSettings(isFullMastering)).then(
      unwrapResult
    );

    try {
      if (masteringSettingsResp?.data?.success) {
        setMasteringData(masteringSettingsResp.data.masteringSettings);
      } else {
        dispatch(showBanner({ variant: 'error', message: masteringSettingsResp.data.message }));
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const formatMasteringSettings = () => {
    let nodeId = 1;

    try {
      let masteringNodes = Object.keys(masteringData);
      if (masteringNodes?.length) {
        masteringNodes.forEach((masteringNode) => {
          const childObj = getChildNodesData(masteringData[masteringNode], nodeId + 1);
          const childNodes = masteringData[masteringNode] ? childObj.childNodes : [];
          const childCounter = masteringData[masteringNode] ? childObj.lastIndex : undefined;
          const childrenNodes = masteringData[masteringNode] ? childObj.childNodesArray : [];
          treeArr.push({
            nodeId: nodeId,
            nodeText: masteringNode,
            childNodes: childNodes,
            childNodeList: childrenNodes
          });
          nodeId = childCounter ? childCounter + 1 : nodeId + 1;
        });
        setTreeView(treeArr);

        if (Object.keys(createMappingObj).length > 0 && createMappingObj['domainName']) {
          const { domainName, variableName, message } = createMappingObj;
          const nodeData = treeArr.find((node) => {
            return node.nodeText === MASTERING_TYPE_UNMAPPING[domainName];
          });
          if (nodeData) {
            if (variableName) {
              setExpanded([nodeData.nodeId]);
              const childNodeData = nodeData.childNodes.find((node) => {
                return node.nodeText === variableName;
              });
              setParentNodeName(MASTERING_TYPE_UNMAPPING[domainName]);
              setSelectedNodeText(variableName);
              setSelected([childNodeData.nodeId]);
              setMappingError(message);
            } else {
              setExpanded([nodeData.nodeId]);
              setParentNodeName(MASTERING_TYPE_UNMAPPING[domainName]);
              setSelected([nodeData.nodeId]);
              setMappingError(message);
            }
          } else {
            setParentNodeName(GENERAL_SETTING);
          }
          let tempMappingObj = {};
          dispatch(setCreateMappingObj(tempMappingObj));
        } else {
          location?.state
            ? setParentNodeName(location.state.parentNodeName)
            : !parentNodeName && setParentNodeName(GENERAL_SETTING);
          const nodeData = treeArr.find((node) => {
            return node.nodeText === location?.state?.parentNodeName;
          });
          if (nodeData) {
            setSelected([nodeData.nodeId]);
            setExpanded([nodeData.nodeId]);
          }
        }
        return treeArr;
      } else {
        setTreeView([]);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  /**
   * Function to fetch the CTP SVT data(EDC & CDR Default forms)
   */
  const getCtpSvtDefaultForms = async () => {
    const ctpSvtData = await dispatch(
      GetProductCTPData({
        mappingRuleVersionID
      })
    ).then(unwrapResult);
    if (ctpSvtData && ctpSvtData.data && ctpSvtData.data.success) {
      const CDISC_ODM_DEAFULT_FORMS = [];
      const CDR_TABULAR_DEAFULT_FORMS = [];
      ctpSvtData.data.timePoints?.map((record) => {
        if (record?.edcDefaultForm && !CDISC_ODM_DEAFULT_FORMS.includes(record.edcDefaultForm))
          CDISC_ODM_DEAFULT_FORMS.push(record.edcDefaultForm);
        if (record?.cdrDefaultForm && !CDR_TABULAR_DEAFULT_FORMS.includes(record.cdrDefaultForm))
          CDR_TABULAR_DEAFULT_FORMS.push(record.cdrDefaultForm);
      });
      setCtpSvtDefaultForms({
        [CDISC_ODM]: CDISC_ODM_DEAFULT_FORMS,
        [CDR_TABULAR]: CDR_TABULAR_DEAFULT_FORMS
      });
    }
  };

  /**
   * Function to validate the Visit Settings Default forms
   * with the CTP SVT uploaded Data(EDC & CDR Default forms)
   * To Display a soft warning in case the CTP SVT uploaded data
   * Doesnt match the selected Default Forms in Visit Settings
   */
  const validateCtpSvtDefaultForms = async () => {
    const _edcDefaultForms = {};
    const _cdrDefaultForms = {};

    (edcDefaultForms?.saved ? edcDefaultForms : edcDefaultForms._edcDefaultForms)[
      VISIT_SETTING
    ]?.forEach((_defaultForm) => (_edcDefaultForms[_defaultForm] = true));

    for (let _dataset in cdrDefaultForms?.[VISIT_SETTING]) {
      (cdrDefaultForms?.saved ? cdrDefaultForms : cdrDefaultForms._cdrDefaultForms)?.[
        VISIT_SETTING
      ][_dataset]?.forEach((_defaultForm) => (_cdrDefaultForms[_defaultForm] = true));
    }

    const allEDCDefaultFormsConfigured = ctpSvtDefaultForms?.[CDISC_ODM]?.every(
      (_defaultForm) => _defaultForm in _edcDefaultForms
    );

    const allCDRDefaultFormsConfigured = ctpSvtDefaultForms?.[CDR_TABULAR]?.every(
      (_defaultForm) => _defaultForm in _cdrDefaultForms
    );

    if (!allEDCDefaultFormsConfigured || !allCDRDefaultFormsConfigured) {
      const mismatchedSources =
        !allEDCDefaultFormsConfigured && !allCDRDefaultFormsConfigured
          ? 'EDC and CDR'
          : !allEDCDefaultFormsConfigured
          ? 'EDC'
          : 'CDR';
      dispatch(
        setWarningModalConfig({
          displayWarningModal: true,
          displayWarningModalTitle: 'Default Forms does not match CTP SVT Reference data',
          displayWarningModalSubtitle: `${mismatchedSources} Default forms are not selected which are configured in CTP SVT Reference sheet. Do you wish to proceed?`
        })
      );
    } else {
      dispatch(
        setWarningModalConfig({
          displayWarningModal: false,
          displayWarningModalTitle: '',
          displayWarningModalSubtitle: ''
        })
      );
    }
  };

  useEffect(() => {
    const currentNodeCombination = [parentNodeName, selectNodeText].join('-');
    if (prevNodeCombination !== currentNodeCombination) {
      setSelectedVisitLibrary({});
    }
    prevNodeCombination.current = currentNodeCombination;
  }, [parentNodeName, selectNodeText]);

  useEffect(() => {
    if (workFlowModel?.libraryType === TARGET_MODEL_TYPES.IQVIA_CTP) {
      validateCtpSvtDefaultForms();
    }
  }, [edcDefaultForms, cdrDefaultForms]);

  useEffect(() => {
    getSourceData();
    if (workFlowModel?.libraryType === TARGET_MODEL_TYPES.IQVIA_CTP) {
      getCtpSvtDefaultForms();
    }
  }, [mappingRuleVersionID]);

  const { libraryTypes, libraryNames, libraryIds, dataSources, cdrTabularLibTypeDisplayNames } =
    useMemo(() => {
      const _libraryTypes = [];
      const _libraryNames = {};
      const _libraryIds = {};
      const _dataSources = [];
      const _cdrTabularLibTypeDisplayNames = {};
      for (let library of productDataSources) {
        if (library.isMappingRuleConfigured) {
          _libraryTypes.includes(library.libraryType) || _libraryTypes.push(library.libraryType);
          if (library.libraryType === CDR_TABULAR) {
            _dataSources.includes(library.source) || _dataSources.push(library.source);
            _libraryNames[`CDR Tabular_${library.source}`] = library.libraryName;
            _libraryIds[`CDR Tabular_${library.source}`] = library.libraryID;
            _cdrTabularLibTypeDisplayNames[`CDR Tabular_${library.source}`] =
              library.isCustomLibrary === true ? library.displayName : library.libraryType;
          } else {
            _libraryNames[library.libraryType] = library.libraryName;
            _libraryIds[library.libraryType] = library.libraryID;
          }
        }
      }
      return {
        libraryTypes: _libraryTypes,
        libraryNames: _libraryNames,
        libraryIds: _libraryIds,
        dataSources: _dataSources,
        cdrTabularLibTypeDisplayNames: _cdrTabularLibTypeDisplayNames
      };
    }, [productDataSources]);

  useEffect(() => {
    getMasteringSettings();
  }, [masteringType]);

  useEffect(() => {
    getVariableMappingRules();
    getRuleMetadataFormItems();
  }, [libraryTypes]);

  useEffect(() => {
    formatMasteringSettings();
  }, [visitMasteringFilters, masteringData]);

  useEffect(() => {
    getMethodsAndConstants();
    getRuleExpressionTemplates();
  }, []);

  const { libraries, sourceFormItems, odmSourceFormItems, formatedFormItemData } = useMemo(() => {
    const _formatedFormItemData = [];
    const _sourceFormItems = {};
    const _odmSourceFormItems = {};

    const _libraries = Object.keys(formMetaData).reverse();
    _libraries.forEach((libraryType) => {
      _sourceFormItems[libraryType] = {};
    });

    let cdrForms = cdrDefaultForms.saved ? cdrDefaultForms : cdrDefaultForms._cdrDefaultForms;

    const visitLibraries = [
      ..._libraries?.filter((library) => {
        if (library.startsWith(CDR_TABULAR)) {
          const libraryName = libraryNames[library];
          return cdrForms[VISIT_SETTING][libraryName]?.includes(
            library.replace('CDR Tabular_', '')
          );
        } else return true;
      })
    ];
    const subjectLibraries = [..._libraries];

    for (let libraryType in formMetaData) {
      for (let formData of formMetaData[libraryType]) {
        _formatedFormItemData.push({
          formName: formData.formName,
          itemName: formData.itemName,
          libraryType,
          formatedString: `[${formData.formName}].[${formData.itemName}]`
        });
        if (_sourceFormItems[libraryType][formData.formName])
          _sourceFormItems[libraryType][formData.formName].push(formData.itemName);
        else _sourceFormItems[libraryType][formData.formName] = [formData.itemName];

        if (libraryType === 'CDISC ODM') {
          if (_odmSourceFormItems[formData.formName])
            _odmSourceFormItems[formData.formName].items.push(formData.itemName);
          else
            _odmSourceFormItems[formData.formName] = {
              items: [formData.itemName],
              sourceName: getSourceName(libraryType)
            };
        }
      }
    }

    const odmIndexVS = visitLibraries.findIndex((_lib) => _lib.startsWith('CDISC ODM'));
    let edcForms = edcDefaultForms.saved ? edcDefaultForms : edcDefaultForms._edcDefaultForms;
    if (odmIndexVS !== -1) {
      visitLibraries.splice(
        odmIndexVS,
        1,
        ...edcForms[VISIT_SETTING].map((_form) => `CDISC ODM_${_form}`)
      );
    }

    const odmIndexSS = subjectLibraries.findIndex((_lib) => _lib.startsWith('CDISC ODM'));
    if (odmIndexSS !== -1) {
      subjectLibraries.splice(odmIndexSS, 1, `CDISC ODM_${edcDefaultForms[SUBJECT_SETTING]}`);
    }

    for (let lib in libraryRowSequence[VISIT_SETTING]) {
      let libIndex = visitLibraries.indexOf(lib);
      let replacedLib = visitLibraries[libraryRowSequence[VISIT_SETTING][lib] - 1];
      visitLibraries[libraryRowSequence[VISIT_SETTING][lib] - 1] = lib;
      visitLibraries[libIndex] = replacedLib;
    }

    for (let lib in libraryRowSequence[SUBJECT_SETTING]) {
      let libIndex = subjectLibraries.indexOf(lib);
      let replacedLib = subjectLibraries[libraryRowSequence[SUBJECT_SETTING][lib] - 1];
      subjectLibraries[libraryRowSequence[SUBJECT_SETTING][lib] - 1] = lib;
      subjectLibraries[libIndex] = replacedLib;
    }

    return {
      libraries: {
        'Subject Setting': subjectLibraries.filter(Boolean),
        'Visit Setting': visitLibraries.filter(Boolean)
      },
      sourceFormItems: _sourceFormItems,
      odmSourceFormItems: _odmSourceFormItems,
      formatedFormItemData: _formatedFormItemData
    };
  }, [
    formMetaData,
    libraryRowSequence,
    libraryNames,
    libraryIds,
    edcDefaultForms,
    cdrDefaultForms
  ]);

  useEffect(() => {
    setTreeView((treeView) => {
      const modifiedTree = [...treeView];
      const visitMasteringRules = Object.values(visitMasteringFilters).flat().filter(Boolean);
      modifiedTree.forEach((parentNode) => {
        const parentNodeExps = expressions[parentNode.nodeText];
        if (parentNodeExps && parentNode.childNodes.length > 0) {
          parentNode.childNodes.forEach((node) => {
            /**
             * Number of librarySources saved per node/variable
             * if length>0 then check for inprogress(0.5) or completed(1),
             * else mark as queued(0)
             */
            if (Object.keys(parentNodeExps[node.nodeText] || {}).length > 0) {
              if (parentNode.nodeText === VISIT_SETTING) {
                if (node.nodeText.replaceAll(/\s/g, '_').toUpperCase() === 'DERIVED_VISIT_NAME') {
                  node.progress =
                    derivedVisitSetting?.derivedVisitSettingKey ||
                    derivedVisitSetting?.isNotMapped === 'Y'
                      ? 1
                      : 0;
                } else if (visitMasteringRules?.length) {
                  if (
                    visitMasteringRules.every(
                      (rule) =>
                        rule.active === false || parentNodeExps[node.nodeText][rule.filterName]?.length
                    )
                  ) {
                    node.progress = 1;
                    node.reqLibCompleted = true;
                  } else if (
                    visitMasteringRules.some(
                      (rule) => parentNodeExps[node.nodeText][rule.filterName]?.length
                    )
                  ) {
                    node.progress = 0.5;
                    if (
                      visitMasteringRules
                        .filter((rule) => rule.sourceName === 'TABULAR')
                        .every(
                          (rule) =>
                            rule.active === false ||
                            parentNodeExps[node.nodeText][rule.filterName]?.length
                        )
                    ) {
                      node.reqLibCompleted = true;
                    }
                  } else {
                    node.progress = 0;
                  }
                } else {
                  node.progress = 0;
                }
              } else {
                let completedCount = 0;
                let totalExpressionCount = 0;
                let CDRLibProgress = {};
                let reqLibCompleted = false;

                for (let library of libraries[parentNode.nodeText]) {
                  let libExps = parentNodeExps[node.nodeText][library] || [];
                  let completed = 0;
                  totalExpressionCount += libExps.length;
                  libExps.forEach((exp) => {
                    if (exp.isValid && !exp.inProgress) {
                      completed = 1;
                    }

                    if (library.startsWith(CDR_TABULAR)) {
                      CDRLibProgress[library] = completed;
                    }
                  });
                  completedCount += completed;
                }
                reqLibCompleted =
                  Object.values(CDRLibProgress).length !== 0
                    ? libraries[parentNode.nodeText]
                        .filter((_lib) => _lib?.startsWith(CDR_TABULAR))
                        .every((_lib) => CDRLibProgress[_lib] === 1)
                    : false;

                if (totalExpressionCount === 0) {
                  node.progress = 0;
                } else if (completedCount === libraries[parentNode.nodeText].length) {
                  node.progress = 1;
                } else {
                  node.progress = 0.5;
                }
                node.reqLibCompleted = reqLibCompleted;
              }
            } else {
              node.progress = 0;
            }

            // only disabling the nodes based on remotly saved data
            if (edcDefaultForms.saved) {
              /**
               * checking the length of string in case of Subject Setting
               * checking the length of (multiple forms) array in case of Visit setting
               */
              if (
                libraryTypes.some((library) => library.startsWith('CDISC ODM')) &&
                !edcDefaultForms[parentNode.nodeText]?.length
              ) {
                node.defaultFormNotSelected = true;
              } else {
                node.defaultFormNotSelected = false;
              }
            }
          });
        } else if (parentNode.nodeText === GENERAL_SETTING && masteringType) {
          parentNode.progress = 1;
        }
      });
      return modifiedTree;
    });
  }, [
    expressions,
    libraries,
    libraryTypes,
    edcDefaultForms,
    derivedVisitSetting,
    visitMasteringFilters
  ]);

  useEffect(() => {
    setPreviewDisabled(false);
    const modifiedTree = [...treeView];
    modifiedTree.forEach((parentNode) => {
      parentNode.childNodes.forEach((node) => {
        if (node.isMandatory && node.progress !== 1) {
          setPreviewDisabled(true);
        }
      });
    });
  }, [treeView]);

  useEffect(() => {
    (async () => {
      if (typeof setExpressionsCallback?.current === 'function') {
        await setExpressionsCallback.current(expressions);
        setExpressionsCallback.current = undefined;
      }
    })();
  }, [expressions]);

  useEffect(() => {
    handleStepperValidate(true);
  }, []);

  const getVariableMappingRules = async () => {
    setLoading(true);
    const mappedDomains = ['SUBJMAST', 'Visit_Mastering'];
    let payload = {
      mappingRuleVersionID,
      mappedDomains
    };
    let newData = [];
    let libraryRowSeq = {
      'Subject Setting': {},
      'Visit Setting': {}
    };
    let _edcDefaultForms = {
      'Subject Setting': '',
      'Visit Setting': [],
      saved: true
    };
    let _cdrDefaultForms = {
      'Visit Setting': {},
      saved: true
    };
    const masteringRules = await dispatch(GetVariableMappingRules(payload)).then(unwrapResult);
    const _visitMasteringFilters = {};
    if (masteringRules?.data?.success) {
      let itemMappingRules = masteringRules.data.itemMappingRules;
      itemMappingRules?.forEach((el) => {
        if (mappedDomains.includes(el.domainCode)) {
          newData.push({
            ...el,
            id: el.variableRuleId,
            expression: el.expression,
            isValid: true,
            inProgress: false
          });
          if (MASTERING_TYPE_UNMAPPING[el.domainCode] === VISIT_SETTING) {
            if (!(el.sourceName in _visitMasteringFilters)) {
              _visitMasteringFilters[el.sourceName] = [];
            }
            if (!_visitMasteringFilters[el.sourceName][el.rowSeq - 1]) {
              _visitMasteringFilters[el.sourceName][el.rowSeq - 1] = {
                id: el.variableRuleId || uuidv4(),
                expressions: [],
                filterName: el.rowName,
                operator: '',
                active: el.isMapRowActive,
                defaultForm: el.defaultForm,
                sourceName: el.sourceName,
                rowSeq: el.rowSeq,
                libraryID: el.cdrLibraryID,
                isValid: true,
                inProgress: false
              };
            }
            if (el.variableName === 'Q_ROW_CONDITION_OP') {
              _visitMasteringFilters[el.sourceName][el.rowSeq - 1].operator = el.expression;
              _visitMasteringFilters[el.sourceName][el.rowSeq - 1].libraryID = el.cdrLibraryID;
            } else if (el.variableName === 'Q_ROW_CONDITION') {
              _visitMasteringFilters[el.sourceName][el.rowSeq - 1].expressions.push({
                id: el.variableRuleId || uuidv4(),
                expression: el.expression,
                sourceName: el.sourceName,
                libraryID: el.cdrLibraryID,
                isValid: true,
                inProgress: false
              });
            }
          }
          if (
            el.variableName === 'Q_ROW_CONDITION_OP' &&
            MASTERING_TYPE_UNMAPPING[el.domainCode] === VISIT_SETTING
          ) {
            if (el.sourceName === 'ODM') {
              if (
                !_edcDefaultForms[MASTERING_TYPE_UNMAPPING[el.domainCode]].includes(el.defaultForm)
              )
                _edcDefaultForms[MASTERING_TYPE_UNMAPPING[el.domainCode]].push(el.defaultForm);
            } else if (el.sourceName === 'TABULAR') {
              if (
                !_cdrDefaultForms[MASTERING_TYPE_UNMAPPING[el.domainCode]][
                  libraryNames[`CDR Tabular_${el.defaultForm}`]
                ]?.includes(el.defaultForm)
              ) {
                _cdrDefaultForms[MASTERING_TYPE_UNMAPPING[el.domainCode]][
                  libraryNames[`CDR Tabular_${el.defaultForm}`]
                ] =
                  _cdrDefaultForms[MASTERING_TYPE_UNMAPPING[el.domainCode]][
                    libraryNames[`CDR Tabular_${el.defaultForm}`]
                  ] || [];

                _cdrDefaultForms[MASTERING_TYPE_UNMAPPING[el.domainCode]][
                  libraryNames[`CDR Tabular_${el.defaultForm}`]
                ].push(el.defaultForm);
              }
            }
          } else if (
            el.sourceName === 'ODM' &&
            el.variableName === 'Q_ROW_CONDITION' &&
            MASTERING_TYPE_UNMAPPING[el.domainCode] === SUBJECT_SETTING
          ) {
            _edcDefaultForms[MASTERING_TYPE_UNMAPPING[el.domainCode]] = el.defaultForm;
          } else if (el.variableName !== 'Q_ROW_CONDITION') {
            libraryRowSeq[MASTERING_TYPE_UNMAPPING[el.domainCode]][el.rowName] = el.rowSeq;
          }
        }
      });
      // cleanedvisitMasteringFilters has no null values
      const cleanedvisitMasteringFilters = {};
      Object.keys(_visitMasteringFilters).forEach((key) => {
        cleanedvisitMasteringFilters[key] = _visitMasteringFilters[key].filter((item) => item !== null);
      });
      setLibraryRowSeq(libraryRowSeq);
      setData(newData);
      setEDCDefaultForms(_edcDefaultForms);
      setCDRDefaultForms(_cdrDefaultForms);
      setVisitMasteringFilters(cleanedvisitMasteringFilters);
    }
    setLoading(false);
  };

  useEffect(() => {
    setIsMandatory(true);
    for (let parentNode of treeView) {
      if (parentNodeName === parentNode.nodeText && parentNode.childNodes) {
        for (let childNode of parentNode.childNodes) {
          childNode.nodeText === selectNodeText && setIsMandatory(childNode?.isMandatory);
        }
      }
    }
  }, [selectNodeText]);

  /**
   * Function to make Delete API call for the selected variableRuleId
   * @param {string} variableRuleIds Variable rule ids to be deleted
   * @param {function} onDelete Callback to invoke after success or failed API call
   */
  const deleteMasteringRuleExpression = async (variableRuleIds, onDelete) => {
    setLoading(true);
    const payload = {
      variableRuleIds
    };

    const response = await dispatch(DeleteVariableRule(payload))
      .then(unwrapResult)
      .catch((error) => {
        console.log('Error while DeleteVariableRule ::', error);
        return error;
      });

    getVariableMappingRules();
    onDelete && onDelete(response?.data?.success ? 'success' : 'error', response?.data?.message);
    setLoading(false);
  };

  /**
   * Function to make Save API call for the modified expressions
   * @param {Array} modifiedExpressions
   */
  const saveExpressions = async (modifiedExpressions) => {
    setLoading(true);
    let itemMappingRules = [];
    let payload = {};
    /* Ex : parentNode = Subject Settings and selectedVariableNode = SITE */
    Object.keys(modifiedExpressions).length > 0 &&
      Object.keys(modifiedExpressions).forEach((parentNodeKey) => {
        let parentNode = modifiedExpressions[parentNodeKey];
        Object.keys(parentNode).forEach((childKey) => {
          if (childKey === selectNodeText) {
            Object.keys(parentNode[childKey]).forEach((rowName) => {
              let selectedVariableNode = parentNode[childKey];

              selectedVariableNode[rowName].map((rowExp) => {
                itemMappingRules.push({
                  iqCreateDate: new Date().toISOString(),
                  iqUpdateDate: new Date().toISOString(),
                  iqCreatedBy: userId,
                  iqUpdatedBy: userId,
                  iqAuditType: 'INSERT',
                  iqAuditDate: new Date().toISOString(),
                  iqActiveFlag: true,
                  protocolNumber: protocol.protocolNumber,
                  mappingRuleVersionId: mappingRuleVersionID,
                  variableName: childKey,
                  rowName: rowName,
                  sourceName: rowExp.sourceName || getSourceName(rowName),
                  sourceSYSID: 0,
                  domainCode: MASTERING_TYPE_MAPPING[parentNodeKey],
                  ruleSeq: 0,
                  expression: rowExp.expression.trim(),
                  concatenate: 'N',
                  rowSeq: rowExp.rowSeq,
                  isSuppqual: 'N',
                  defaultForm: rowExp.defaultForm,
                  isMapRowActive: true
                });
              });
            });
          }
        });
      });
    payload.itemMappingRules = itemMappingRules;

    const saveExpression = await dispatch(SaveVariableMappingRules(payload)).then(unwrapResult);

    if (saveExpression && saveExpression.data.success) {
      dispatch(showBanner({ variant: 'success', message: saveExpression.data.message }));
      getVariableMappingRules();
    } else {
      dispatch(showBanner({ variant: 'error', message: saveExpression.data.message }));
    }
    setLoading(false);
  };

  function getChildNodesData(nodeData, nodeId) {
    let childNodes = [];
    let lastNodeId = nodeId;
    let childNodesArray = [];
    if (nodeData !== null && nodeData.length > 0) {
      nodeData.map((el, index) => {
        childNodes.push({
          nodeId: index + nodeId,
          nodeText: el.displayName,
          isMandatory: el.isMandatory,
          childNodes: [],
          progress: 0,
          variableMappingType: el.variableMappingType,
          isCDRMandatory: libraryTypes.includes(CDR_TABULAR) && el.isCDRMandatory
        });
        childNodesArray.push(index + nodeId);
        lastNodeId = index + nodeId;
      });

      return { childNodes: childNodes, lastIndex: lastNodeId, childNodesArray };
    } else {
      return { childNodes: [], lastIndex: lastNodeId, childNodesArray };
    }
  }

  const getMethodsAndConstants = async () => {
    setLoading(true);
    const methodsAndConstantsResponse = await dispatch(GetRuleExpressionMethodsAndConstants()).then(
      unwrapResult
    );
    if (
      methodsAndConstantsResponse &&
      methodsAndConstantsResponse.data &&
      methodsAndConstantsResponse.data.success
    ) {
      let methodsAndConstants = [];
      methodsAndConstantsResponse.data.listMethodsConstants.forEach((item) => {
        methodsAndConstants.push(item.displayName);
      });
      setMethodsAndConstants(methodsAndConstants);
    } else {
      dispatch(showBanner({ variant: 'error', message: methodsAndConstantsResponse.data.message }));
    }
    setLoading(false);
  };

  const getRuleExpressionTemplates = async () => {
    const expressionTemplatesResponse = await dispatch(GetRuleExpressionTemplates(false)).then(
      unwrapResult
    );
    const ruleExp = getRuleList(expressionTemplatesResponse);
    if (ruleExp) setExpressionTemplates(ruleExp);
    else
      dispatch(showBanner({ variant: 'error', message: expressionTemplatesResponse.data.message }));
  };

  /**
   * Function to transform the retrived MetaDataFormItems
   * @param {object} formItemsDict
   * @returns {object} ruleMetaDataFormItems
   */
  const transformMetadata = (formItemsDict) => {
    let ruleMetaDataFormItems = {};

    if (formItemsDict['CDISC ODM']) ruleMetaDataFormItems['CDISC ODM'] = formItemsDict['CDISC ODM'];
    if (formItemsDict['ECG']) ruleMetaDataFormItems['QECG'] = formItemsDict['ECG'];
    if (formItemsDict['LAB']) ruleMetaDataFormItems['Q2LAB'] = formItemsDict['LAB'];

    if (formItemsDict[CDR_TABULAR]) {
      formItemsDict[CDR_TABULAR].map((item, index) => {
        if (dataSources.includes(item.formName)) {
          if (ruleMetaDataFormItems[`CDR Tabular_${item.formName}`]) {
            ruleMetaDataFormItems[`CDR Tabular_${item.formName}`].push(item);
          } else {
            ruleMetaDataFormItems[`CDR Tabular_${item.formName}`] = [item];
          }
        }
      });
    }
    return ruleMetaDataFormItems;
  };

  /**
   * Function to save the transformed MetaData to context
   * @param {object} response
   */
  const saveFormMetadata = (response) => {
    const { data: { formItemsDict, success, message } = { formItemsDict: {} } } = response;
    let ruleMetaDataFormItems = {};

    if (success) {
      ruleMetaDataFormItems = transformMetadata(formItemsDict);
      dataSources.forEach((source) => {
        ruleMetaDataFormItems[`CDR Tabular_${source}`] ||
          (ruleMetaDataFormItems[`CDR Tabular_${source}`] = []);
      });
    } else {
      dispatch(showBanner({ variant: 'error', message: message }));
    }
    setFormMetaData(ruleMetaDataFormItems);
  };

  const handleSubjectSettingPreview = () => {
    navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/subject-setting-preview`);
  };
  const handleVisitSettingPreview = () => {
    navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/visit-setting-preview`);
  };

  /**
   * Function to fetch RuleMetadataFormItems from GetRuleMetadataFormItems API
   */
  const getRuleMetadataFormItems = async () => {
    try {
      const payload = {
        mappingRuleVersionID,
        forms: []
      };

      libraryTypes.includes('CDISC ODM') && payload.forms.push(1);
      libraryTypes.includes(CDR_TABULAR) && payload.forms.push(2);
      libraryTypes.includes('Q2LAB') && payload.forms.push(3);
      libraryTypes.includes('QECG') && payload.forms.push(4);

      const response = await dispatch(GetRuleMetadataFormItems(payload)).then(unwrapResult);
      saveFormMetadata(response);
    } catch (error) {
      console.log('Failed to retrive FormItems MetaData :: ', error);
    }
  };

  useEffect(() => {
    let createdExpressions = {};
    for (let parentNodeText in masteringData) {
      createdExpressions[parentNodeText] = {};
      masteringData[parentNodeText]?.forEach((node) => {
        createdExpressions[parentNodeText][node.displayName] = libraries[parentNodeText].reduce(
          (prev, curr) => ((prev[curr] = []), prev),
          {}
        );
      });
    }

    data.forEach((expr) => {
      createdExpressions?.[MASTERING_TYPE_UNMAPPING[expr.domainCode]]?.[expr.variableName]?.[
        expr.rowName
      ]?.push({
        ...expr,
        isValid: true,
        inProgress: false,
        rowSeq: expr.rowSeq,
        expression: expr.expression
      });
    });
    setExpressions(createdExpressions);
  }, [masteringData, data, libraries]);

  const validateNextStep = () => {
    let childNodes = treeView.reduce((prevVal, node) => {
      return node.childNodes.length > 0 ? prevVal.concat(node.childNodes) : prevVal;
    }, []);

    handleStepperValidate(
      !childNodes?.length ||
        childNodes.findIndex((node) => {
          return node.isMandatory
            ? node.progress === 0.5 || node.progress === 0
            : node.isCDRMandatory && !node.reqLibCompleted;
        }) !== -1
    );
  };

  useEffect(() => {
    validateNextStep();
  }, [treeView, contextData.expanded]);

  const defaultFormMapping = useMemo(() => {
    const _defaultFormMapping = {};
    for (let libraryType in formMetaData) {
      formMetaData[libraryType].forEach((formItem) => {
        if (libraryType?.startsWith(CDR_TABULAR)) {
          if (libraryType in _defaultFormMapping) {
            _defaultFormMapping[libraryType].formItems.push(formItem);
          } else {
            _defaultFormMapping[libraryType] = {
              libraryType: CDR_TABULAR,
              libraryName: libraryNames[libraryType],
              libraryID: libraryIds[libraryType],
              sourceName: libraryType.replace('CDR Tabular_', ''),
              formItems: [formItem],
              cdrTabularLibTypeDisplayName: cdrTabularLibTypeDisplayNames[libraryType]
            };
          }
        } else if (libraryType?.startsWith('CDISC ODM')) {
          if ('CDISC ODM' in _defaultFormMapping) {
            _defaultFormMapping['CDISC ODM'].formItems.includes(formItem) ||
              _defaultFormMapping['CDISC ODM'].formItems.push(formItem);
          } else {
            _defaultFormMapping['CDISC ODM'] = {
              libraryType: 'CDISC ODM',
              libraryName: libraryNames['CDISC ODM'],
              sourceName: getSourceName('CDISC ODM'),
              formItems: [formItem]
            };
          }
        } else {
          if (libraryType in _defaultFormMapping) {
            _defaultFormMapping[libraryType].formItems.push(formItem);
          } else {
            _defaultFormMapping[libraryType] = {
              libraryType,
              libraryName: libraryNames[libraryType],
              sourceName: getSourceName(libraryType),
              formItems: [formItem]
            };
          }
        }
      });
    }

    for (let library in _defaultFormMapping) {
      const formsMapping = {};
      _defaultFormMapping[library].formItems.forEach(
        (_formItem) => (formsMapping[_formItem.formName] = null)
      );
      _defaultFormMapping[library].forms = Object.keys(formsMapping);
      _defaultFormMapping[library].rowSeq = libraries[parentNodeName]?.indexOf(library) + 1;
      delete _defaultFormMapping[library].formItems;

      if (library === 'CDISC ODM') {
        _defaultFormMapping[library].selectedForm = edcDefaultForms;
        _defaultFormMapping[library].rowSeq = libraries[parentNodeName]?.reduce(
          (libSeq, library, index) => ({ ...libSeq, [library]: index + 1 }),
          {}
        );
      } else if (library.startsWith(CDR_TABULAR) && parentNodeName === VISIT_SETTING) {
        _defaultFormMapping[library].selectedForm = cdrDefaultForms;
        _defaultFormMapping[library].rowSeq = libraries[parentNodeName]?.reduce(
          (libSeq, library, index) => ({ ...libSeq, [library]: index + 1 }),
          {}
        );
      } else {
        _defaultFormMapping[library].selectedForm = {
          'Subject Setting': _defaultFormMapping[library].forms[0],
          'Visit Setting':
            visitMasteringFilters[getSourceName(library)]?.filter(Boolean)[0]?.defaultForm
        };
      }
    }

    return _defaultFormMapping;
  }, [
    libraryNames,
    formMetaData,
    libraries,
    edcDefaultForms,
    parentNodeName,
    cdrDefaultForms,
    visitMasteringFilters
  ]);

  const getDerivedVisitNameData = async () => {
    setLoading(true);
    const derivedVisitData = await dispatch(GetDerivedVisitNameData(mappingRuleVersionID)).then(
      unwrapResult
    );
    if (derivedVisitData.data.success) {
      const { derivedVisitSetting, derivedVisits } = derivedVisitData.data;
      setDerivedVisitSetting(derivedVisitSetting);
      setDerivedVisits(derivedVisits);
    } else {
      dispatch(showBanner({ variant: 'error', message: derivedVisitData.data.message }));
      setDerivedVisitSetting({});
      setDerivedVisits([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDerivedVisitNameData();
  }, [mappingRuleVersionID]);

  const handleSelectForm = (row) => {
    setSelectedVisitLibrary(row);
  };

  const renderSubjectVisitMastering = useCallback(() => {
    if (parentNodeName && !selectNodeText) {
      if (selectedVisitLibrary?.libraryType && parentNodeName === VISIT_SETTING) {
        const library = selectedVisitLibrary?.libraryType;

        const transFormedSourceFormItems = {};
        if (library.startsWith('CDISC ODM')) {
          const _sourceFormItems = sourceFormItems['CDISC ODM'];
          for (let formKey in _sourceFormItems) {
            transFormedSourceFormItems[formKey] = {
              items: _sourceFormItems[formKey],
              sourceName: getSourceName(selectedVisitLibrary?.libraryType)
            };
          }
        } else if (library.startsWith(CDR_TABULAR)) {
          for (let sourceKey in sourceFormItems) {
            if (sourceKey.startsWith(CDR_TABULAR)) {
              const form = sourceKey.replace('CDR Tabular_', '');
              if (selectedVisitLibrary.forms.includes(form)) {
                transFormedSourceFormItems[form] = {
                  items: sourceFormItems[sourceKey][form],
                  sourceName: getSourceName(selectedVisitLibrary?.libraryType)
                };
              }
            }
          }
        } else {
          const _sourceFormItems = sourceFormItems[library];
          for (let formKey in _sourceFormItems) {
            transFormedSourceFormItems[formKey] = {
              items: _sourceFormItems[formKey],
              sourceName: getSourceName(selectedVisitLibrary?.libraryType)
            };
          }
        }
        return (
          <VisitFormFilters
            refDataService={refDataService}
            isLoading={isLoading}
            sourceFormItems={transFormedSourceFormItems}
            formatedFormItemData={formatedFormItemData}
            selectedVisitLibrary={selectedVisitLibrary}
            getVariableMappingRules={getVariableMappingRules}
            setLoading={setLoading}
          />
        );
      } else {
        return (
          <DefaultFormSettings
            defaultFormMapping={Object.values(defaultFormMapping)}
            getVariableMappingRules={getVariableMappingRules}
            handleSelectForm={handleSelectForm}
          />
        );
      }
    } else if (
      parentNodeName &&
      selectNodeText.replaceAll(/\s/g, '_').toUpperCase() === 'DERIVED_VISIT_NAME'
    ) {
      return (
        <DerivedVisitNameConfig
          mappingRuleVersionID={mappingRuleVersionID}
          derivedVisitSetting={derivedVisitSetting}
          derivedVisits={derivedVisits}
          userId={userId}
          protocolNumber={protocol.protocolNumber}
          getDerivedVisitNameData={getDerivedVisitNameData}
        />
      );
    } else if (parentNodeName === VISIT_SETTING && selectNodeText) {
      let parentNode = treeView?.find((node) => node.nodeText === parentNodeName);
      let isCDRMandatory = parentNode?.childNodes?.find(
        (node) => node.nodeText === selectNodeText
      )?.isCDRMandatory;

      let visitLibs = [];

      visitLibs.push(...(visitMasteringFilters[getSourceName(CDISC_ODM)] || []));
      visitLibs.push(...(visitMasteringFilters[getSourceName(CDR_TABULAR)] || []));
      visitLibs.push(...(visitMasteringFilters[getSourceName(QECG)] || []));
      visitLibs.push(...(visitMasteringFilters[getSourceName(Q2LAB)] || []));

      visitLibs = visitLibs.filter(Boolean);

      return (
        <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
          {visitLibs?.map(({ filterName, sourceName, defaultForm, rowSeq }) => {
            const library =
              sourceName === 'TABULAR'
                ? `${CDR_TABULAR}_${defaultForm}`
                : SOURCE_NAME_MAPPING[sourceName];
            const _sourceFormItems = sourceFormItems[library];

            return (
              <VisitLibrary
                key={'library_' + selectNodeText + filterName}
                library={library}
                setExpressions={(exps, callback) => {
                  setExpressionsCallback.current = callback;
                  setExpressionsForNode(parentNodeName, selectNodeText, filterName, exps);
                }}
                masteringData={masteringData}
                deleteMasteringRuleExpression={deleteMasteringRuleExpression}
                saveExpressions={saveExpressions}
                sourceFormItems={_sourceFormItems}
                expressions={expressions[parentNodeName][selectNodeText][filterName]}
                rowSeq={rowSeq}
                methodsConstants={methodsConstants}
                isMandatory={isMandatory}
                isCDRMandatory={isCDRMandatory}
                libraryNames={libraryNames}
                codeListDataMapping={codeListDataMapping}
                defaultForm={defaultForm}
                formatedFormItemData={formatedFormItemData}
                refDataService={refDataService}
                rowName={filterName}
                sourceName={sourceName}
              />
            );
          })}
        </Box>
      );
    } else if (parentNodeName === SUBJECT_SETTING && selectNodeText) {
      let parentNode = treeView?.find((node) => node.nodeText === parentNodeName);
      let isCDRMandatory = parentNode?.childNodes?.find(
        (node) => node.nodeText === selectNodeText
      )?.isCDRMandatory;

      const rowSeqs = {};
      libraries[parentNodeName].forEach((library, index) => {
        rowSeqs[library] = index + 1;
      });
      const sortedLibraries = libraries[SUBJECT_SETTING].sort((libraryA, libraryB) => {
        if (libraryA.startsWith(CDISC_ODM)) {
          if (libraryB.startsWith(CDISC_ODM)) return 0;
          else if (libraryB.startsWith(CDR_TABULAR)) return -1;
          else return -1;
        } else if (libraryA.startsWith(CDR_TABULAR)) {
          if (libraryB.startsWith(CDR_TABULAR)) return 0;
          else if (libraryB.startsWith(CDISC_ODM)) return +1;
          else return -1;
        } else {
          return +1;
        }
      });

      return (
        <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
          {sortedLibraries?.map((library, index) => {
            const [_defForm, _sourceFormItems] = library.startsWith('CDISC ODM')
              ? [library.replace('CDISC ODM_', ''), sourceFormItems['CDISC ODM']]
              : [
                  defaultFormMapping[library]?.selectedForm[parentNodeName],
                  sourceFormItems[library]
                ];

            return (
              <SubjectLibrary
                key={'library_' + library}
                library={library}
                setExpressions={(exps, callback) => {
                  setExpressionsCallback.current = callback;
                  setExpressionsForNode(parentNodeName, selectNodeText, library, exps);
                }}
                masteringData={masteringData}
                deleteMasteringRuleExpression={deleteMasteringRuleExpression}
                saveExpressions={saveExpressions}
                sourceFormItems={_sourceFormItems}
                expressions={expressions[parentNodeName][selectNodeText][library]}
                rowSeq={rowSeqs[library]}
                methodsConstants={methodsConstants}
                isMandatory={isMandatory}
                isCDRMandatory={isCDRMandatory}
                libraryNames={libraryNames}
                cdrTabularLibTypeDisplayNames={cdrTabularLibTypeDisplayNames}
                codeListDataMapping={codeListDataMapping}
                defaultForm={_defForm}
                formatedFormItemData={formatedFormItemData}
                refDataService={refDataService}
              />
            );
          })}
        </Box>
      );
    } else return null;
  }, [
    libraries,
    parentNodeName,
    selectNodeText,
    expressions,
    defaultFormMapping,
    codeListDataMapping,
    formatedFormItemData,
    mappingRuleVersionID,
    derivedVisitSetting,
    derivedVisits,
    isMandatory,
    selectedVisitLibrary,
    visitMasteringFilters
  ]);

  const renderHeader = () => {
    return (
      <Box className={classes.header}>
        <Typography variant="h3">{parentNodeName}</Typography>
        <Typography variant="title" style={{ fontSize: '18px', margin: '3px 0', color: '#444444' }}>
          {selectNodeText}
        </Typography>
        <Box mt={2}>
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Product Mnemonic
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {rowDataForRuleEditor && rowDataForRuleEditor.productNnemonic}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Description
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {rowDataForRuleEditor && rowDataForRuleEditor.description}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              {parentNodeName === SUBJECT_SETTING && !selectNodeText ? (
                <Tooltip
                  title={
                    previewDisabled
                      ? 'To enable preview, all attributes of all configured sources must be completed'
                      : null
                  }
                  disableFocusListener>
                  <Button
                    variant="secondary"
                    size="small"
                    disabled={previewDisabled}
                    onClick={handleSubjectSettingPreview}
                    data-testid="preview">
                    Preview Subject Settings
                  </Button>
                </Tooltip>
              ) : parentNodeName === VISIT_SETTING && !selectNodeText ? (
                <Tooltip
                  title={
                    previewDisabled
                      ? 'To enable preview, all attributes of all configured sources must be completed'
                      : null
                  }
                  disableFocusListener>
                  <Button
                    variant="secondary"
                    size="small"
                    disabled={previewDisabled}
                    onClick={handleVisitSettingPreview}
                    data-testid="preview">
                    Preview Visit Settings
                  </Button>
                </Tooltip>
              ) : null}
            </Grid>
          </Grid>
          <Typography style={{ color: 'red', marginTop: '10px' }}>
            {mappingError ? mappingError : null}
          </Typography>
        </Box>
      </Box>
    );
  };
  const renderVisitsSourceFormHeader = () => {
    return (
      <Box className={classes.header}>
        <Typography variant="h3">Source Form Configuration</Typography>
        <Box mt={2}>
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Library Type
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {selectedVisitLibrary?.libraryTypeDisplayName}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Library Name
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {selectedVisitLibrary?.libraryName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  height: '100%'
                }}>
                <Button
                  icon={<ChevronLeft />}
                  variant="secondary"
                  size="small"
                  onClick={() =>
                    setConfirmAlert({
                      enabled: true,
                      variant: 'warning',
                      title: 'Are you sure you want to leave this page?',
                      message:
                        'Unsaved changes (if any) will be lost if you navigate away from this page. Would you still like to leave?',
                      onConfirm: () => {
                        setSelectedVisitLibrary({});
                        setConfirmAlert({
                          enabled: false
                        });
                      },
                      onCancel: () => {
                        setConfirmAlert({
                          enabled: false
                        });
                      }
                    })
                  }>
                  Back to Visit Settings
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {isLoading ? <Loader isInner overlayClassName={classes.loader}></Loader> : null}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            width: '25%',
            backgroundColor: 'white',
            position: 'sticky',
            top: '0px',
            minHeight: 'calc(100vh - 113px)',
            height: 'fit-content'
          }}>
          <MiddlePanel />
        </div>
        <div
          style={{
            width: '75%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 113px)'
          }}>
          <div>
            <Breadcrumb addStudy={true} />
            {selectedVisitLibrary?.libraryType ? renderVisitsSourceFormHeader() : renderHeader()}
            <Box className={classes.body}>
              {parentNodeName === GENERAL_SETTING ? (
                <GeneralSettings mappingRuleVersionID={mappingRuleVersionID} />
              ) : (
                renderSubjectVisitMastering()
              )}
            </Box>
          </div>
          <Footer />
        </div>
      </div>
      <CustomModal
        display={confirmAlert.enabled}
        title={
          confirmAlert?.title ||
          (confirmAlert.type === 'cancel'
            ? 'Cancel Expression Edit?'
            : confirmAlert.type === 'delete'
            ? 'Delete Expression?'
            : confirmAlert.type === CANCEL_PAGE
            ? 'Leave Page?'
            : '')
        }
        variant={confirmAlert.variant}
        message={
          confirmAlert.message ||
          (confirmAlert.type === CANCEL_PAGE &&
            'Changes could not be saved if you leave this page, Would you still like to leave?')
        }
        buttonPrimaryLabel={'Ok'}
        handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
      />
      {searchFieldsEnabled?.enabled && (
        <SearchFields sourceFormItems={odmSourceFormItems} {...searchFieldsEnabled} />
      )}
    </>
  );
};

export default MasteringRules;
