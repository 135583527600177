import { makeStyles } from '@mui/styles';

export const useLibraryStyles = makeStyles({
  libraryType: {
    width: '100%',
    marginBottom: '1rem !important',
    boxShadow: '0px 4px 16px rgba(0,0,0,0.04)',
    border: '1px solid #E9E9E9',
    borderRadius: '4px'
  },
  libraryTypeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '48px'
  },
  libraryTypeTitle: {
    fontSize: '14px',
    fontWeight: '500'
  },
  expressionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #F6F7FB',
    padding: '12px 32px',
    '& *': {
      //temp fix to fit the library title and default form in the accordian header
      fontSize: '14px !important'
    }
  },
  optional: {
    '&::after': {
      content: "'Optional'",
      border: '1px #E6E7EB solid',
      color: '#222',
      backgroundColor: '#F6F7FB',
      borderRadius: '4px',
      fontSize: '1rem',
      fontWeight: 'normal',
      padding: '0 0.3rem',
      margin: '0 0.4rem'
    }
  },
  defaultFormTitle: {
    whiteSpace: 'pre-line',
    fontSize: '14px',
    fontWeight: '500',
    color: '#898989'
  },
  defaultForm: {
    fontSize: '14px',
    margin: '0 0.5rem',
    fontWeight: '500'
  }
});
