import { Box, Button, Typography } from 'apollo-react';

import FilterIcon from 'apollo-react-icons/Filter';
import PlusIcon from 'apollo-react-icons/Plus';

import styled from 'styled-components';
import { neutral7 } from 'apollo-react/colors';

import InfoDataset from 'Images/info-dataset.svg';

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'sticky',
  left: '0px',
  padding: '5rem 0',
  maxWidth: 'calc(100vw - 7.25rem)',
  fontFamily: 'Proxima Nova, Nunito Sans, sans-serif'
});

export const InfoDatasetIcon = styled('img')({
  height: '32px',
  width: '32px',
  marginBottom: '1rem',
  opacity: '0.7'
});

export const ContentText = styled(Typography)({
  fontSize: '18px',
  fontWeight: '600',
  color: neutral7
});
export const MessageText = styled(Typography)({
  fontSize: '14px',
  width: 'clamp(150px, 40%, 250px)',
  marginBottom: '1rem'
});

export const CustomButtonHeader = ({
  toggleFilters,
  handleAddVLCRule,
  vlcRules,
  handlePreview
}) => {
  if (vlcRules?.length)
    return (
      <Box>
        <Button
          size="small"
          variant="secondary"
          sx={{ marginRight: '15px' }}
          disabled={!vlcRules.some((row) => row.isActive === true)}
          onClick={handlePreview}>
          Preview
        </Button>
        <Button
          size="small"
          variant="secondary"
          icon={FilterIcon}
          sx={{ marginRight: '15px' }}
          onClick={toggleFilters}>
          Filter
        </Button>
        <Button size="small" variant="secondary" icon={PlusIcon} onClick={handleAddVLCRule}>
          Add VLC Rule
        </Button>
      </Box>
    );
  else return <></>;
};

export const EmptyTableComp = ({ handleAddVLCRule }) => {
  return (
    <Container>
      <InfoDatasetIcon src={InfoDataset} alt={'no-data-icon'} />
      <ContentText>No VLC Rules created yet</ContentText>
      <MessageText>{`Click on “Add VLC Rule” to open the 
        editor and compose a query`}</MessageText>
      <Button
        variant="secondary"
        data-testid="addVLCRuleBtn"
        icon={PlusIcon}
        onClick={handleAddVLCRule}>
        Add VLC Rule
      </Button>
    </Container>
  );
};
