import React, { useState, useEffect, useMemo } from 'react';
import Table from 'apollo-react/components/Table';
import { makeStyles } from '@mui/styles';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import { compareStrings, createStringSearchFilter } from 'apollo-react/components/Table';
import { TextFieldFilter, IntegerFilter } from 'Pages/Dashboard/Components/Filters';
import { numberSearchFilter } from 'Pages/Dashboard/DataProductSummary/Components/columns';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import { Box, Modal, Tab, Tabs } from 'apollo-react';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { GetVLCStats } from 'Redux/Service/DashboardService';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { ExpressionCell } from 'Pages/ProductDesigner/Components/RuleEditor/VLCRules/VLCRules.columns';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem 0'
  },
  content: {
    minWidth: '10%',
    marginRight: '3rem'
  },
  header: {
    color: '#444444',
    fontSize: '14px',
    lineHeight: '24px'
  },
  value: {
    color: '#444444',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '600'
  },
  vlcValue: {
    color: '#444444',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500'
  }
});

const StatusCell = ({ row, column: { accessor } }) => {
  return (
    <Typography style={{ color: row?.isSuccess ? '#028217' : '#BD0100' }}>
      {row?.[accessor]}
    </Typography>
  );
};

const systemDefinedColumns = [
  {
    header: 'VLC Rule Name',
    accessor: 'vlcRuleName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('vlcRuleName')
  },
  {
    header: 'Column',
    accessor: 'column',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('column')
  },
  {
    header: 'Issue Count',
    accessor: 'issueCount',
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('issueCount')
  },
  {
    header: 'VLC Execution Status',
    accessor: 'vlcExecutionStatus',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('vlcExecutionStatus'),
    customCell: StatusCell
  }
];

const userDefinedColumns = [...systemDefinedColumns];
userDefinedColumns.splice(1, 1, {
  header: 'Expression',
  accessor: 'expression',
  sortFunction: compareStrings,
  filterComponent: TextFieldFilter,
  filterFunction: createStringSearchFilter('expression'),
  customCell: ExpressionCell
});

const CountSeperator = () => {
  return <Typography sx={{ mx: '8px' }}>{'|'}</Typography>;
};
const VLCRulesCount = (props) => {
  const classes = useStyles();
  const { headerText, successCount, failedCount } = props;

  return (
    <Box sx={{ my: '0.5rem' }}>
      <Typography className={classes.header}>{headerText}</Typography>
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }} spacing={1}>
        <Typography className={classes.vlcValue}>{`Total: ${
          successCount + failedCount
        }`}</Typography>
        <CountSeperator />
        <Typography
          className={classes.vlcValue}
          style={{ color: '#028217' }}>{`Successful: ${successCount}`}</Typography>
        <CountSeperator />
        <Typography
          className={classes.vlcValue}
          style={{ color: '#BD0100' }}>{`Failed: ${failedCount} `}</Typography>
      </Grid>
    </Box>
  );
};

const VLCIssueOrRecordCount = (props) => {
  const { headerText, count, isLoading = false } = props;
  const classes = useStyles();

  return (
    <Box sx={{ my: '0.5rem' }}>
      <Typography className={classes.header}>{headerText}</Typography>
      <Typography className={classes.value}>
        {isLoading ? (
          <Stack spacing={1} width={'100%'} py={'5px'}>
            <Skeleton variant="rounded" width={'50%'} height={14} />
          </Stack>
        ) : (
          count
        )}
      </Typography>
    </Box>
  );
};

const VLCDetails = ({ dataSetsRowVal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [userDefinedVLCStats, setUserDefinedVLCStats] = useState([]);
  const [systemDefinedVLCStats, setSystemDefinedVLCStats] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [tab, setTab] = useState(0);

  const getVLCStats = async () => {
    setLoading(true);
    const payload = {
      jobRunID: dataSetsRowVal?.jobRunID,
      mappingRuleVersionID: dataSetsRowVal.mappingRuleVersionID,
      datasetName: dataSetsRowVal?.datasetName
    };
    const response = await dispatch(GetVLCStats(payload)).then(unwrapResult);
    if (response?.data?.success) {
      const { systemDefinedVLCStats, userDefinedVLCStats } = response.data;
      setSystemDefinedVLCStats(systemDefinedVLCStats);
      setUserDefinedVLCStats(userDefinedVLCStats);
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
    }
    setLoading(false);
  };

  useEffect(() => {
    getVLCStats();
    setTab(dataSetsRowVal?.tab || 0);
  }, [dataSetsRowVal]);

  const {
    totalVLCSuccessCount,
    totalVLCFailedCount,
    systemDefinedVLCSuccessCount,
    systemDefinedVLCFailedCount,
    userDefinedVLCSuccessCount,
    userDefinedVLCFailedCount
  } = useMemo(() => {
    const {
      vlcStatistics: {
        systemDefinedVLCSuccessCount,
        systemDefinedVLCFailedCount,
        userDefinedVLCSuccessCount,
        userDefinedVLCFailedCount
      } = {}
    } = dataSetsRowVal;
    return {
      totalVLCSuccessCount: systemDefinedVLCSuccessCount + userDefinedVLCSuccessCount,
      totalVLCFailedCount: systemDefinedVLCFailedCount + userDefinedVLCFailedCount,
      systemDefinedVLCSuccessCount,
      systemDefinedVLCFailedCount,
      userDefinedVLCSuccessCount,
      userDefinedVLCFailedCount
    };
  }, [dataSetsRowVal]);

  const { totalIssueCount, systemDefinedVLCIssueCount, userDefinedVLCIssueCount } = useMemo(() => {
    let systemDefinedVLCIssueCount = 0;
    let userDefinedVLCIssueCount = 0;

    systemDefinedVLCStats.forEach((vlcRule) => {
      systemDefinedVLCIssueCount += vlcRule.issueCount;
    });
    userDefinedVLCStats.forEach((vlcRule) => {
      userDefinedVLCIssueCount += vlcRule.issueCount;
    });

    return {
      totalIssueCount: systemDefinedVLCIssueCount + userDefinedVLCIssueCount,
      systemDefinedVLCIssueCount,
      userDefinedVLCIssueCount
    };
  }, [systemDefinedVLCStats, userDefinedVLCStats]);

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <VLCRulesCount
            headerText="Total VLC Rules"
            successCount={totalVLCSuccessCount}
            failedCount={totalVLCFailedCount}
          />
          <VLCRulesCount
            headerText="System Defined VLC Rules"
            successCount={systemDefinedVLCSuccessCount}
            failedCount={systemDefinedVLCFailedCount}
          />
          <VLCRulesCount
            headerText="User Defined VLC Rules"
            successCount={userDefinedVLCSuccessCount}
            failedCount={userDefinedVLCFailedCount}
          />
        </Box>

        <Box className={classes.content}>
          <VLCIssueOrRecordCount
            headerText={'Total Issue Count'}
            count={totalIssueCount}
            isLoading={isLoading}
          />
          <VLCIssueOrRecordCount
            headerText={'System Defined VLC Issue Count'}
            count={systemDefinedVLCIssueCount}
            isLoading={isLoading}
          />
          <VLCIssueOrRecordCount
            headerText={'User Defined VLC Issue Count'}
            count={userDefinedVLCIssueCount}
            isLoading={isLoading}
          />
        </Box>

        <Box className={classes.content}>
          <VLCIssueOrRecordCount
            headerText={'Total Data Set Records'}
            count={dataSetsRowVal?.recordsCount}
          />
        </Box>
      </Box>
      <Table
        title={
          <Tabs
            value={tab}
            onChange={(e, selectedTab) => setTab(selectedTab)}
            truncate
            size="small">
            <Tab label="System Defined" />
            <Tab label="User Defined" />
          </Tabs>
        }
        columns={tab == 0 ? systemDefinedColumns : userDefinedColumns}
        rows={tab === 0 ? systemDefinedVLCStats : userDefinedVLCStats}
        rowId="dataSetName"
        defaultRowsPerPage={10}
        rowsPerPageOptions={[10, 50, 100, 'All']}
        hasScroll
        maxHeight={600}
        tablePaginationProps={{
          labelDisplayedRows: DisplayedRowsLabel,
          truncate: true
        }}
        isLoading={isLoading}
      />
    </>
  );
};

const useVLCDetailsOverlayStyles = makeStyles({
  modalContainer: {
    minWidth: '100%',
    minHeight: '100%',
    '&>div:nth-child(1)': {
      borderBottom: '1px solid #E9E9E9',
      boxShadow: '0px 0px 15px #0002'
    },
    '&>div:nth-child(2)>div:nth-child(1)': {
      borderBottom: '1px solid #E9E9E9'
    }
  }
});

const VLCDetailsOverlay = (props) => {
  const classes = useVLCDetailsOverlayStyles();

  const { displayVLCStatsModal, setVlcStatsModal, dataSetsRowVal, vlcStats } = props;

  if (!displayVLCStatsModal) {
    return <></>;
  }
  return (
    <Modal
      open={displayVLCStatsModal}
      onClose={() => setVlcStatsModal(false)}
      title={`VLC Rules for ${dataSetsRowVal?.datasetName}`}
      id="neutral"
      width={classes.modal}
      hideButtons={true}
      className={classes.modalContainer}>
      <VLCDetails vlcStats={vlcStats} dataSetsRowVal={dataSetsRowVal} />
    </Modal>
  );
};

export default VLCDetailsOverlay;
