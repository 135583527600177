import ERROR from './messages';
/* eslint-disable */
const LITERAL = Object.freeze({
  FORM: 'FORM',
  STRING: 'STRING',
  METHOD: 'METHOD',
  OTHER: 'OTHER',
  ITEM: 'ITEM'
});

export const roundBracketRegex = new RegExp('\\((.*?)\\)');
export const squareBracketRegex = new RegExp('\\[(.*?)\\]');

export default function ValidateExpression(input, methods, forms_items, defaultForm) {
  this.input = input;
  this.forms = Object.keys(forms_items);
  this.methods = methods;
  this.forms_items = forms_items;
  this.defaultForm = defaultForm;
}

ValidateExpression.prototype.parse = function () {
  const input = this.input;
  const expBalanced = this.isStringBalanced(input);
  const ifThenBalancingData = [];

  if (!expBalanced.isValid) {
    return expBalanced;
  }
  const splittedExpWithPlus = this.splitOnChar(input, '+');
  let position = 0;
  for (let i = 0; i < splittedExpWithPlus.length; i++) {
    const expression = splittedExpWithPlus[i];

    const balancedResult = this.isStringBalanced(expression);
    if (!balancedResult.isValid) {
      return balancedResult;
    }

    const { score, count } = this.detectExpression(expression, balancedResult.details);

    ifThenBalancingData.push({
      score,
      count,
      expression,
      details: balancedResult.details,
      startIndex: position
    });

    position += expression.length + 1;
  }

  let result = this.validateIfThenPair(ifThenBalancingData);
  if (!result.isValid) {
    return result;
  }

  for (const data of ifThenBalancingData) {
    const { score, expression, details, startIndex } = data;

    if (score === 0) {
      result = this.checkMissingOperator(expression, details, startIndex);
      if (result.isValid) {
        result = this.validateExpression(expression, startIndex);
      }
    } else {
      result = this.validateIfThen(expression, details, startIndex);
    }

    if (!result.isValid) {
      return result;
    }
  }

  result.isValid = true;
  return result;
};

ValidateExpression.prototype.detectExpression = function (expression, details) {
  expression = expression.toLowerCase();

  //Replace any nbsp character with a space
  expression = expression.replace(/\u00A0/gi, ' ');

  let score = 0;
  let i = 0;
  let count = {
    conjuction: 0,
    ifKeyword: 0,
    then: 0,
    operators: 0
  };
  const length = expression.length;
  while (i < expression.length) {
    const char = expression[i];
    if (!this.validateIndex(i, details)) {
      i++;
      continue;
    }
    if (char === '=') {
      i++;
      score++;
      count['operators']++;
    } else if (char === '>') {
      i++;
      score++;
      count['operators']++;
    } else if (char === '<') {
      if (i + 1 < length && expression[i + 1] === '>') {
        i += 2;
      } else {
        i++;
      }
      score++;
      count['operators']++;
    } else if (char === 'l') {
      if (i + 3 < length && expression.substring(i, i + 4) === 'like') {
        i += 4;
        score++;
        count['operators']++;
      } else {
        i++;
      }
    } else if (char === 'c') {
      if (i + 7 < length && expression.substring(i, i + 8) === 'contains') {
        i += 8;
        score++;
        count['operators']++;
      } else {
        i++;
      }
    } else if (char === 'n') {
      if (i + 2 < length && expression.substring(i, i + 3) === 'not') {
        i += 3;
        i = this.ignoreForwardSpacesInExpression(expression, i);
        if (i + 1 < length && expression.substring(i, i + 2) === 'in') {
          i += 2;
          score++;
          count['operators']++;
        } else if (i + 3 < length && expression.substring(i, i + 4) === 'like') {
          i += 4;
          score++;
          count['operators']++;
        } else {
          i++;
        }
      } else {
        i++;
      }
    } else if (char === 'i') {
      if (i + 2 < length && expression.substring(i - 1, i + 3) === ' in ') {
        score++;
        count['operators']++;
        i += 3;
      } else if (i + 2 < length && expression.substring(i, i + 3) === 'if ') {
        score += 3;
        count['ifKeyword']++;
        i += 3;
      } else {
        i++;
      }
    } else if (char === 't') {
      if (i + 3 < length && expression.substring(i, i + 4) === 'then') {
        i += 4;
        count['then']++;
        score += 3;
      } else {
        i++;
      }
    } else if (char === '$') {
      if (i + 3 < length && expression.substring(i, i + 4) === '$and') {
        count['conjuction']++;
        score += 2;
        i += 4;
      } else if (i + 2 < length && expression.substring(i, i + 3) === '$or') {
        count['conjuction']++;
        score += 2;
        i += 3;
      } else {
        i++;
      }
    } else {
      i++;
    }
  }
  return { score, count };
};

ValidateExpression.prototype.validateIfThenPair = function (ifThenBalancingData) {
  const result = {
    isValid: false,
    message: ERROR.BLANK,
    details: { index: 0 },
    cordinates: { start: 0, end: 0 }
  };

  let flag = false; // count of if during iteration.
  let start = 0;
  let position = 0;
  for (let i = 0; i < ifThenBalancingData.length; i++) {
    const { score, count, startIndex, expression } = ifThenBalancingData[i];
    const { ifKeyword, then } = count;
    if (score && flag) {
      if (ifKeyword) {
        result.cordinates = { start, end: startIndex };
        result.message = ERROR.MISSING_THEN;
        return result;
      } else if (then) {
        result.cordinates = { start, end: position + expression.length };
        result.message = ERROR.INVALID_PLUS_SIGN;
        return result;
      }
    } else if (score && !flag) {
      if (ifKeyword > 1) {
        result.cordinates = {
          start: position,
          end: position + expression.length
        };
        result.message = ERROR.MULTIPLE_IF;
        return result;
      } else if (then > 1) {
        result.cordinates = {
          start: position,
          end: position + expression.length
        };
        result.message = ERROR.MULTIPLE_THEN;
        return result;
      }

      if (ifKeyword && then) {
        // continue;
      } else if (!ifKeyword && then) {
        result.cordinates = {
          start: position,
          end: position + expression.length - 1
        };
        result.message = ERROR.MISSING_IF;
        return result;
      } else if (ifKeyword && !then) {
        flag = true;
        start = startIndex;
      } else if (!ifKeyword && !then) {
        result.cordinates = {
          start: position,
          end: position + expression.length - 1
        };
        result.message = ERROR.MISSING_IF_THEN;
        return result;
      }
    } else if (!score && flag) {
      // continue;
    } else if (!score && !flag) {
      // continue;
    }
    position += expression.length + 1;
  }

  if (flag) {
    result.cordinates = { start, end: position };
    result.message = ERROR.MISSING_THEN;
    return result;
  }

  result.isValid = true;
  return result;
};

ValidateExpression.prototype.isStringBalanced = function (s) {
  let i = 0;
  let arr = [];
  let result = {};
  let rootBrackets = [];

  while (i < s.length) {
    let popOrPush = 0;
    if (s[i] === '{' || s[i] === '(' || s[i] === '[') {
      arr.push({ index: i, char: s[i] });
      popOrPush = 1;
    } else if (s[i] === '}' && arr[arr.length - 1] && arr[arr.length - 1].char === '{') {
      arr.pop();
      popOrPush = 2;
    } else if (s[i] === ')' && arr[arr.length - 1] && arr[arr.length - 1].char === '(') {
      arr.pop();
      popOrPush = 2;
    } else if (s[i] === ']' && arr[arr.length - 1] && arr[arr.length - 1].char === '[') {
      arr.pop();
      popOrPush = 2;
    } else if (s[i] === '"' && arr.length > 0 && arr[arr.length - 1].char === '"') {
      arr.pop();
      popOrPush = 2;
    } else if (
      s[i] === '"' &&
      ((arr.length > 0 && arr[arr.length - 1].char !== '"') || arr.length === 0)
    ) {
      arr.push({ index: i, char: '"' });
      popOrPush = 1;
    }
    if (popOrPush === 1 && arr.length === 1) {
      rootBrackets.push({ start: i, char: s[i] });
    } else if (popOrPush === 2 && arr.length === 0) {
      rootBrackets[rootBrackets.length - 1].end = i;
    }

    i++;
  }

  if (arr.length === 0) {
    result.isValid = true;
    result.message = ERROR.BLANK;
    result.details = rootBrackets;
  } else {
    result.isValid = false;
    result.message = ERROR.MISSING_BRACKETS + arr[arr.length - 1].char;
    result.details = arr[arr.length - 1];
    result.cordinates = {
      start: arr[arr.length - 1].index,
      end: arr[arr.length - 1].index + 1
    };
  }

  return result;
};

ValidateExpression.prototype.checkRoundBracket = function (input, detail, startIndex) {
  if (detail.start !== 0 && detail.end < input.length - 1) {
    let index = detail.end + 1;
    index = this.ignoreForwardSpacesInExpression(input, index);
    const endChar = input.charAt(detail.end + 1);
    if (index !== input.length - 1 && endChar !== '+' && endChar !== '.')
      return {
        isValid: false,
        message: ERROR.MISSING_DOT_OR_PLUS,
        details: { index: detail.end },
        cordinates: { start: startIndex, end: startIndex + 2 }
      };
  }
  return { isValid: true, message: ERROR.BLANK, details: {} };
};

ValidateExpression.prototype.checkRoundBracketIfThen = function (input, detail, startIndex) {
  if (detail.start !== 0 && detail.end < input.length - 1) {
    let index = detail.end + 1;
    index = this.ignoreForwardSpacesInExpression(input, index);
    let temp = input.trim();
    const endChar = temp.charAt(temp.length - 1);
    if (index <= input.length - 1 && endChar === '"') {
      return {
        isValid: false,
        message: ERROR.FORM_LITERAL,
        details: { index: detail.start - 1 },
        cordinates: { start: startIndex, end: startIndex + input.length - 1 }
      };
    }
    if (index <= input.length - 1 && endChar !== '.' && endChar !== ' ' && endChar !== ')')
      return {
        isValid: false,
        message: ERROR.MISSING_DOT,
        details: { index: detail.end },
        cordinates: {
          start: startIndex + detail.end + 1,
          end: startIndex + 1 + input.length
        }
      };
  }
  return { isValid: true, message: ERROR.BLANK, details: {} };
};

ValidateExpression.prototype.checkSquareBracket = function (input, detail, startIndex) {
  const message = ERROR.MISSING_DOT_OR_PLUS;
  const result = { isValid: true, message: ERROR.BLANK, details: {} };
  if (detail.start === 0 && detail.end < input.length - 1) {
    let index = detail.end + 1;
    index = this.ignoreForwardSpacesInExpression(input, index);
    if (index === input.length - 1) {
      return result;
    }
    const char = input.charAt(detail.end + 1);
    if (char !== '+' && char !== '.')
      return {
        isValid: false,
        message: message,
        details: { index: detail.end },
        cordinates: { start: startIndex + detail.end, end: startIndex + detail.end + 2 }
      };
  } else if (detail.start !== 0 && detail.end === input.length - 1) {
    let index = detail.start - 1;
    index = this.ignoreBackwardSpacesInExpression(input, index);
    const char = input.charAt(index);
    if (index > 0 && char !== '+' && char !== '.')
      return {
        isValid: false,
        message: message,
        details: { index: detail.start - 1 },
        cordinates: { start: startIndex + detail.end + 1, end: startIndex + input.length + 1 }
      };
  } else if (detail.start !== 0 && detail.end < input.length - 1) {
    let startIdx = detail.start - 1;
    let endIndex = detail.end + 1;
    startIdx = this.ignoreBackwardSpacesInExpression(input, startIdx);
    const startChar = input.charAt(detail.start - 1);
    endIndex = this.ignoreForwardSpacesInExpression(input, endIndex);
    const endChar = input.charAt(detail.end + 1);
    if (startIdx !== 0 && startChar === ' ')
      return {
        isValid: false,
        message: ERROR.UNEXPECTED_SPACES,
        details: { index: detail.start - 1 },
        cordinates: { start: startIndex + detail.start - 1, end: startIndex + detail.start + 1 }
      };

    if (startIdx !== 0 && startChar !== '+' && startChar !== '.')
      return {
        isValid: false,
        message: message,
        details: { index: detail.start - 1 },
        cordinates: { start: startIndex + detail.start - 1, end: startIndex + detail.start + 1 }
      };

    if (endIndex !== input.length - 1 && endChar !== '+' && endChar !== '.')
      return {
        isValid: false,
        message: message,
        details: { index: detail.end },
        cordinates: { start: startIndex + detail.end, end: startIndex + detail.end + 2 }
      };
  }
  return result;
};

ValidateExpression.prototype.checkSquareBracketIfThen = function (input, detail, startIndex) {
  const message = ERROR.MISSING_DOT;
  const result = { isValid: true, message: ERROR.BLANK, details: {} };
  if (detail.start === 0 && detail.end < input.length - 1) {
    const index = this.ignoreForwardSpacesInExpression(input, detail.end + 1);
    if (index === input.length - 1) {
      return result;
    }

    const char = input.charAt(detail.end + 1);

    if (char === '"') {
      return {
        isValid: false,
        message: ERROR.FORM_LITERAL,
        details: { index: detail.start - 1 },
        cordinates: { start: startIndex, end: startIndex + input.length }
      };
    }

    if (char !== '.')
      return {
        isValid: false,
        message: message,
        details: { index: detail.end },
        cordinates: {
          start: startIndex + detail.end,
          end: startIndex + detail.end + 1
        }
      };
  } else if (detail.start !== 0 && detail.end === input.length - 1) {
    const char = input.charAt(detail.start - 1);
    if (char !== '.')
      return {
        isValid: false,
        message: message,
        details: { index: detail.start - 1 },
        cordinates: {
          start: startIndex + detail.end,
          end: startIndex + detail.end + 1
        }
      };
  } else if (detail.start !== 0 && detail.end < input.length - 1) {
    const startIdx = this.ignoreBackwardSpacesInExpression(input, detail.start - 1);
    const endIndex = this.ignoreForwardSpacesInExpression(input, detail.end + 1);
    const startChar = input.charAt(detail.start - 1);
    const endChar = input.charAt(detail.end + 1);
    if (endIndex !== input.length - 1 && endChar === '"') {
      return {
        isValid: false,
        message: ERROR.FORM_LITERAL,
        details: { index: detail.start - 1 },
        cordinates: { start: startIndex, end: startIndex + input.length - 1 }
      };
    }

    if (startIdx !== 0 && startChar !== '.') {
      if (startChar === ' ') {
        return {
          isValid: false,
          message: ERROR.UNEXPECTED_SPACES,
          details: { index: detail.start - 1 },
          cordinates: {
            start: startIndex + detail.start - 1,
            end: startIndex + detail.start
          }
        };
      }

      return {
        isValid: false,
        message: message,
        details: { index: detail.start - 1 },
        cordinates: {
          start: startIndex + detail.start - 1,
          end: startIndex + detail.start
        }
      };
    }

    if (endIndex !== input.length - 1 && endChar !== '.')
      return {
        isValid: false,
        message: message,
        details: { index: detail.end },
        cordinates: {
          start: startIndex + detail.end,
          end: startIndex + detail.end + 4
        }
      };
  }

  return result;
};

ValidateExpression.prototype.checkDoubleQuotes = function (input, detail, startIndex) {
  let message = ERROR.MISSING_PLUS;
  let result = { isValid: false, message: message, details: {} };
  if (detail.start === 0 && detail.end < input.length - 1) {
    let index = detail.end + 1;
    index = this.ignoreForwardSpacesInExpression(input, index);
    if (index === input.length - 1) {
      result.cordinates = {
        start: startIndex,
        end: startIndex + input.length + 1
      };
      return result;
    }
    const char = input.charAt(detail.end + 1);
    if (char !== '+') {
      return {
        isValid: false,
        message: message,
        details: { index: detail.end },
        cordinates: {
          start: startIndex,
          end: startIndex + input.length + 1
        }
      };
    }
  } else if (detail.start !== 0 && detail.end === input.length - 1) {
    let index = detail.start - 1;
    index = this.ignoreBackwardSpacesInExpression(input, index);
    const char = input.charAt(detail.start - 1);
    if (index !== 0 && char !== '+') {
      return {
        isValid: false,
        message: message,
        details: { index: detail.start - 1 },
        cordinates: {
          start: startIndex,
          end: startIndex + input.length + 1
        }
      };
    }
  } else if (detail.start !== 0 && detail.end < input.length - 1) {
    let startIndex = detail.start - 1;
    let endIndex = detail.end + 1;
    startIndex = this.ignoreBackwardSpacesInExpression(input, startIndex);
    const startChar = input.charAt(detail.start - 1);
    endIndex = this.ignoreForwardSpacesInExpression(input, endIndex);
    const endChar = input.charAt(detail.end + 1);
    if (startIndex !== 0 && startChar !== '+') {
      return {
        isValid: false,
        message: message,
        details: { index: detail.start - 1 },
        cordinates: {
          start: startIndex,
          end: startIndex + input.length + 1
        }
      };
    }
    if (endIndex !== input.length - 1 && endChar !== '+') {
      return {
        isValid: false,
        message: message,
        details: { index: detail.end },
        cordinates: {
          start: startIndex,
          end: startIndex + input.length + 1
        }
      };
    }
  }
  return { isValid: true, message: ERROR.BLANK, details: {} };
};

ValidateExpression.prototype.checkDoubleQuotesIfThen = function (input, detail, isLeftSide) {
  let message = ERROR.MISSING_PLUS;
  let result = { isValid: false, message: ERROR.BLANK, details: {} };
  if (detail.start === 0 && detail.end < input.length - 1) {
    let index = detail.end + 1;
    index = this.ignoreForwardSpacesInExpression(input, index);
    if (!isLeftSide && index !== input.length - 1) {
      result.message = ERROR.MISSING_CONJUCTION;
      return result;
    }
  } else if (detail.start !== 0 && detail.end === input.length - 1) {
    let index = detail.start - 1;
    index = this.ignoreBackwardSpacesInExpression(input, index);
    const char = input.charAt(detail.start - 1);
    if (isLeftSide && index === 0 && char !== '+') {
      return {
        isValid: false,
        message: message,
        details: { index: detail.start - 1 }
      };
    }
  } else if (detail.start !== 0 && detail.end < input.length - 1) {
    let startIndex = detail.start - 1;
    let endIndex = detail.end + 1;
    startIndex = this.ignoreBackwardSpacesInExpression(input, startIndex);
    const startChar = input.charAt(detail.start - 1);
    endIndex = this.ignoreForwardSpacesInExpression(input, endIndex);
    const endChar = input.charAt(detail.end + 1);
    if (startIndex !== 0 && startChar !== '+') {
      return {
        isValid: false,
        message: message,
        details: { index: detail.start - 1 }
      };
    }
    if (endIndex !== input.length - 1 && endChar !== '+') {
      return {
        isValid: false,
        message: message,
        details: { index: detail.end }
      };
    }
  }
  return { isValid: true, message: ERROR.BLANK, details: {} };
};

ValidateExpression.prototype.checkMissingOperator = function (expression, details, startIndex) {
  let result = { isValid: true, message: ERROR.BLANK, details: { ...details } };
  for (let i = 0; i < details.length; i++) {
    const detail = details[i];
    if (detail.char === '[') {
      result = this.checkSquareBracket(expression, detail, startIndex);
    } else if (detail.char === '(') {
      result = this.checkRoundBracket(expression, detail, startIndex + detail.end);
    } else if (detail.char === '"') {
      result = this.checkDoubleQuotes(expression, detail, startIndex);
    }

    if (!result.isValid) {
      break;
    }
  }
  return result;
};

ValidateExpression.prototype.validateIfThen = function (expression, details, startIndex) {
  let result = {
    isValid: false,
    message: ERROR.BLANK,
    details: { index: 0 },
    cordinates: { start: 0, end: 0 }
  };
  if (!expression.trim().toLowerCase().startsWith('if')) {
    result.cordinates = {
      start: startIndex,
      end: startIndex + expression.length - 1
    };
    result.message = ERROR.START_IF;
    return result;
  }

  const [betweenIfAndThenExpression, afterThenExression] = this.splitOnIfThen(expression, details);

  if (!afterThenExression.trim() || afterThenExression.trim() === '""') {
    result.cordinates = {
      start: startIndex,
      end: startIndex + expression.length
    };
    result.message = ERROR.LITERAL_THEN;
    return result;
  }

  if (!betweenIfAndThenExpression.trim() || betweenIfAndThenExpression.trim() === '""') {
    result.cordinates = {
      start: startIndex,
      end: startIndex + expression.toLowerCase().indexOf('then') + 3
    };
    result.message = ERROR.EXPRESSION_NOT_EXISTS_IF_THEN;
    return result;
  }

  // split with $AND/$OR;
  const andOrSplittedString = this.splitOnAND_OR(
    betweenIfAndThenExpression,
    this.isStringBalanced(betweenIfAndThenExpression).details
  );

  let expressionsSplittedOnOperators = [];
  let conjuctionIndex = !andOrSplittedString[0].splittedBy
    ? expression.toLowerCase().indexOf('if') + 2
    : expression.toLowerCase().indexOf(andOrSplittedString[0].splittedBy);

  let prevOperator = andOrSplittedString[0].splittedBy;
  let position = startIndex + expression.toLowerCase().indexOf('if') + 2;
  for (let { str, splittedBy } of andOrSplittedString) {
    if (!str.trim()) {
      result.cordinates = {
        start: startIndex + conjuctionIndex,
        end: startIndex + conjuctionIndex + prevOperator.length
      };
      result.message = ERROR.EXPRESSION_NOT_EXISTS_CONJUCTION;
      result.isValid = false;
      return result;
    }

    const { result: splittled, operator } = this.splitOnOperators(
      str,
      this.isStringBalanced(str).details
    );
    if (splittled.length < 2) {
      result.cordinates = {
        start: startIndex + conjuctionIndex + prevOperator.length,
        end: startIndex + conjuctionIndex + splittled[0].length + 2
      };
      result.message = ERROR.INVALID_OPERATOR;
      result.isValid = false;
      return result;
    } else if (splittled.length > 2) {
      result.cordinates = {
        start: startIndex + conjuctionIndex + prevOperator.length,
        end: startIndex + conjuctionIndex + prevOperator.length + str.length
      };
      result.message = ERROR.INVALID_AND_OR;
      result.isValid = false;
      return result;
    }

    if (!splittled[0].trim() || splittled[0].trim() === '""') {
      const end = position + splittled[0].length + operator.length + splittled[1].length - 1;
      result.cordinates = { start: position, end };
      result.message = ERROR.LEFTSIDE_STRING;
      result.isValid = false;
      return result;
    }

    if (!splittled[1].trim() || splittled[1].trim() === '""') {
      const end = position + splittled[0].length + operator.length + splittled[1].length - 1;
      result.cordinates = { start: position, end };
      result.message = ERROR.RIGHTSIDE_STRING;
      result.isValid = false;
      return result;
    }

    expressionsSplittedOnOperators.push({
      expression: splittled[0],
      startIndex: position
    });
    expressionsSplittedOnOperators.push({
      expression: splittled[1],
      startIndex: position + splittled[0].length + operator.length
    });

    result = this.validateOperatorsLeftAndRightPart(splittled, position, operator);
    if (!result.isValid) {
      return result;
    }

    position += str.length + splittedBy.length;
    conjuctionIndex = expression.toLowerCase().indexOf(splittedBy, conjuctionIndex);
    prevOperator = splittedBy;
  }

  result = this.validateOperatorExpressions(afterThenExression, 0, false);

  if (!result.isValid) {
    return result;
  }

  expressionsSplittedOnOperators.push({
    expression: afterThenExression,
    startIndex: position + 4
  });

  for (let i = 0; i < expressionsSplittedOnOperators.length; i++) {
    // if (i % 2 === 0) {
    //   result = this.validateExpression(
    //     expressionsSplittedOnOperators[i].expression,
    //     expressionsSplittedOnOperators[i].startIndex
    //   );
    // }
    result = this.validateExpression(
      expressionsSplittedOnOperators[i].expression,
      expressionsSplittedOnOperators[i].startIndex
    );
    if (!result.isValid) {
      return result;
    }
  }

  return result;
};

ValidateExpression.prototype.validateOperatorsLeftAndRightPart = function (
  splittedData,
  startIndex,
  operator
) {
  let result = { isValid: true, message: ERROR.BLANK, details: { index: 0 } };
  const leftSide = splittedData[0];
  const rightSide = splittedData[1];

  result = this.validateOperatorExpressions(leftSide, startIndex, true);
  if (!result.isValid) {
    return result;
  }
  result = this.validateOperatorExpressions(
    rightSide,
    startIndex + leftSide.length + operator.length,
    false
  );
  if (!result.isValid) {
    return result;
  }

  return result;
};

ValidateExpression.prototype.validateOperatorExpressions = function (
  expression,
  startIndex,
  isLeftSide = true
) {
  let result = {
    isValid: true,
    message: ERROR.BLANK,
    details: { index: 0 },
    cordinates: { start: 0, end: 0 }
  };
  const isString = this.isStringLiteral(expression);

  if (isLeftSide) {
    if (isString) {
      result.cordinates = {
        start: startIndex,
        end: startIndex + expression.length - 1
      };
      result.message = ERROR.LEFTSIDE_STRING;
      result.isValid = false;
      return result;
    }
  } else {
    if (isString) {
      return result;
    }
  }

  const { details } = this.isStringBalanced(expression);

  for (let i = 0; i < details.length; i++) {
    const detail = details[i];
    if (detail.char === '[') {
      result = this.checkSquareBracketIfThen(expression, detail, startIndex + i);
    } else if (detail.char === '(') {
      result = this.checkRoundBracketIfThen(expression, detail, startIndex);
    } else if (detail.char === '"') {
      result.cordinates = {
        start: startIndex,
        end: startIndex + expression.length - 1
      };
      result.isValid = false;
      result.message = ERROR.FORM_LITERAL;
    }
    if (!result.isValid) {
      result.isValid = false;
      return result;
    }
  }

  return result;
};

ValidateExpression.prototype.splitOnIfThen = function (expression, excludes) {
  const idx_of_if = expression.toLowerCase().indexOf('if');
  let idx_of_then = expression.toLowerCase().indexOf('then');

  while (idx_of_then !== -1 && !this.validateIndex(idx_of_then, excludes)) {
    idx_of_then = expression.toLowerCase().indexOf('then', idx_of_then + 1);
  }

  if (idx_of_then === -1) {
    return [];
  }

  return [expression.substring(idx_of_if + 2, idx_of_then), expression.substring(idx_of_then + 4)];
};

ValidateExpression.prototype.validateIndex = function (index, excludes) {
  for (const exc of excludes) {
    if (exc.start <= index && exc.end >= index) {
      return false;
    }
  }

  return true;
};

ValidateExpression.prototype.splitOnChar = function (expression, char) {
  const length = expression.length;
  let prev = 0;
  let index = 0;
  const result = [];
  const { details } = this.isStringBalanced(expression);

  while (index < length) {
    if (expression[index] === char && this.validateIndex(index, details)) {
      const sub = expression.substring(prev, index);
      result.push(sub);
      prev = index + 1;
      index++;
    } else {
      index++;
    }
  }

  if (prev <= index) {
    const sub = expression.substring(prev, index);
    result.push(sub);
  }

  return result;
};

ValidateExpression.prototype.splitOnAND_OR = function (expression, excludes) {
  const length = expression.length;
  let prev = 0;
  let index = 0;
  const result = [];

  while (index < length) {
    if (expression[index] === '$') {
      if (!this.validateIndex(index, excludes)) {
        index++;
        continue;
      }
      const temp = expression.toLowerCase();
      const hasAnd = index + 3 < length && temp.substring(index + 1, index + 4) === 'and';

      const hasOr = index + 2 < length && temp.substring(index + 1, index + 3) === 'or';

      if (hasAnd) {
        const sub = expression.substring(prev, index);
        result.push({ str: sub, splittedBy: '$and' });
        prev = index + 4;
        index += 4;
      } else if (hasOr) {
        const sub = expression.substring(prev, index);
        result.push({ str: sub, splittedBy: '$or' });
        prev = index + 3;
        index += 3;
      } else {
        index++;
      }
    } else {
      index++;
    }
  }

  if (prev <= index) {
    const sub = expression.substring(prev, index);
    result.push({ str: sub, splittedBy: '' });
  }

  return result;
};

ValidateExpression.prototype.splitOnOperators = function (expression, excludes) {
  const lowerExp = expression.toLowerCase();
  const length = expression.length;
  let prev = 0;
  let index = 0;
  const result = [];
  let operator = null;

  function pushResult(incrementBy) {
    const sub = expression.substring(prev, index);
    result.push(sub);
    prev = index + incrementBy;
    index += incrementBy;
  }

  while (index < length) {
    if (lowerExp[index] === '>' || lowerExp[index] === '=') {
      if (!this.validateIndex(index, excludes)) {
        index++;
        continue;
      }
      // check >, >= and = operators
      if (index + 1 < length && lowerExp[index] === '>' && lowerExp[index + 1] === '=') {
        operator = '>=';
        pushResult(2);
      } else {
        operator = lowerExp[index];
        pushResult(1);
      }
    } else if (lowerExp[index] === '<') {
      if (!this.validateIndex(index, excludes)) {
        index++;
        continue;
      }
      // check < , <= and <> operators
      if (index + 1 < length && lowerExp[index + 1] === '>') {
        operator = '<>';
        pushResult(2);
      } else if (index + 1 < length && lowerExp[index + 1] === '=') {
        operator = `<=`;
        pushResult(2);
      } else {
        operator = '<';
        pushResult(1);
      }
    } else if (lowerExp[index] === 'n') {
      if (!this.validateIndex(index, excludes)) {
        index++;
        continue;
      }
      // check not in and not like operator
      if (index + 5 < length && lowerExp.substring(index, index + 6) === 'not in') {
        operator = 'not in';
        pushResult(6);
      } else if (index + 7 < length && lowerExp.substring(index, index + 8) === 'not like') {
        operator = 'not like';
        pushResult(8);
      } else {
        index++;
      }
    } else if (
      lowerExp[index] === 'i' &&
      index + 2 < length &&
      lowerExp.substring(index - 1, index + 3) === ' in '
    ) {
      if (!this.validateIndex(index, excludes)) {
        index++;
        continue;
      }
      // check in operator
      operator = ' in ';
      pushResult(3);
    } else if (
      lowerExp[index] === 'l' &&
      index + 3 < length &&
      lowerExp.substring(index, index + 4) === 'like'
    ) {
      if (!this.validateIndex(index, excludes)) {
        index++;
        continue;
      }
      // check like operator
      operator = 'like';
      pushResult(4);
    } else if (
      lowerExp[index] === 'c' &&
      index + 7 < length &&
      lowerExp.substring(index, index + 8) === 'contains'
    ) {
      if (!this.validateIndex(index, excludes)) {
        index++;
        continue;
      }
      // check contains operator
      operator = 'contains';
      pushResult(8);
    } else {
      index++;
    }
  }

  if (prev <= index) {
    const sub = expression.substring(prev, index);
    result.push(sub);
  }

  return { result, operator };
};

ValidateExpression.prototype.validateExpression = function (expression, startIndex) {
  let result = {
    isValid: false,
    message: ERROR.BLANK,
    details: {},
    cordinates: { start: 0, end: 0 }
  };
  const literals = this.splitOnChar(expression, '.');

  let index = 0;
  if (literals.length === 0) {
    return {
      isValid: false,
      message: ERROR.MISSING_EXPRESSION,
      details: { index: 0 },
      cordinates: { start: 0, end: 0 }
    };
  } else if (literals.length === 1 && this.isStringLiteral(literals[0])) {
    result = this.validate1Item(literals, LITERAL.ITEM, startIndex);
    index = index + result.details.index;
  } else if (literals.length === 1 && this.isForm(literals[0])) {
    return {
      isValid: false,
      message: ERROR.MISSING_FORM_OR_ITEM_NAME,
      details: { index: 0 },
      cordinates: { start: 0, end: 0 }
    };
  } else if (
    literals.length > 1 &&
    !this.isStringLiteral(literals[0]) &&
    this.isForm(literals[0]) &&
    !this.isForm(literals[1])
  ) {
    if (!literals[1].trim().endsWith(')')) {
      return {
        isValid: false,
        message: ERROR.MISSING_DOT,
        details: { index: 0 },
        cordinates: {
          start: startIndex + expression.trim().length,
          end: startIndex + expression.length + 2
        }
      };
    }
    return {
      isValid: false,
      message: ERROR.MISSING_FORM_OR_ITEM_NAME,
      details: { index: 0 },
      cordinates: { start: 0, end: 0 }
    };
  } else if (literals.length === 2) {
    result = this.validate2Item(literals, startIndex);
    index = index + result.details.index;
  } else {
    result = this.validate3OrMoreItem(literals, startIndex);
    index = index + result.details.index;
  }
  if (!result.isValid) {
    result.details.index = index;
  }
  return result;
};

ValidateExpression.prototype.validate1Item = function (literals, type, startIndex) {
  const literalType = this.getLiteralType(literals[0]);
  if (literals.length > 1 && literalType === LITERAL.STRING) {
    return {
      isValid: false,
      message: ERROR.STRING_LITERAL,
      details: { index: 0 },
      cordinates: {
        start: startIndex,
        end: startIndex + literals[0].length + 1
      }
    };
  }

  switch (literalType) {
    case LITERAL.METHOD:
      return {
        isValid: false,
        message: ERROR.MISSING_NAME,
        details: { index: 0 },
        cordinates: {
          start: startIndex,
          end: startIndex + literals[0].length + 1
        }
      };
    case LITERAL.FORM:
      return this.validateFormOrItemName(literals[0], type, startIndex, this.defaultForm);
    case LITERAL.STRING:
      return { isValid: true, message: ERROR.BLANK, details: { index: 0 } };
    case LITERAL.OTHER:
      return {
        isValid: false,
        message: ERROR.START_OR,
        details: { index: 0 },
        cordinates: {
          start: startIndex - 1,
          end: startIndex + literals[0].length
        }
      };
  }
};

ValidateExpression.prototype.validate2Item = function (literals, startIndex) {
  let index = 0;
  if (
    this.getLiteralType(literals[0]) !== LITERAL.FORM ||
    this.getLiteralType(literals[1]) !== LITERAL.FORM
  ) {
    return {
      isValid: false,
      message: ERROR.MISSING_FORM_OR_ITEM_NAME,
      details: { index: index },
      cordinates: {
        start: startIndex,
        end: startIndex + literals[0].length + 1
      }
    };
  } else if (literals[1].trimStart() !== literals[1]) {
    return {
      isValid: false,
      message: ERROR.UNEXPECTED_SPACES,
      details: { index: index },
      cordinates: {
        start: index + literals[0].length + 1,
        end: index + literals[0].length + literals[1].length - literals[1].trim().length + 2
      }
    };
  } else {
    const formNameValidation = this.validateFormOrItemName(
      literals[0],
      LITERAL.FORM,
      startIndex,
      this.defaultForm
    );
    if (!formNameValidation.isValid) return formNameValidation;
    return this.validateFormOrItemName(
      literals[1],
      LITERAL.ITEM,
      startIndex + literals[0].length + 1,
      literals[0]
    );
  }
};

ValidateExpression.prototype.validateFormOrItemName = function (
  value,
  type,
  startIndex,
  formValue
) {
  const data = value.match(squareBracketRegex);
  console.log('data::', data);
  if (data && data.length > 0) {
    if (type === LITERAL.FORM) {
      return this.validateFormName(data[1], startIndex, value.length);
    } else if (type === LITERAL.ITEM) {
      if (formValue) {
        const form = formValue.match(squareBracketRegex);
        return this.validateItemName(data[1], form[1], startIndex, value.length);
      }
      return this.validateItemName(data[1], null, startIndex, value.length);
    }
  } else {
    return {
      isValid: false,
      messgae: 'invalid name',
      details: { index: 0 },
      cordinates: { start: startIndex, end: startIndex + value.length - 1 }
    };
  }
};

ValidateExpression.prototype.validateFormName = function (value, startIndex, size) {
  if (this.forms.includes(value)) {
    return { isValid: true, message: ERROR.BLANK, details: { index: 0 } };
  }
  return {
    isValid: false,
    message: ERROR.INVALID_FORMNAME,
    details: { index: 0 },
    cordinates: { start: startIndex, end: startIndex + size }
  };
};

ValidateExpression.prototype.validateItemName = function (item, form, startIndex, size) {
  if (form) {
    if (this.forms_items[form] && this.forms_items[form].includes(item)) {
      return { isValid: true, message: ERROR.BLANK, details: { index: item.length } };
    }
  } else {
    for (let key in this.forms_items) {
      for (let i of this.forms_items[key]) {
        if (i === item) {
          return { isValid: true, message: ERROR.BLANK, details: { index: item.length } };
        }
      }
    }
  }

  return {
    isValid: false,
    message: ERROR.INVALID_ITEM,
    details: { index: 0 },
    cordinates: { start: startIndex, end: startIndex + size }
  };
};

ValidateExpression.prototype.validateMethod = function (value, startIndex) {
  let methodName = value.substring(0, value.indexOf('(')).trim().toLowerCase();
  if (!this.methods[methodName]) {
    return {
      isValid: false,
      message: ERROR.INVALID_METHOD,
      details: { index: 0 },
      cordinates: { start: startIndex - 1, end: startIndex + value.length }
    };
  }
  const roundBracketData = value.match(roundBracketRegex);
  console.log('roundBracketData', roundBracketData);
  if (roundBracketData && roundBracketData.length > 0) {
    const args = roundBracketData[1];
    const result = this.validateMethodArgs(args, methodName);
    if (!result.isValid) {
      result.cordinates = {
        start: startIndex - 1,
        end: startIndex + value.length
      };
      return result;
    }
    return { isValid: true, message: ERROR.BLANK, details: { index: 0 } };
  }
  return {
    isValid: false,
    message: ERROR.INVALID_METHOD_ARGS + methodName,
    details: { index: 0 },
    cordinates: { start: startIndex - 1, end: startIndex + value.length }
  };
};

ValidateExpression.prototype.validateMethodArgs = function (args, methodName) {
  if (!args) {
    if (this.methods[methodName].min !== 0) {
      return {
        isValid: false,
        message: ERROR.INVALID_METHOD_ARGS + methodName,
        details: { index: 0 }
      };
    }
    return { isValid: true, message: ERROR.BLANK, details: { index: 0 } };
  }
  let splittedArgs = [];
  args = args.trim();
  if (methodName === 'replace' && args.startsWith('"') && args.endsWith('"')) {
    splittedArgs = args.match(/\s*"[^"]*"\s*/g);
  }
  if (methodName === 'replace' && (!args.startsWith('"') || !args.endsWith('"'))) {
    return {
      isValid: false,
      message: ERROR.MISSING_DOUBLE_QUOTES + methodName,
      details: { index: 0 }
    };
  } else splittedArgs = args.split(',');
  if (!splittedArgs[splittedArgs.length - 1]) {
    return {
      isValid: false,
      message: ERROR.INVALID_COMMA,
      details: { index: 0 }
    };
  }
  if (
    this.methods[methodName].min <= splittedArgs.length &&
    this.methods[methodName].max >= splittedArgs.length
  ) {
    return this.validateArgType(splittedArgs, methodName);
  }
  return {
    isValid: false,
    message: ERROR.INVALID_METHOD_ARGS + methodName,
    details: { index: 0 }
  };
};

ValidateExpression.prototype.validateArgType = function (args, methodName) {
  for (let i = 0; i < args.length; i++) {
    const testRegex =
      this.methods[methodName].regex[this.methods[methodName].max === Infinity ? '-1' : i];
    if (!testRegex.test(args[i])) {
      return {
        isValid: false,
        message: ERROR.INVALID_ARGTYPE,
        details: { index: 0 }
      };
    }
  }
  return { isValid: true, message: ERROR.BLANK, details: { index: 0 } };
};

ValidateExpression.prototype.validate3OrMoreItem = function (literals, startIndex) {
  let index = 0;
  //   let result = this.validate1Item(literals, "form");
  //   if (result.isValid) {
  let result = this.validate2Item(literals, startIndex);
  if (result.isValid) {
    index += literals[0].length + literals[1].length + 2 + startIndex;
    for (let i = 2; i < literals.length; i++) {
      if (literals[i].trimStart() !== literals[i]) {
        result = {
          isValid: false,
          message: ERROR.UNEXPECTED_SPACES,
          details: { index: index },
          cordinates: {
            start: index,
            end: index + literals[i].length
          }
        };
        break;
      }
      const literalType = this.getLiteralType(literals[i]);
      switch (literalType) {
        case LITERAL.METHOD:
          result = this.validateMethod(literals[i], index);
          break;
        case LITERAL.FORM:
          result = {
            isValid: false,
            message: ERROR.MUST_METHOD,
            details: { index: index },
            cordinates: {
              start: index,
              end: index + literals[i].length
            }
          };
          break;
        case LITERAL.STRING:
          result = {
            isValid: false,
            message: ERROR.MISSING_METHOD,
            details: { index: index },
            cordinates: {
              start: index,
              end: index + literals[i].length
            }
          };
          break;
        case LITERAL.OTHER:
          result = {
            isValid: false,
            message: ERROR.INVALID_PARENTHESIS,
            details: { index: index },
            cordinates: {
              start: index,
              end: index + literals[i].length
            }
          };
          break;
      }
      if (!result.isValid) {
        result.details.index += index;
        break;
      }
      index += literals[i].length + 1;
    }
  }
  return result;
};

ValidateExpression.prototype.getLiteralType = function (expression) {
  if (this.isMethod(expression)) {
    return LITERAL.METHOD;
  }
  if (this.isForm(expression)) {
    return LITERAL.FORM;
  }
  if (this.isStringLiteral(expression)) {
    return LITERAL.STRING;
  }
  return LITERAL.OTHER;
};

ValidateExpression.prototype.isForm = function (expression) {
  if (expression?.trim().startsWith('[') && expression.trim().endsWith(']')) return true;
  return false;
};

ValidateExpression.prototype.isStringLiteral = function (expression) {
  if (expression.trim().startsWith('"') && expression.trim().endsWith('"')) return true;
  return false;
};

ValidateExpression.prototype.isMethod = function (expression) {
  let singleMethodRegex = /^[$A-Z_][0-9A-Z_$]*\(([^,]*,){0,}[^,]*\)/i;
  // let singleMethodRegex = /^[a-zA-Z]+\(.*\)/;
  return singleMethodRegex.test(expression);
};

ValidateExpression.prototype.ignoreForwardSpacesInExpression = function (input, index) {
  while (
    index < input.length - 1 &&
    (input.charCodeAt(index) === 32 || input.charCodeAt(index) === 160)
  ) {
    index++;
  }
  return index;
};

ValidateExpression.prototype.ignoreBackwardSpacesInExpression = function (input, index) {
  while (index > 0 && (input.charCodeAt(index) === 32 || input.charCodeAt(index) === 160)) {
    index--;
  }
  return index;
};
