/*eslint-disable */
import Box from 'apollo-react/components/Box';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Select from 'apollo-react/components/Select';
import Typography from 'apollo-react/components/Typography';
import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import Footer from 'Components/Footer';
import { getDomainAndVariables } from 'Redux/Service/PostSQLEditorService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { Notification } from 'Components/Common/Notification';
import MenuItem from 'apollo-react/components/MenuItem';
import ColumnSettingsTable from './ColumnSettingsTable';
import { uuidv4 } from 'Utils';
import { KeyConstants } from 'Utils/KeyConstants';
import { MessageConstants } from 'Utils/MessageConstants';
import { GetStandardVLCConfig, SaveStandardVLCConfig } from 'Redux/Service/ColumnSettingsService';
import Cookies from 'js-cookie';
import { setNextEnabled } from 'Redux/Slice/RuleEditorSlice';
import { setUnSavedChanges } from 'Redux/Slice/RuleEditorSlice';
import useGlobalMessage from 'Utils/useGlobalMessage';

const useStyles = makeStyles({
  container: {
    fontFamily: 'Proxima Nova'
  },
  select: {
    width: '400px'
  },
  loader: {
    '&>div': {
      marginTop: 'calc(50vh - 113px)'
    }
  }
});

const ColumnSettings = ({ handleVerifyUnsavedChanges }) => {
  const [domainAndvariablesData, setDomainAndvariablesData] = useState([]);
  const { id: mappingRuleVersionID } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [selectedDomainMetaData, setSelectedDomainMetaData] = useState({});
  const [savedColumnSettings, setSavedColumnSettings] = useState({});

  const userId = Cookies.get('user.id');
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { COLUMN_SETTINGS_NOTE_MESSAGE, COLUMN_SETTINGS_PRE_VALIDATION_MESSAGE } =
    useGlobalMessage();

  const { unSavedChanges } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const getDomainAndVariablesData = async () => {
    setLoading(true);
    const response = await dispatch(getDomainAndVariables(mappingRuleVersionID)).then(unwrapResult);
    if (response?.data?.success) {
      const { domainAndVariables } = response.data;
      if (domainAndVariables?.length) {
        const filteredDomains = domainAndVariables.filter(
          (domain) => domain.domainType === 'User Defined' || domain.domainType === 'System Defined'
        );
        setDomainAndvariablesData(filteredDomains);
        setSelectedDomainMetaData(filteredDomains[0] || {});
      }
    } else {
      dispatch(showBanner({ variant: 'error', message: response.data.message }));
    }
    setLoading(false);
  };

  const handleDomainChange = async (e) => {
    setSelectedDomainMetaData(e.target.value);
  };

  const getStandardVLCConfig = async () => {
    setLoading(true);
    const response = await dispatch(GetStandardVLCConfig({ mappingRuleVersionID })).then(
      unwrapResult
    );
    if (response?.data?.success) {
      const { standardVLCConfigs } = response.data;
      if (standardVLCConfigs?.length) {
        const _savedColumnSettings = {};
        standardVLCConfigs.forEach((savedColumnSetting) => {
          if (savedColumnSetting.datasetName in _savedColumnSettings) {
            _savedColumnSettings[savedColumnSetting.datasetName][savedColumnSetting.datasetColumn] =
              savedColumnSetting;
          } else {
            _savedColumnSettings[savedColumnSetting.datasetName] = {
              [savedColumnSetting.datasetColumn]: savedColumnSetting
            };
          }
        });
        setSavedColumnSettings(_savedColumnSettings);
      }
    } else {
      dispatch(showBanner({ variant: 'error', message: response.data.message }));
    }
    setLoading(false);
  };

  useEffect(() => {
    getDomainAndVariablesData();
    getStandardVLCConfig();
  }, []);

  const selectedDomainColumnSettings = useMemo(() => {
    console.log('selectedDomainData', selectedDomainMetaData);
    const _selectedDomainColumnSettings = [];
    selectedDomainMetaData?.variableName?.forEach((datasetColumn) => {
      const columnSetting =
        savedColumnSettings?.[selectedDomainMetaData.domainCode]?.[datasetColumn] || {};

      _selectedDomainColumnSettings.push({
        id: uuidv4(),
        datasetColumn,
        isPrimaryKey: false,
        isRequiredField: false,
        dataType: KeyConstants.COLUMN_SETTINGS_DATA_TYPES.VALUES.ALPHANUMERIC.KEY,
        format: '',
        minLength: '',
        maxLength: '',
        ...columnSetting
      });
    });
    return _selectedDomainColumnSettings;
  }, [selectedDomainMetaData, savedColumnSettings]);

  useEffect(() => {
    const enabledNext =
      domainAndvariablesData.length &&
      domainAndvariablesData.every(({ domainCode }) => {
        //validating if each domain has atleast one primary key is selected and on required field is selected
        let hasOnePrimaryKey = false,
          hasOneRequiredField = false;
        Object.values(savedColumnSettings?.[domainCode] || {}).forEach((columnSetting) => {
          hasOnePrimaryKey ||= columnSetting.isPrimaryKey;
          hasOneRequiredField ||= columnSetting.isRequiredField;
        });
        return hasOnePrimaryKey && hasOneRequiredField;
      });
    dispatch(setNextEnabled(enabledNext));
  }, [domainAndvariablesData, savedColumnSettings]);

  const handleSaveDomainColumnSettings = async (datasetName, columnSettings) => {
    const saveDomainColumnSettingsPayload = [];
    columnSettings?.forEach((datasetColumn) => {
      const date = new Date().toISOString();
      saveDomainColumnSettingsPayload.push({
        ...datasetColumn,
        minLength: datasetColumn.minLength ? +datasetColumn.minLength : null,
        maxLength: datasetColumn.maxLength ? +datasetColumn.maxLength : null,
        protocolNumber: protocol.protocolNumber,
        mappingRuleVersionID: mappingRuleVersionID,
        datasetName,
        iqActiveFlag: true,
        iqAuditType: 'UPDATE',
        iqAuditDate: date,
        iqCreateBy: userId
      });
    });
    const saveResponse = await dispatch(
      SaveStandardVLCConfig({ standardVLCConfigs: saveDomainColumnSettingsPayload })
    ).then(unwrapResult);
    if (saveResponse?.data?.success) {
      dispatch(showBanner({ variant: 'success', message: saveResponse.data.message }));
      getStandardVLCConfig();
    } else {
      dispatch(showBanner({ variant: 'error', message: saveResponse.data.message }));
    }
  };

  const handlePreview = () => {
    navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/column-settings-preview`, {
      state: {
        domainCode: selectedDomainMetaData.domainCode
      }
    });
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      minHeight={'calc(100vh - 171px)'}
      width={'100%'}
      className={classes.container}>
      <Box px={3}>
        <Typography variant="h3">{'Column Settings'}</Typography>
        <Box display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
          <Select
            className={classes.select}
            onChange={(e) => handleVerifyUnsavedChanges(() => handleDomainChange(e))}
            label={'Select a Domain'}
            placeholder={'Select Domain'}
            style={{ marginRight: '10px' }}
            value={selectedDomainMetaData}
            canDeselect={false}>
            {domainAndvariablesData?.map((domainData) => (
              <MenuItem value={domainData} key={domainData?.domainCode}>
                {domainData?.domainCode}
              </MenuItem>
            ))}
          </Select>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} paddingTop={'2rem'}>
            <Button
              variant={'secondary'}
              size="small"
              onClick={handlePreview}
              data-testid="preview">
              Preview
            </Button>
          </Box>
        </Box>
        <Notification
          header={''}
          message={
            <>
              <span style={{ fontWeight: '600' }}>{COLUMN_SETTINGS_PRE_VALIDATION_MESSAGE}</span>
              <br />
              {COLUMN_SETTINGS_NOTE_MESSAGE}
            </>
          }
        />
        <Grid item xs={12}>
          <ColumnSettingsTable
            selectedDomainColumnSettings={selectedDomainColumnSettings}
            selectedDomain={selectedDomainMetaData.domainCode}
            isLoading={isLoading}
            handleSaveDomainColumnSettings={handleSaveDomainColumnSettings}
          />
        </Grid>
      </Box>
      <Footer width={'100%'} />
    </Box>
  );
};

export default ColumnSettings;
