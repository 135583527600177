/*eslint-disable */
import { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Grid } from 'apollo-react';
import Filter from '../DomainRules/Filter';
import { MasteringRulesContext } from '../../MasteringRulesProvider';
import { showBanner } from 'Redux/Slice/BannerSlice';
import {
  DeleteDomainVariableRule,
  DeleteVariableRule,
  ToggleItemMappingRow
} from 'Redux/Service/DomainRuleService';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { unwrapResult } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { getSourceName, uuidv4 } from 'Utils';
import { SaveVariableMappingRules } from 'Redux/Service/MasteringRuleService';
import { MASTERING_TYPE_MAPPING } from '../../MasteringRules';

export const VisitFormFilters = (props) => {
  const {
    refDataService,
    selectedVisitLibrary,
    isLoading,
    sourceFormItems = {},
    formatedFormItemData,
    getVariableMappingRules,
    setLoading
  } = props;

  const contextData = useContext(MasteringRulesContext);
  const { id: mappingRuleVersionID } = useParams();
  const {
    parentNodeName,
    formMetaData,
    methodsConstants,
    codeListDataMapping,
    setConfirmAlert,
    displaySearchFields,
    visitMasteringFilters,
    setVisitMasteringFilters,
    expressionTemplates
  } = contextData;
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const dispatch = useDispatch();
  const userId = Cookies.get('user.id');
  const [addFiltersDisabled, setAddFiltersDisabled] = useState(false);

  const saveRuleExpressions = async (_filters, onSave) => {
    const sourceName = getSourceName(selectedVisitLibrary.libraryType);
    setLoading(true);
    const domainVariableRules = [];
    _filters[sourceName]?.forEach((filter) => {
      if (!filter) return;

      domainVariableRules.push({
        iqCreateDate: new Date().toISOString(),
        iqUpdateDate: new Date().toISOString(),
        iqCreatedBy: userId,
        iqUpdatedBy: userId,
        iqAuditType: 'INSERT',
        iqAuditDate: new Date().toISOString(),
        iqActiveFlag: true,
        protocolNumber: protocol.protocolNumber,
        mappingRuleVersionId: mappingRuleVersionID,
        variableName: 'Q_ROW_CONDITION_OP',
        rowName: filter.filterName,
        domainCode: MASTERING_TYPE_MAPPING[parentNodeName],
        ruleSeq: 0,
        expression: filter.operator,
        defaultForm: filter.defaultForm,
        sourceName: filter.sourceName,
        sourceSYSID: 0,
        concatenate: 'N',
        rowSeq: filter.rowSeq,
        isSuppqual: 'N',
        isMapRowActive: typeof filter.active === 'boolean' ? filter.active : true,
        cdrLibraryID: filter.sourceName === 'TABULAR' ? filter.libraryID : ''
      });
      filter.expressions.forEach((expression) => {
        domainVariableRules.push({
          iqCreateDate: new Date().toISOString(),
          iqUpdateDate: new Date().toISOString(),
          iqCreatedBy: userId,
          iqUpdatedBy: userId,
          iqAuditType: 'INSERT',
          iqAuditDate: new Date().toISOString(),
          iqActiveFlag: true,
          protocolNumber: protocol.protocolNumber,
          mappingRuleVersionId: mappingRuleVersionID,
          variableName: 'Q_ROW_CONDITION',
          rowName: filter.filterName,
          domainCode: MASTERING_TYPE_MAPPING[parentNodeName],
          ruleSeq: 0,
          expression: expression.expression.trim(),
          defaultForm: filter.defaultForm,
          sourceName: expression.sourceName || filter.sourceName,
          sourceSYSID: 0,
          concatenate: 'N',
          rowSeq: filter.rowSeq,
          isSuppqual: 'N',
          isMapRowActive: typeof filter.active === 'boolean' ? filter.active : true,
          cdrLibraryID: filter.sourceName === 'TABULAR' ? filter.libraryID : ''
        });
      });
    });
    const payload = {
      itemMappingRules: domainVariableRules
    };
    const response = await dispatch(SaveVariableMappingRules(payload))
      .then(unwrapResult)
      .catch((error) => {
        console.log('Error while SaveVariableMappingRules ::', error);
        return error;
      });

    if (response && response?.data?.success) {
      onSave({
        enabled: true,
        variant: 'success',
        message: 'Edits to filter have been saved'
      });
      setTimeout(() => onSave({ enabled: false }), 3000);
      getVariableMappingRules();
    } else {
      onSave({ variant: 'error', message: response.message });
      setTimeout(() => onSave({ enabled: false }), 3000);
    }
    setLoading(false);
  };

  const handleAddFilter = () => {
    const sourceName = getSourceName(selectedVisitLibrary.libraryType);
    setVisitMasteringFilters((oldFilters = {}) => {
      const modifiedFilters = { ...oldFilters };
      const defaultForm = ['QLIMS', 'QECG'].includes(sourceName)
        ? Object.keys(sourceFormItems)[0]
        : undefined;
      if (modifiedFilters[sourceName]?.length > 0) {
        modifiedFilters[sourceName].push({
          id: uuidv4(),
          expressions: [],
          filterName: '',
          operator: '"$OR"',
          active: undefined,
          inProgress: true,
          variables: {},
          libraryID: selectedVisitLibrary.libraryID,
          sourceName,
          defaultForm
        });
      } else {
        modifiedFilters[sourceName] = [
          {
            id: uuidv4(),
            expressions: [],
            filterName: '',
            operator: '"$OR"',
            active: undefined,
            inProgress: true,
            variables: {},
            libraryID: selectedVisitLibrary.libraryID,
            sourceName,
            defaultForm
          }
        ];
      }
      return modifiedFilters;
    });
  };

  const handleFilterUpdate = (
    type,
    filterId,
    modifiedFilterData,
    saveToRemote = false,
    onSave = () => null
  ) => {
    const sourceName = getSourceName(selectedVisitLibrary.libraryType);
    switch (type) {
      case 'update': {
        setVisitMasteringFilters((oldFilters = {}) => {
          let filterToSave;
          const modifiedFilters = JSON.parse(JSON.stringify(oldFilters));
          modifiedFilters[sourceName]?.forEach((filter, index) => {
            let rowSeq = filter?.rowSeq;
            if (!rowSeq) {
              rowSeq =
                1 +
                Math.max(
                  0,
                  ...Object.values(modifiedFilters || {})
                    ?.flat()
                    ?.filter(Boolean)
                    .map((_filter) => _filter.rowSeq)
                    .filter(Boolean)
                );
            }

            if (filter?.id === filterId) {
              modifiedFilters[sourceName][index] = { ...filter, ...modifiedFilterData, rowSeq };
              filterToSave = { [sourceName]: [] };
              filterToSave[sourceName][index] = { ...filter, ...modifiedFilterData, rowSeq };
            }
          });

          if (saveToRemote && filterToSave) saveRuleExpressions(filterToSave, onSave);

          return modifiedFilters;
        });
        break;
      }
      case 'delete': {
        setVisitMasteringFilters((oldFilters = {}) => {
          const modifiedFilters = { ...oldFilters };
          modifiedFilters[sourceName]?.splice(
            modifiedFilters[sourceName].findIndex((filter) => filter?.id === filterId),
            1
          );
          return modifiedFilters;
        });
        break;
      }
    }
  };

  const handleIsActiveUpdate = useCallback(
    async (isActive, filterId, filterName, rowSeq, disableActiveToggle, setBanner) => {
      disableActiveToggle(true);
      const payload = {
        itemMappingRows: [
          {
            iqCreateDate: new Date().toISOString(),
            iqUpdateDate: new Date().toISOString(),
            iqCreatedBy: userId,
            iqUpdatedBy: userId,
            iqAuditType: 'UPDATE',
            iqAuditDate: new Date().toISOString(),
            iqActiveFlag: true,
            protocolNumber: protocol.protocolNumber,
            mappingRuleVersionId: mappingRuleVersionID,
            domainCode: MASTERING_TYPE_MAPPING[parentNodeName],
            rowName: filterName,
            rowSeq,
            isMapRowActive: isActive
          }
        ]
      };

      const response = await dispatch(ToggleItemMappingRow(payload)).then(unwrapResult);

      if (response?.data?.success) {
        handleFilterUpdate('update', filterId, {
          active: isActive
        });
        setBanner({
          enabled: true,
          variant: 'success',
          message: `Active flag for filter ${filterName} saved.`
        });
      } else {
        setBanner({
          enabled: true,
          variant: 'error',
          message: `Failed to save Active flag for filter ${filterName}.`
        });
      }
      disableActiveToggle(false);
      setTimeout(() => setBanner({ enabled: false }), 3000);
    },
    [userId, protocol, mappingRuleVersionID, parentNodeName]
  );

  const deleteDomainVariableRule = useCallback(
    async (filterId, filterName, rowSeq) => {
      const payload = {
        itemMappingRows: [
          {
            iqCreateDate: new Date().toISOString(),
            iqUpdateDate: new Date().toISOString(),
            iqCreatedBy: userId,
            iqUpdatedBy: userId,
            iqAuditType: 'DELETE',
            iqAuditDate: new Date().toISOString(),
            iqActiveFlag: true,
            protocolNumber: protocol.protocolNumber,
            mappingRuleVersionId: mappingRuleVersionID,
            domainCode: MASTERING_TYPE_MAPPING[parentNodeName],
            rowName: filterName,
            rowSeq
          }
        ]
      };

      const response = await dispatch(DeleteDomainVariableRule(payload)).then(unwrapResult);

      if (response?.data?.success) {
        handleFilterUpdate('delete', filterId);
        getVariableMappingRules();
        dispatch(showBanner({ variant: 'success', message: response.data.message }));
      } else {
        dispatch(showBanner({ variant: 'error', message: response.data.message }));
      }
    },
    [userId, protocol, mappingRuleVersionID, parentNodeName]
  );

  const deleteDomainVariableExpression = useCallback(async (variableRuleIds, onDelete) => {
    const payload = {
      variableRuleIds
    };

    const response = await dispatch(DeleteVariableRule(payload)).then(unwrapResult);

    onDelete && onDelete(response?.data?.success ? 'success' : 'error', response?.data?.message);
  }, []);

  const selectedParentFilters = useMemo(() => {
    const sourceName = getSourceName(selectedVisitLibrary?.libraryType || '');
    let _selectedParentFilters = [];
    if (sourceName === 'TABULAR') {
      _selectedParentFilters =
        visitMasteringFilters[sourceName]?.filter(
          (filter) => filter.libraryID === selectedVisitLibrary.libraryID
        ) || [];
    } else {
      _selectedParentFilters = visitMasteringFilters[sourceName] || [];
    }
    return _selectedParentFilters;
  }, [visitMasteringFilters, selectedVisitLibrary]);

  return (
    <Grid container spacing={0} style={{ padding: '1rem 0rem' }}>
      {selectedParentFilters?.map((filter) => (
        <Filter
          key={filter.id}
          rowSeq={filter.rowSeq}
          formMetaData={formMetaData}
          handleFilterUpdate={handleFilterUpdate}
          handleIsActiveUpdate={handleIsActiveUpdate}
          deleteVariableRule={deleteDomainVariableRule}
          deleteVariableExpression={deleteDomainVariableExpression}
          refDataService={refDataService}
          isLoading={isLoading}
          selectedParentFilters={selectedParentFilters}
          setConfirmAlert={setConfirmAlert}
          sourceFormItems={sourceFormItems}
          codeListDataMapping={codeListDataMapping}
          methodsConstants={methodsConstants}
          expressionTemplates={expressionTemplates}
          displaySearchFields={displaySearchFields}
          formatedFormItemData={formatedFormItemData}
          disableDefaultFormSelection={['QLIMS', 'QECG'].includes(filter?.sourceName)}
          {...(filter || {})}
        />
      ))}
      <span
        style={{
          marginTop: '1rem',
          width: '100%',
          minWidth: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Button variant={'primary'} onClick={handleAddFilter} disabled={addFiltersDisabled}>
          + Add New Filter
        </Button>
      </span>
    </Grid>
  );
};
export default VisitFormFilters;
