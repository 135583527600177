import { MessageConstants } from './MessageConstants';

export const KeyConstants = {
  EXPORT_SUPPQUAL_TYPES: {
    TOPIC_NAME: 'ExportSuppQualTypes',
    VALUES: {
      EXPORT_IN_SEPERATE_TABLE: {
        KEY: 'EXPORT_IN_SEPERATE_TABLE',
        LABEL: MessageConstants.EXPORT_SEPERATE_TABLE_TITLE,
        DESC: MessageConstants.EXPORT_SEPERATE_TABLE_DESC
      },
      EXPORT_IN_PRIMARY_TABLE: {
        KEY: 'EXPORT_IN_PRIMARY_TABLE',
        LABEL: MessageConstants.EXPORT_PRIMARY_TABLE_TITLE,
        DESC: MessageConstants.EXPORT_PRIMARY_TABLE_DESC
      },
      EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE: {
        KEY: 'EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE',
        LABEL: MessageConstants.EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_TITLE,
        DESC: MessageConstants.EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_DESC
      },
      DO_NOT_EXPORT: {
        KEY: 'DO_NOT_EXPORT',
        LABEL: MessageConstants.DO_NOT_EXPORT_TITLE,
        DESC: MessageConstants.DO_NOT_EXPORT_DESC
      }
    }
  },
  EXPORT_PACKAGE_FORMAT: {
    TOPIC_NAME: 'ExportPackageFormat',
    VALUES: {
      UNCOMPRESSED: {
        KEY: 'UNCOMPRESSED',
        LABEL: MessageConstants.UNCOMPRESSED
      },
      COMPRESSED: {
        KEY: 'COMPRESSED',
        LABEL: MessageConstants.COMPRESSED
      }
    }
  },
  MAPPING_SPEC_FILTER: {
    TOPIC_NAME: 'MappingSpecFilter',
    VALUES: {
      VIEW_ALL_LABEL: {
        KEY: 'VIEW_ALL_LABEL',
        LABEL: MessageConstants.VIEW_ALL_LABEL
      },
      SHOW_LATEST_CREATED_LABEL: {
        KEY: 'SHOW_LATEST_CREATED_LABEL',
        LABEL: MessageConstants.SHOW_LATEST_CREATED_LABEL
      }
    }
  },
  EXPORT_File_FORMAT: {
    TOPIC_NAME: 'ExportFileFormat',
    VALUES: {
      CSV: {
        KEY: 'CSV',
        LABEL: MessageConstants.CSV
      }
    }
  },
  EXPORT_PATH_FOLDER_NAME: {
    TOPIC_NAME: 'ExportPathFolderName',
    VALUES: {
      NONE: {
        KEY: 'NONE',
        LABEL: MessageConstants.NONE
      },
      PROTOCOL_MNEMONIC_PRODUCT_MNEMONIC: {
        KEY: 'PROTOCOL_MNEMONIC_PRODUCT_MNEMONIC',
        LABEL: MessageConstants.PROTOCOL_MNEMONIC_PRODUCT_MNEMONIC
      }
    }
  },
  EXPORT_PATH_SUB_FOLDER: {
    TOPIC_NAME: 'ExportPathSubFolder',
    VALUES: {
      NONE: {
        KEY: 'NONE',
        LABEL: MessageConstants.NONE
      },
      AUTO_GENERATED_BATCH_ID: {
        KEY: 'AUTO_GENERATED_BATCH_ID',
        LABEL: MessageConstants.AUTO_GENERATED_BATCH_ID
      },
      EXPORT_DATE_AND_TIME: {
        KEY: 'EXPORT_DATE_AND_TIME',
        LABEL: MessageConstants.EXPORT_DATE_AND_TIME
      }
    }
  },
  QCODE_SQL_TEMPLATE: {
    TOPIC_NAME: 'QcodeSQLTemplate',
    VALUES: {
      NONE: {
        KEY: 'NONE',
        LABEL: MessageConstants.NONE
      },
      DRUG_THESAURUS: {
        KEY: 'DRUG_THESAURUS',
        LABEL: MessageConstants.DRUG_THESAURUS
      },
      MEDDRA_THESAURUS: {
        KEY: 'MEDDRA_THESAURUS',
        LABEL: MessageConstants.MEDDRA_THESAURUS
      }
    }
  },
  EXPORT_TYPES: {
    TOPIC_NAME: 'ExportTypes',
    VALUES: {
      CUMULATIVE: {
        KEY: 'CUMULATIVE',
        LABEL: MessageConstants.EXPORT_TYPE_CUMULATIVE_TITLE,
        DESC: MessageConstants.EXPORT_TYPE_CUMULATIVE_DESC
      },
      INCREMENTAL: {
        KEY: 'INCREMENTAL',
        LABEL: MessageConstants.EXPORT_TYPE_INCREMENTAL_TITLE,
        DESC: MessageConstants.EXPORT_TYPE_INCREMENTAL_DESC
      }
    }
  },
  COLUMN_SETTINGS_DATA_TYPES: {
    TOPIC_NAME: 'ColumnSettingsDataTypes',
    VALUES: {
      ALPHANUMERIC: {
        KEY: 'ALPHANUMERIC',
        LABEL: MessageConstants.ALPHANUMERIC
      },
      NUMERIC: {
        KEY: 'NUMERIC',
        LABEL: MessageConstants.NUMERIC
      },
      DATE: {
        KEY: 'DATE',
        LABEL: MessageConstants.DATE
      }
    }
  }
};
