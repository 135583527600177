import { createSlice } from '@reduxjs/toolkit';
import { GetProductDataSources, SaveDataSources } from 'Redux/Service/RuleEditorService';

export const RuleEditorSlice = createSlice({
  name: 'RuleEditor',
  initialState: {
    checkedDataForSource: false,
    tableData: [],
    selectedRowsForSave: [],
    dataSourceArray: [],
    trialVisitUploadData: [],
    mappingRuleVersionID: '',
    supplqualFlag: false,
    dmArray: [],
    workFlowModel: {},
    createMappingObj: {},
    nextEnabled: false,
    isImportStudyLibrary: false,
    warningModalConfig:{},
    // importedData: [],
    errorDomains : [],
    unSavedChanges: {}
  },

  reducers: {
    setCheckedDataForSource(state, { payload }) {
      state.checkedDataForSource = payload;
    },
    getSelectedRowsForSave(state, { payload }) {
      state.selectedRowsForSave = payload;
    },
    getTrialVisitData(state, { payload }) {
      state.trialVisitUploadData = payload;
    },
    getMappingRuleVersionID(state, { payload }) {
      state.mappingRuleVersionID = payload;
    },
    getApiHit(state, { payload }) {
      state.supplqualFlag = payload;
    },
    passDmData(state, { payload }) {
      state.dmArray = payload;
    },
    getImportFromStudyLibrary(state, { payload }) {
      state.isImportStudyLibrary = payload;
    },
    // setImportedData(state, { payload }) {
    //   state.importedData = payload;
    // },
    setRuleEditorCards(state, { payload }) {
      state.workFlowModel = payload;
    },
    setCreateMappingObj(state, { payload }) {
      state.createMappingObj = payload;
    },
    setNextEnabled(state, { payload }) {
      state.nextEnabled = payload;
    },
    setErrorDomains(state, { payload }) {
      state.errorDomains = payload;
    },
    setWarningModalConfig(state, { payload }) {
      state.warningModalConfig = payload;
    },
    setUnSavedChanges(state, { payload }) {
      state.unSavedChanges = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(GetProductDataSources.fulfilled, (state, { payload }) => {
      state.tableData = payload;
    });
    builder.addCase(GetProductDataSources.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetProductDataSources.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(SaveDataSources.fulfilled, (state, { payload }) => {
      state.dataSourceArray = payload;
    });
    builder.addCase(SaveDataSources.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(SaveDataSources.rejected, (state, action) => {
      state.error = action.error.message;
    });
  }
});

export const {
  setCheckedDataForSource,
  getSelectedRowsForSave,
  getTrialVisitData,
  getMappingRuleVersionID,
  getApiHit,
  passDmData,
  getImportFromStudyLibrary,
  setRuleEditorCards,
  setCreateMappingObj,
  setNextEnabled,
  setErrorDomains,
  setWarningModalConfig,
  setUnSavedChanges,
  // setImportedData,
} = RuleEditorSlice.actions;
